import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'

export async function updateInvitations({ boardId, recipientEmail, patch }) {
  try {
    const ret = await axios.post(
      `${API_URL}/invitations/update/${boardId}`,
      { data: { patch, recipientEmail } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200 || ret.status === 204) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function addInvitation({ invitation }) {
  try {
    const ret = await axios.post(
      `${API_URL}/invitations/${invitation.boardId}`,
      { data: { invitation } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200 || ret.status === 201) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function getPendingInvitationsForEmail({ email }) {
  try {
    const ret = await axios.get(`${API_URL}/invitations/email/${email}/false`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    }

    throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return []
  }
}

export async function getPendingInvitationsForBoard({ boardId }) {
  try {
    const ret = await axios.get(`${API_URL}/invitations/board/${boardId}/false`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function acceptInvitation({ id, boardId }) {
  try {
    const ret = await axios.post(
      `${API_URL}/invitations/${id}/accept`,
      {
        data: {
          boardId
        }
      },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function acceptInvitationForOutsiders({ email }) {
  try {
    const ret = await axios.post(
      `${API_URL}/invitations/outsider/${email}/accept`,
      { data: { email } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function sendInvite(invitation) {
  try {
    const ret = await axios.post(
      `${API_URL}/invitations/${invitation.boardId}/send-invite`,
      { data: { invitation } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    }

    throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}
