import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'
import { decompressJson } from '@/utils/Utils.js'

export async function sync({ boardId, processId }) {
  // ref: firebase function googleSheetIntegration-sync
  try {
    const ret = await axios.post(
      `${API_URL}/import-mapping/${boardId}/sync`,
      { data: { processId } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) return ret.data // { status, msg }
    throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return error.response?.data
  }
}

/**
 * Returns the google_sheet_import_mapping entry for a boardId
 */
export async function getMapping({ boardId }) {
  try {
    const ret = await axios.get(`${API_URL}/import-mapping/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else if (ret.status === 204) {
      return null
    }

    throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Updates the google_sheet_import_mapping table with the new mapping and filePath of a board
 */
export async function saveMapping({ boardId, mapping, filePath }) {
  try {
    const ret = await axios.post(
      `${API_URL}/import-mapping/${boardId}`,
      { data: { mapping, filePath } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) return ret.data // success
    throw new Error(ret)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function createMapping({ boardId, mapping, filePath }) {
  try {
    const ret = await axios.post(
      `${API_URL}/import-mapping/${boardId}/create`,
      { data: { mapping, filePath } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) return ret.data // success
    throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return error.statusText
  }
}

export async function importGoogleSheet({ boardId, link }) {
  // ref: firebase function googleSheetIntegration-import
  console.log('importGoogleSheet')
  try {
    const ret = await axios.post(
      `${API_URL}/import-mapping/${boardId}/import-google-sheet`,
      { data: { link } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) return decompressJson(ret.data || {}) // The sheet's title, header, imported data, and importId
    throw new Error(ret.response)
  } catch (error) {
    console.log(error)

    throw new Error(error.response?.data)
  }
}

export async function copyGoogleSheet({ sourceId, title }) {
  // ref: firebase function googleSheetIntegration-createCopy
  try {
    const ret = await axios.post(
      `${API_URL}/import-mapping/create-copy`,
      { data: { sourceId, title } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) return ret.data // docId of new doc
    throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function updateGoogleSheet({ docId, props }) {
  // ref: firebase function googleSheetIntegration-updateDoc
  const updateDoc = async (docId, props) => {
    try {
      const ret = await axios.post(
        `${API_URL}/import-mapping/update-doc`,
        { data: { docId, props } },
        { headers: { ...(await getAuthHeader()) } }
      )

      if (ret.status === 200) return ret.data // success
      throw new Error(ret.statusText)
    } catch (error) {
      console.log(error)
      return null
    }
  }

  let res
  try {
    res = await updateDoc({ docId, props })
  } catch (e) {
    console.log('err caught in updateDoc')
    let retryCount = 0
    let isDone = false
    while (!isDone && retryCount < 11) {
      try {
        retryCount++
        res = await updateDoc({ docId, props })
        isDone = true
      } catch (err) {
        console.log('Retrying document update', retryCount, err)
      }
    }
  }
  return res
}

export async function updateAccessGoogleSheet({ docId, email }) {
  // ref: firebase function googleSheetIntegration-givePermissionForUser
  try {
    const ret = await axios.post(
      `${API_URL}/import-mapping/give-permission`,
      { data: { docId, email } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data // success
    }

    throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function uploadCSV({
  boardId,
  formData = null,
  bucketFilePath = null,
  processId = null
}) {
  // ref: firebase function googleSheetIntegration-import
  console.log('importCSV')

  // Sanity check - Either upload the csv file here or pass the path to the bucket where the file was uploaded
  if ((!formData && !bucketFilePath) || (formData && bucketFilePath)) {
    throw new Error('Error uploading CSV - both formData and bucketFilePath are used')
  }

  try {
    let ret = {}
    if (bucketFilePath) {
      ret = await axios.post(
        `${API_URL}/import-mapping/${boardId}/import-csv`,
        { data: { bucketFilePath } },
        {
          headers: { ...(await getAuthHeader()) },
          params: { processId }
        }
      )
    } else {
      ret = await axios.post(`${API_URL}/import-mapping/${boardId}/import-csv`, formData, {
        headers: { ...(await getAuthHeader()), 'Content-Type': 'multipart/form-data' },
        params: { processId }
      })
    }

    if (ret.status === 200) return ret.data // The sheet's title, header, imported data, and importId
    throw new Error(ret.response)
  } catch (error) {
    console.log(error)
    throw new Error(error.response?.data)
  }
}

export async function uploadSalaryMapping({ boardId, formData = null, bucketFilePath = null }) {
  // ref: firebase function googleSheetIntegration-import
  console.log('importCSV')

  // Sanity check - Either upload the csv file here or pass the path to the bucket where the file was uploaded
  if ((!formData && !bucketFilePath) || (formData && bucketFilePath)) {
    throw new Error('Error uploading CSV - both formData and bucketFilePath are used')
  }

  try {
    let ret = {}

    ret = await axios.post(`${API_URL}/settings/${boardId}/salary-mapping`, formData, {
      headers: { ...(await getAuthHeader()), 'Content-Type': 'multipart/form-data' }
    })

    if (ret.status === 200) return ret.data // The sheet's title, header, imported data, and importId
    throw new Error(ret.response)
  } catch (error) {
    console.log(error)
    throw new Error(error.response?.data)
  }
}

export async function uploadRateCardMapping({ boardId, formData }) {
  if (!formData) {
    throw new Error('Error uploading rate card mapping - formData is required')
  }

  try {
    const { data } = await axios.post(
      `${API_URL}/settings/${boardId}/rate-card-mapping`,
      formData,
      {
        headers: {
          ...(await getAuthHeader()),
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return data
  } catch (error) {
    console.error('Error uploading rate card mapping:', error)
    throw error.response?.data || error
  }
}

export async function uploadSkillsCatalog({ boardId, formData = null, bucketFilePath = null }) {
  // ref: firebase function googleSheetIntegration-import
  console.log('importCSV')

  // Sanity check - Either upload the csv file here or pass the path to the bucket where the file was uploaded
  if ((!formData && !bucketFilePath) || (formData && bucketFilePath)) {
    throw new Error('Error uploading CSV - both formData and bucketFilePath are used')
  }

  try {
    let ret = {}

    ret = await axios.post(`${API_URL}/settings/${boardId}/skills-catalog`, formData, {
      headers: { ...(await getAuthHeader()), 'Content-Type': 'multipart/form-data' }
    })

    if (ret.status === 200) return ret.data // The sheet's title, header, imported data, and importId
    throw new Error(ret.response)
  } catch (error) {
    console.log(error)
    throw new Error(error.response?.data)
  }
}

export async function authorizeOneDrive({ boardId }) {
  console.log('authorizeOneDrive')
  try {
    const ret = await axios.get(`${API_URL}/excel-sync/authorize/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) return ret.data
    throw new Error(ret.response)
  } catch (error) {
    console.log(error)
    throw new Error(error.response?.data)
  }
}

// Get the list of files in the Root
export async function fetchOneDriveFiles({ boardId }) {
  console.log('fetchOneDriveFiles')
  try {
    const ret = await axios.get(`${API_URL}/excel-sync/list/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })
    if (ret.status === 200) return ret.data
    throw new Error(ret.response)
  } catch (error) {
    console.log(error)
    throw new Error(error.response?.data)
  }
}

// Get meta data of an excel file. e.g. headers, title, etc
export async function fetchFileMetaData({ boardId, sourceDoc }) {
  console.log('fetchFileMetaData')
  try {
    const ret = await axios.get(`${API_URL}/excel-sync/fields/${boardId}`, {
      headers: { ...(await getAuthHeader()) },
      params: { sourceDoc }
    })
    if (ret.status === 200) return ret.data
    throw new Error(ret.response)
  } catch (error) {
    console.log(error)
    throw new Error(error.response?.data)
  }
}

export async function fetchExcelImportMappings({ boardId }) {
  try {
    const ret = await axios.get(`${API_URL}/excel-sync/mapping/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) return ret.data // success
    throw new Error(ret)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Updates the excel_import_mapping table with the new mapping and filePath of a board
 */
export async function saveExcelMapping({ boardId, url, sourceDoc, sheetName, mapping }) {
  try {
    const ret = await axios.post(
      `${API_URL}/excel-sync/mapping/${boardId}`,
      { data: { url, sourceDoc, sheetName, mapping } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) return ret.data // success
    throw new Error(ret)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function importExcelData({ boardId }) {
  try {
    const ret = await axios.post(
      `${API_URL}/excel-sync/import/${boardId}`,
      { data: {} },
      { headers: { ...(await getAuthHeader()) } }
    )
    console.log(ret)
    if (ret.status === 200) return ret.data
    throw new Error(ret.response)
  } catch (error) {
    console.log(error)
    throw new Error(error.response?.data)
  }
}
