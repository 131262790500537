import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { sortBy, filter, uniq } from 'lodash-es'
import numeral from 'numeral'

/**
 * Formats the amount according to the zooning factor
 * @param amount The number to be formatted
 * @param factor The zooning factor applied to the amount based on location
 * @returns formatted value
 */
const getFormattedValue = (amount, factor) => Math.round(numeral(amount).value() * factor)

/**
 * Function to get percentage of an amount
 * @param amount The actual amount for which you want percentage amount
 * @param percentage The percentage for the amount
 * @returns The amount after calculating the percentage
 */
const getPercentageAmount = (amount, percentage) => {
  return Math.round(amount * numeral(percentage).value())
}

/**
 * Function to get the pay amount based on whether its calculating base pay, ote pay or total pay
 * @param amount The actual amount for which you want percentage amount
 * @param factor The zooning factor applied to the amount based on location
 * @param percentage The percentage for the amount
 * @param payType ie total, base and ote
 * @returns The amount after calculating the percentage
 */
const getPay = (amount, factor, percentage, payType) => {
  let result = 0
  switch (payType) {
    case 'total':
      result = numeral(getFormattedValue(amount, factor)).format('0,0')
      break
    case 'base':
      result = numeral(
        getPercentageAmount(getFormattedValue(amount, factor), numeral(percentage).value())
      ).format('0,0')
      break
    case 'ote':
      result = numeral(
        getPercentageAmount(getFormattedValue(amount, factor), 1 - numeral(percentage).value())
      ).format('0,0')
      break
    default:
      break
  }
  return result
}

/**
 * Function to reset state to default values
 */
const getDefaultState = () => {
  return {
    salary: {},
    basePay: 0,
    levelBenchmark: [],
    department: [],
    title: [],
    level: [],
    location: [],
    zoningFactorLocation: []
  }
}

const state = getDefaultState()

const getters = {
  compDepartmentSuggestions: (_state) => _state.department,
  compTitleSuggestions: (_state) => _state.title,
  compLevelSuggestions: (_state) => _state.level,
  compLocationSuggestions: (_state) => _state.location,
  compZoningFactorLocationSuggestions: (_state) => _state.zoningFactorLocation,
  compSalary: (_state) => _state.salary,
  compBasePercentage: (_state) => _state.basePay,
  compLevelBenchmark: (_state) => _state.levelBenchmark
}

const mutations = {
  setSalary(_state, salaryObj) {
    _state.salary = salaryObj
  },
  setBasePay(_state, basePay) {
    _state.basePay = basePay
  },
  setLevelBenchmark(_state, levelArr) {
    _state.levelBenchmark = levelArr
  },
  setDepartment(_state, arr) {
    _state.department = arr
  },
  setTitle(_state, arr) {
    _state.title = arr
  },
  setLevel(_state, arr) {
    _state.level = arr
  },
  setZoningFactorLocation(_state, arr) {
    _state.zoningFactorLocation = arr
  },
  setLocation(_state, arr) {
    _state.location = arr
  }
}

const actions = {
  async fetchCompSuggestions(context, { location, department, jobRole, boardId, personId }) {
    let query = firebase
      .firestore()
      .collection('salary_calculator_comp_benchmark')
      .where('boardId', '==', boardId)
    if (location) query = query.where('location', '==', location)
    if (department) query = query.where('department', '==', department)
    if (jobRole) query = query.where('jobRole', '==', jobRole)

    const querySnapshot = await query.get()

    const departmentQuerySnapshot = await firebase
      .firestore()
      .collection('salary_calculator_permissions')
      .where('boardId', '==', boardId)
      .where('accessAllowedPersonIds', 'array-contains', personId)
      .get()

    const zoningFactorLocationQuerySnapshot = await firebase
      .firestore()
      .collection('salary_calculator_zooning_factors')
      .where('boardId', '==', boardId)
      .get()

    const result = []
    const departmentList = []
    const zoningFactorLocationResult = []

    querySnapshot.forEach((doc) => {
      result.push(doc.data())
    })

    departmentQuerySnapshot.forEach((doc) => {
      departmentList.push(doc.data())
    })

    zoningFactorLocationQuerySnapshot.forEach((doc) => {
      zoningFactorLocationResult.push(doc.data())
    })

    if (!location) {
      context.commit(
        'setLocation',
        sortBy(uniq(filter(result, (record) => record.location).map((record) => record.location)))
      )
    }

    if (!department) {
      context.commit(
        'setDepartment',
        sortBy(
          uniq(
            filter(departmentList, (record) => record.department).map((record) => record.department)
          )
        )
      )
    }

    if (!jobRole) {
      context.commit(
        'setTitle',
        sortBy(uniq(filter(result, (record) => record.jobRole).map((record) => record.jobRole)))
      )
    }

    context.commit(
      'setLevel',
      sortBy(
        uniq(filter(result, (record) => record.level).map((record) => record.level.toString()))
      )
    )

    context.commit(
      'setZoningFactorLocation',
      sortBy(
        uniq(
          filter(zoningFactorLocationResult, (record) => record.country).map(
            (record) => record.country
          )
        )
      )
    )
  },

  async fetchCompBenchmark(
    context,
    { department, jobRole, level, zoningFactorLocation, location, boardId }
  ) {
    try {
      let query = firebase
        .firestore()
        .collection('salary_calculator_comp_benchmark')
        .where('boardId', '==', boardId)
        .where('department', '==', department)
        .where('jobRole', '==', jobRole)
        .where('level', '==', level)
      if (location) query = query.where('location', '==', location)

      const compQuerySnapshot = await query.get()

      const zoningFactorLocationQuerySnapshot = await firebase
        .firestore()
        .collection('salary_calculator_zooning_factors')
        .where('boardId', '==', boardId)
        .where('country', '==', zoningFactorLocation)
        .get()

      const result = []
      const zoningFactorLocationResult = []

      compQuerySnapshot.forEach((doc) => {
        result.push(doc.data())
      })

      zoningFactorLocationQuerySnapshot.forEach((doc) => {
        zoningFactorLocationResult.push(doc.data())
      })

      if (result.length > 0) {
        const factor = zoningFactorLocationResult?.[0]?.factor || 1

        const salary = {
          min: {
            total: getPay(result[0]?.minSalary, factor, null, 'total'),
            base: getPay(result[0]?.minSalary, factor, result[0]?.basePayPercentage, 'base'),
            ote: getPay(result[0]?.minSalary, factor, result[0]?.basePayPercentage, 'ote')
          },
          mid: {
            total: getPay(result[0]?.midSalary, factor, null, 'total'),
            base: getPay(result[0]?.midSalary, factor, result[0]?.basePayPercentage, 'base'),
            ote: getPay(result[0]?.midSalary, factor, result[0]?.basePayPercentage, 'ote')
          },
          max: {
            total: getPay(result[0]?.maxSalary, factor, null, 'total'),
            base: getPay(result[0]?.maxSalary, factor, result[0]?.basePayPercentage, 'base'),
            ote: getPay(result[0]?.maxSalary, factor, result[0]?.basePayPercentage, 'ote')
          }
        }

        context.commit('setSalary', salary)
        context.commit(
          'setBasePay',
          result[0]?.basePayPercentage ? numeral(result[0]?.basePayPercentage).value() : null
        )
        return
      }
      context.commit('setSalary', {})
    } catch (error) {
      console.log('error in fetchCompBenchmark', error)
    }
  },

  async fetchCompLevelBenchmark(
    context,
    { department, jobRole, zoningFactorLocation, location, boardId }
  ) {
    try {
      let query = firebase
        .firestore()
        .collection('salary_calculator_comp_benchmark')
        .where('boardId', '==', boardId)
        .where('department', '==', department)
        .where('jobRole', '==', jobRole)
      if (location) query = query.where('location', '==', location)

      // query.where('department', '==', department)
      //   .where('jobRole', '==', jobRole)

      const querySnapshot = await query.get()

      const zoningFactorLocationQuerySnapshot = await firebase
        .firestore()
        .collection('salary_calculator_zooning_factors')
        .where('boardId', '==', boardId)
        .where('country', '==', zoningFactorLocation)
        .get()

      let result = []
      const zoningFactorLocationResult = []

      querySnapshot.forEach((doc) => {
        result.push(doc.data())
      })
      zoningFactorLocationQuerySnapshot.forEach((doc) => {
        zoningFactorLocationResult.push(doc.data())
      })

      if (result.length > 0) {
        const factor = zoningFactorLocationResult?.[0]?.factor || 1

        result = sortBy(
          result,
          (x) =>
            (numeral(x.minSalary).value(),
            numeral(x.midSalary).value(),
            numeral(x.maxSalary).value()) / 3
        )

        const min = uniq(
          filter(result, (record) => record.level).map((record) => ({
            level: `Level ${record.level}`,
            benchmark: Math.round(numeral(record.minSalary).value() * factor),
            base: getPay(record.minSalary, factor, record.basePayPercentage, 'base'),
            ote: getPay(record.minSalary, factor, record.basePayPercentage, 'ote'),
            basePay: record.basePayPercentage ? numeral(record.basePayPercentage).value() : null
          }))
        )
        const mid = uniq(
          filter(result, (record) => record.level).map((record) => ({
            level: `Level ${record.level}`,
            benchmark: Math.round(numeral(record.midSalary).value() * factor),
            base: getPay(record.midSalary, factor, record.basePayPercentage, 'base'),
            ote: getPay(record.midSalary, factor, record.basePayPercentage, 'ote'),
            basePay: record.basePayPercentage ? numeral(record.basePayPercentage).value() : null
          }))
        )
        const max = uniq(
          filter(result, (record) => record.level).map((record) => ({
            level: `Level ${record.level}`,
            benchmark: Math.round(numeral(record.maxSalary).value() * factor),
            base: getPay(record.maxSalary, factor, record.basePayPercentage, 'base'),
            ote: getPay(record.maxSalary, factor, record.basePayPercentage, 'ote'),
            basePay: record.basePayPercentage ? numeral(record.basePayPercentage).value() : null
          }))
        )
        const formatedData = {
          min,
          mid,
          max
        }

        context.commit('setLevelBenchmark', formatedData)
        return
      }
      context.commit('setLevelBenchmark', [])
      context.dispatch('resetSalaryData')
    } catch (error) {
      console.log('error in fetchCompLevelBenchmark', error)
    }
  },

  resetCompData(context) {
    context.dispatch('resetSalaryData')
    context.dispatch('resetGraphData')
  },
  resetSalaryData(context) {
    context.commit('setSalary', {})
  },
  resetGraphData(context) {
    context.commit('setLevelBenchmark', [])
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {}
}
