<template>
  <div ref="menu" class="relative">
    <PopperTooltip content="Boards" placement="bottom" hover open-delay="300">
      <button
        class="flex h-9 items-center gap-2 overflow-hidden truncate rounded-lg bg-agentnoon-org-plan-dropdown-button bg-bluedark-700 px-2 text-sm font-medium text-agentnoon-org-plan-dropdown-button text-white focus:outline-none"
        @click="isMenuOpen = !isMenuOpen"
      >
        <span v-if="project">{{ project.name }}</span>
        <span v-else>{{ board?.boardName ?? '' }}</span>

        <MainBoardPill v-if="boardId === project?.planBoardId || boardId === mainBoardId" />

        <ChevronDownIcon
          class="mr-1 h-5 w-5 transition-transform"
          :class="{ 'rotate-180 transform': isMenuOpen }"
        />
      </button>
    </PopperTooltip>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <ol
        v-if="isMenuOpen"
        class="absolute z-[60] -mr-1 mt-2 flex w-[320px] flex-col rounded-lg border bg-white shadow-lg"
      >
        <button
          class="flex items-center justify-between border-b px-4 py-2.5 text-sm font-medium text-gray-700 hover:bg-gray-100"
          @click="navigateToMainBoard()"
        >
          {{ project?.name ?? mainBoard?.boardName }}
          <MainBoardPill />
        </button>

        <div v-if="isSearchOpen" class="border-b">
          <div
            class="group mx-4 my-2 flex items-center justify-between gap-2 overflow-hidden rounded-lg border border-gray-300 px-3 py-2 transition-all focus-within:ring-1 focus-within:ring-bluedark-600"
          >
            <SearchIcon class="h-5 w-5 text-gray-600 group-focus-within:text-bluedark-600" />
            <input
              ref="filterInput"
              v-model="searchQuery"
              class="w-full border-none bg-white p-0 text-base font-normal text-gray-900 placeholder:text-gray-500 focus:border-none focus:outline-none focus:ring-0"
              type="search"
              placeholder="Search"
              @click.stop
              @dblclick.stop
            />
          </div>
        </div>

        <div
          v-else
          class="flex items-center justify-between border-b px-4 py-2.5 text-sm font-medium text-gray-700"
        >
          <router-link
            :to="{
              name: 'Plans',
              params: { boardId: mainBoardId, projectId }
            }"
            class="hover:text-blue-700"
            @click="isMenuOpen = false"
          >
            All Scenarios
          </router-link>
          <button @click="isSearchOpen = true">
            <SearchIcon class="h-5 w-5 hover:text-bluedark-600" />
          </button>
        </div>

        <div v-if="Object.keys(sortedFolders).length" class="max-h-[400px] overflow-y-auto">
          <li v-for="(folderName, index) in Object.keys(sortedFolders)" :key="'folder-' + index">
            <template v-if="folderName !== 'No folder'">
              <div
                class="flex cursor-default items-center gap-2 px-4 py-2.5 text-left text-sm font-normal text-gray-500"
              >
                <FolderIcon class="h-4 w-4" />
                {{ folderName }}
              </div>
            </template>

            <ol>
              <li v-for="(scenario, pIndex) in sortedFolders[folderName]" :key="'plan-' + pIndex">
                <button
                  class="w-full whitespace-nowrap px-4 py-2.5 text-left text-sm font-normal text-gray-900 hover:bg-gray-100"
                  :class="{ 'pl-8': folderName !== 'No folder' }"
                  @click="navigateToPlan(scenario.boardId)"
                >
                  <ScrollOnHover>
                    {{ scenario.boardName ? scenario.boardName : scenario.name }}
                  </ScrollOnHover>
                </button>
              </li>
            </ol>
          </li>
        </div>
        <p
          v-else
          class="flex items-center justify-between px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-100"
        >
          No scenarios found
        </p>
      </ol>
    </transition>
  </div>
</template>

<script setup>
import useBoard from '@/hooks/use-board'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import ScrollOnHover from './ScrollOnHover.vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'
import { onClickOutside, useMagicKeys } from '@vueuse/core'
import { useRouter } from 'vue-router'
import FolderIcon from '@/assets/untitled-ui/folder.svg'
import SearchIcon from '@/assets/untitled-ui/search.svg'
import { useOwners } from '@/hooks/use-owners'
import { useProjects } from '@/hooks/use-projects'
import MainBoardPill from '@/components/MainBoardPill.vue'

const props = defineProps({
  boardId: {
    type: String,
    required: true
  },
  projectId: {
    type: String,
    required: false,
    default: null
  }
})

const menu = ref(null)
const isMenuOpen = ref(false)
const isSearchOpen = ref(false)
const searchQuery = ref('')

useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (e.key === 'Escape' && e.type === 'keydown') {
      isSearchOpen.value = false
      close()
    }
  }
})

const { getters } = useStore()
const { boardId: mainBoardId } = useBoard()
const { push } = useRouter()
const { projects } = useProjects(mainBoardId)

const project = computed(() => {
  if (props.projectId) {
    return projects.value?.find((project) => project.id === props.projectId)
  }

  return null
})

const mainProjectBoard = computed(() => getters.getBoard(project.value?.planBoardId))
const board = computed(() => getters.getBoard(props.boardId))
const mainBoard = computed(() => getters.getBoard(mainBoardId.value))

const uid = computed(() => getters.uid)
const { owners } = useOwners(mainBoardId, [uid.value])
const ownerProfile = computed(() => owners.value?.[uid.value])

const scenarios = computed(() => {
  const allScenarios = getters.scenarios(mainBoardId.value)
  if (props.projectId) {
    return allScenarios.filter(
      (scenario) =>
        scenario.projectId === props.projectId &&
        scenario.boardId !== mainProjectBoard.value?.boardId
    )
  }

  return allScenarios.filter((scenario) => !scenario.projectId)
})

const sortedFolders = computed(() => {
  const folderObj = {}

  let filteredPlans = scenarios.value
  if (searchQuery.value.length) {
    filteredPlans = scenarios.value.filter((plan) => {
      return plan.boardName.toLowerCase().includes(searchQuery.value.toLowerCase())
    })
  }

  if (filteredPlans.length === 0) {
    return {}
  }
  const noNameFolder = ownerProfile.value?.name
    ? `${ownerProfile?.value?.name}'s Private Folder`
    : 'Private Folder'

  folderObj[noNameFolder] = []

  filteredPlans.forEach((plan) => {
    const folderTag = plan.tags?.find((tag) => tag.type === 'folder')

    if (folderTag?.name) {
      if (folderObj[folderTag.name] == null) {
        folderObj[folderTag.name] = []
      }
      folderObj[folderTag.name].push(plan)
    } else {
      folderObj[noNameFolder].push(plan)
    }
  })

  // Sort the folders by name
  const sortedKeys = Object.keys(folderObj).sort()

  // Sort plans within each folder by boardName or name
  sortedKeys.forEach((key) => {
    folderObj[key].sort((a, b) => {
      const nameA = a.boardName || a.name
      const nameB = b.boardName || b.name
      return nameA.localeCompare(nameB)
    })
  })

  return sortedKeys.reduce((acc, key) => {
    acc[key] = folderObj[key]
    return acc
  }, {})
})

const navigateToPlan = async (planId) => {
  await push({
    name: 'Plan View',
    params: { boardId: mainBoardId.value, planId }
  })
}

const navigateToMainBoard = async () => {
  if (props.projectId) {
    await push({
      name: 'Plan View',
      params: { boardId: mainBoardId.value, planId: project.value?.planBoardId }
    })
  } else {
    await push({
      name: 'Board',
      params: { boardId: mainBoardId.value }
    })
  }
}

onClickOutside(menu, () => {
  isMenuOpen.value = false
  searchQuery.value = ''
  isSearchOpen.value = false
})
</script>

<style scoped>
.etihad-color {
  background-color: #cda75a;
  color: white;
}
</style>
