import { useActiveElement } from '@vueuse/core'
import { computed } from 'vue'

export function useKeyboard() {
  const activeElement = useActiveElement()

  const isUsingInput = computed(() => {
    return (
      activeElement.value?.tagName === 'INPUT' ||
      activeElement.value?.tagName === 'SELECT' ||
      activeElement.value?.tagName === 'OPTION' ||
      activeElement.value?.tagName === 'TEXTAREA' ||
      activeElement.value?.shadowRoot?.activeElement?.tagName === 'INPUT'
    )
  })

  return {
    isUsingInput
  }
}
