import { baseHeight, baseWidth } from '@/lib/OrgNodeData.js'
import { createGlobalState } from '@vueuse/core'
import { computed, readonly, ref } from 'vue'

const useNodeWidths = createGlobalState(() => {
  const nodeWidths = ref({})
  const nodeHeights = ref({})

  const maxWidth = computed(() => {
    let max = 0

    Object.keys(nodeWidths.value).forEach((node) => {
      if (nodeWidths.value[node] > max) max = nodeWidths.value[node]
    })

    return max || baseWidth
  })

  const maxHeight = computed(() => {
    let max = 0

    Object.keys(nodeHeights.value).forEach((node) => {
      if (nodeHeights.value[node] > max) max = nodeHeights.value[node]
    })

    return max || baseHeight
  })

  const maxHeightNoFallback = computed(() => {
    let max = 0

    Object.keys(nodeHeights.value).forEach((node) => {
      if (nodeHeights.value[node] > max) max = nodeHeights.value[node]
    })

    return max
  })

  return {
    maxWidth,
    maxHeight,
    maxHeightNoFallback,
    nodeWidths,
    nodeHeights
  }
})

const useHoveredNode = createGlobalState(() => {
  const hoveredNode = ref(null)

  return {
    hoveredNode
  }
})

const useHighlightedNode = createGlobalState(() => {
  const highlightedNode = ref(null)

  return {
    highlightedNode
  }
})

const useCopiedId = createGlobalState(() => {
  const copiedId = ref(null)

  return {
    copiedId
  }
})

const useHideImages = createGlobalState(() => {
  const hideImages = ref(false)

  return {
    hideImages
  }
})

/**
 * Shared state of visible nodes to help set a common width/height when content size changes
 */
export default function useOrgNodeHelpers() {
  const { nodeWidths, nodeHeights, maxHeight, maxHeightNoFallback, maxWidth } = useNodeWidths()
  const { hoveredNode } = useHoveredNode()
  const { highlightedNode } = useHighlightedNode()
  const { copiedId } = useCopiedId()
  const { hideImages } = useHideImages()

  /**
   * Update width of a card with given id
   * @param id
   * @param width
   */
  const updateWidth = ({ id, width }) => {
    if (!width) return

    nodeWidths.value[id] = width
  }

  /**
   * Update height of a card with given id
   * @param id
   * @param width
   */
  const updateHeight = ({ id, height }) => {
    if (!height) return

    nodeHeights.value[id] = height
  }

  /**
   * Reset initial state
   */
  const reset = () => {
    nodeWidths.value = {}
    nodeHeights.value = {}
    copiedId.value = null
    hoveredNode.value = null
  }

  const setHovered = (id) => {
    hoveredNode.value = id
  }

  const resetHovered = (id) => {
    if (id !== hoveredNode.value) return

    hoveredNode.value = null
  }

  const setCopiedId = (id) => {
    copiedId.value = id
  }

  const setHideImages = (value) => {
    hideImages.value = value
  }

  const triggerHighlight = (id) => {
    highlightedNode.value = id
  }

  return {
    copiedId: readonly(copiedId),
    hideImages: readonly(hideImages),
    highlightedNode: readonly(highlightedNode),
    hoveredNode: readonly(hoveredNode),
    maxHeight,
    maxHeightNoFallback,
    maxWidth,
    reset,
    resetHovered,
    setCopiedId,
    setHideImages,
    setHovered,
    triggerHighlight,
    updateHeight,
    updateWidth
  }
}
