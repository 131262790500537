<template>
  <TransitionRoot as="template" :show="props.show" @after-leave="closeModal">
    <DialogUI as="div" class="fixed inset-0 z-[99] overflow-y-auto" @close="open = false">
      <div
        class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <DialogPanel
            class="inline-block transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
          >
            <div>
              <DialogTitle as="h3" class="text-lg font-medium leading-7 text-gray-900">
                Edit name
              </DialogTitle>
              <div class="mt-5">
                <label for="displayName" class="block text-sm font-medium leading-5 text-gray-700">
                  Your name
                </label>
                <div class="mt-1">
                  <input
                    id="displayName"
                    v-model="displayName"
                    type="text"
                    name="displayName"
                    class="block w-full rounded-md border-0 px-3.5 py-2.5 text-base leading-6 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                    placeholder="John Doe"
                  />
                </div>
              </div>
            </div>
            <div class="mt-8 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                class="inline-flex w-full justify-center rounded-lg bg-bluedark-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                @click="handleSubmit(displayName)"
              >
                Update
              </button>
              <button
                ref="cancelButtonRef"
                type="button"
                class="mt-3 inline-flex w-full justify-center rounded-lg bg-white px-4 py-2 text-base font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                @click="closeModal"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </DialogUI>
  </TransitionRoot>
  <ToastDisplay />
</template>

<script setup>
import {
  DialogOverlay,
  DialogPanel,
  Dialog as DialogUI,
  TransitionChild,
  TransitionRoot,
  DialogTitle
} from '@headlessui/vue'
import ToastDisplay from '@/components/ToastDisplay.vue'
import { ref } from 'vue'
import { useStore } from 'vuex'

const { getters } = useStore()
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
    required: true
  }
})

const displayName = ref(getters.userDisplayName)

const emit = defineEmits(['close', 'handle-submit'])

/**
 * Run the close modal event after leave this modal
 */
const closeModal = () => {
  displayName.value = ''
  emit('close')
}

/**
 * Function to pass selected option to the parent component
 * @param option selected option (ie company, department or team)
 */
const handleSubmit = (option) => {
  emit('handle-submit', option)
  displayName.value = ''
  closeModal()
}
</script>
