import { createStore } from 'vuex'

import user from './user.module'
import people from './people.module.js'
import board from './board.module'
import settings from './settings.module'
import compensations from './compensations.module'
import importData from './importData.module'
import forex from './forex.module'
import syncScenario from './syncScenario.module'
import ppt from './ppt.module'
import impactPlannedChanges from './impactPlannedChanges.module'
import workforceHubCharts from './workforceHubCharts.module'
import operatingModel from './operatingModel.module'
import meta from './meta.module'
import sensitiveData from './sensitiveData.module'
import customFields from './customFields.module'
import accessScopes from './accessScopes.module'
import comments from './comments.module'
import branding from './branding.module'
import cardContent from './cardContent.module'

//WEEE
import performanceReview from './performanceReview.module'

//DEMO ONLY, not porting
import benchmarkData from './benchmarkData.module'
import salaryBenchmark from './salaryBenchmark.module'
import salaryCalculatorCompBenchmark from './salaryCalculatorCompBenchmark.module'
import salaryCalculatorPermissions from './salaryCalculatorPermissions.module'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    people,
    user,
    board,
    settings,
    importData,
    compensations,
    salaryBenchmark,
    forex,
    performanceReview,
    salaryCalculatorCompBenchmark,
    salaryCalculatorPermissions,
    benchmarkData,
    impactPlannedChanges,
    workforceHubCharts,
    syncScenario,
    operatingModel,
    meta,
    ppt,
    sensitiveData,
    customFields,
    accessScopes,
    comments,
    branding,
    cardContent
  }
})
