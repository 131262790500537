import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'
import DecompressWorker from '@/workers/decompress-json.js?worker'
import { until, useWebWorker } from '@vueuse/core'

/**
 * Fetch all sensitive data for a board
 * @returns Array of sensitive data objects
 */
export async function fetchSensitiveData({ boardId }) {
  const { data, post, terminate } = useWebWorker(DecompressWorker)

  try {
    const ret = await axios.get(`${API_URL}/sensitive-data/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      post(ret.data)
      await until(data).changed()
      return data.value
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  } finally {
    terminate()
  }
}

/**
 * Add or update sensitive data for one or more people.
 * The patch does not have to contain all fields.
 * @returns Array of sensitive data objects that were added or updated
 */
export async function addOrUpdateSensitiveData({ boardId, patches }) {
  try {
    const ret = await axios.post(
      `${API_URL}/sensitive-data/${boardId}`,
      { data: { patches } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Add or update sensitive data for one or more people.
 * The patch does not have to contain all fields.
 * @returns Array of sensitive data objects that were added or updated
 */
export async function addOrUpdateSensitiveDataRaw({ boardId, patches }) {
  try {
    const ret = await axios.post(
      `${API_URL}/sensitive-data/${boardId}/raw`,
      { data: { patches } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}
