const hash = {
  'bg-primary': '--bg-agentnoon-primary',
  'bg-light': '--bg-agentnoon-light',
  'bg-light-100': '--bg-agentnoon-light-100',
  'bg-secondary': '--bg-agentnoon-secondary',
  'bg-board-pill': '--bg-agentnoon-board-pill',
  'text-board-pill': '--text-agentnoon-board-pill',
  'bg-board-pill-bullet': '--bg-agentnoon-board-pill-bullet',
  'bg-org-plan-dropdown-button': '--bg-agentnoon-org-plan-dropdown-button',
  'text-org-plan-dropdown-button': '--text-agentnoon-org-plan-dropdown-button',
  'bg-card-open-button': '--bg-agentnoon-card-open-button',
  'bg-card-open-button-hover': '--bg-agentnoon-card-open-button-hover',
  'text-card-open-button': '--text-agentnoon-card-open-button',
  'text-card-open-button-hover': '--text-agentnoon-card-open-button-hover',
  'text-card-title-hover': '--text-agentnoon-card-title-hover',
  'text-card-subtitle-hover': '--text-agentnoon-card-subtitle-hover',
  'bg-card-icon': '--bg-agentnoon-card-icon',
  'bg-card-icon-hover': '--bg-agentnoon-card-icon-hover',
  'text-card-icon': '--text-agentnoon-card-icon',
  'text-card-icon-hover': '--text-agentnoon-card-icon-hover',
  'text-welcome-message': '--text-agentnoon-welcome-message',
  'text-home-button': '--text-agentnoon-home-button'
}

// In the absence of branding property (key), the fallback (value) will
// be used
const fallback = {
  'bg-card-open-button-hover': 'bg-card-open-button'
}

export const toCSS = (branding) => {
  let styles = {}

  Object.keys(branding).forEach((key) => hash[key] && (styles[hash[key]] = branding[key]))

  Object.keys(fallback).forEach((key) => {
    if (!branding[key] && branding[fallback[key]]) {
      styles[hash[key]] = branding[fallback[key]]
    }
  })

  return styles
}
