import { baseHeight, baseWidth } from '@/lib/OMNodeData.js'
import { computed, readonly, ref } from 'vue'

const nodeWidths = ref({})
const nodeHeights = ref({})
const widthCondition = ref(1)
const hoveredNode = ref(null)
const copiedId = ref(null)
const expandedNodes = ref([])
const hideImages = ref(false)
const highlightedNode = ref(null)

const maxWidth = computed(() => {
  let max = 0

  Object.keys(nodeWidths.value).forEach((node) => {
    if (nodeWidths.value[node] > max) max = nodeWidths.value[node]
  })

  return max || baseWidth
})

const maxHeight = computed(() => {
  let max = 0

  Object.keys(nodeHeights.value).forEach((node) => {
    if (nodeHeights.value[node] > max) max = nodeHeights.value[node]
  })

  return max || baseHeight
})

/**
 * Shared state of visible nodes to help set a common width/height when content size changes
 */
export default function useOrgNodeHelpers() {
  /**
   * Update width of a card with given id
   * @param id
   * @param width
   */
  const updateWidth = ({ id, width }) => {
    if (!width) return

    nodeWidths.value[id] = width
  }

  /**
   * Update height of a card with given id
   * @param id
   * @param width
   */
  const updateHeight = ({ id, height }) => {
    if (!height) return

    nodeHeights.value[id] = height
  }

  /**
   * Reset initial state
   */
  const reset = () => {
    nodeWidths.value = {}
    nodeHeights.value = {}
    widthCondition.value = 1
    copiedId.value = null
    hoveredNode.value = null
  }

  /**
   * Sets the ID of the currently hovered node.
   *
   * @param {number} id - The ID of the node being hovered.
   */
  const setHovered = (id) => {
    hoveredNode.value = id
  }

  /**
   * Resets the hovered node to null if the provided ID matches the currently hovered node.
   * @param {string} id - The ID of the node to reset the hovered state for.
   */
  const resetHovered = (id) => {
    if (id !== hoveredNode.value) return

    hoveredNode.value = null
  }

  /**
   * Sets the value of the `copiedId` ref to the provided `id`.
   * @param {string} id - The ID to set as the new value of `copiedId`.
   */
  const setCopiedId = (id) => {
    copiedId.value = id
  }

  /**
   * Adds the given id to the list of expanded nodes if it is not already included.
   * @param {string} id - The id of the node to add to the list of expanded nodes.
   */
  const addExpanded = (id) => {
    if (expandedNodes.value.includes(id)) return

    expandedNodes.value.push(id)
  }

  /**
   * Removes the specified node ID from the list of expanded nodes.
   * @param {string} id - The ID of the node to remove from the list of expanded nodes.
   */
  const removeExpanded = (id) => {
    expandedNodes.value = expandedNodes.value.filter((node) => node !== id)
  }

  /**
   * Checks if a node with the given ID is expanded.
   * @param {string} id - The ID of the node to check.
   * @returns {boolean} - True if the node is expanded, false otherwise.
   */
  const isExpanded = (id) => {
    return expandedNodes.value.includes(id)
  }

  /**
   * Sets the value of hideImages to the provided value.
   * @param {boolean} value - The value to set hideImages to.
   */
  const setHideImages = (value) => {
    hideImages.value = value
  }

  /**
   * Sets the value of the highlightedNode to the given id.
   * @param {string} id - The id of the node to be highlighted.
   */
  const triggerHighlight = (id) => {
    highlightedNode.value = id
  }

  return {
    addExpanded,
    copiedId: readonly(copiedId),
    hideImages: readonly(hideImages),
    highlightedNode: readonly(highlightedNode),
    hoveredNode: readonly(hoveredNode),
    isExpanded,
    maxHeight,
    maxWidth,
    removeExpanded,
    reset,
    resetHovered,
    setCopiedId,
    setHideImages,
    setHovered,
    triggerHighlight,
    updateHeight,
    updateWidth,
    widthCondition
  }
}
