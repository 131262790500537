import { groupBy, isArray, isEqual, mergeWith, uniq } from 'lodash-es'

export const departments = (people) =>
  uniq(people?.filter((item) => item?.department).map((item) => item?.department))

export const groupByDepartment = (people) => {
  return groupBy(people, (emp) => {
    return emp?.department ? emp?.department : 'noDepartment'
  })
}

/**
 * returns an array of unique location names
 */
export const locations = (people) =>
  uniq(people?.filter((item) => item?.officeLocation).map((item) => item?.officeLocation))

/**
 * returns an array of unique location names
 */
export const roles = (people) =>
  uniq(people?.filter((item) => item?.role).map((item) => item?.role))

/**
 * returns people grouped by location
 */
export const groupByLocation = (people) => {
  return groupBy(people, (emp) => {
    return emp?.officeLocation ? emp?.officeLocation : 'noLocation'
  })
}

function mergeCustomizer(objValue, srcValue) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue)
  }
}

export const patchIfChanged = ({ person, patch }) => {
  const updatedPerson = mergeWith({ ...person }, patch, mergeCustomizer)

  if (isEqual(person, updatedPerson)) {
    return { updated: false }
  }

  return { updated: true, person: updatedPerson }
}
