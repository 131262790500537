import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'

export const fetchPlannedChanges = async ({ boardId, filters }) => {
  try {
    const encodedFilters = encodeURIComponent(JSON.stringify(filters))
    const ret = await axios.get(`${API_URL}/plannedChanges/${boardId}?filters=${encodedFilters}`, {
      headers: {
        ...(await getAuthHeader())
      }
    })
    if (ret.status === 200) {
      console.log(ret)
      return ret
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}
