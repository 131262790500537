import { experimental_createPersister } from '@tanstack/query-persist-client-core'
import { get, set, del } from 'idb-keyval'

export function createIdbPersister() {
  return experimental_createPersister({
    storage: {
      getItem: (key) => get(key),
      setItem: (key, value) => {
        return set(key, value)
      },
      removeItem: (key) => del(key)
    },
    //default serialization assumes that the values need to be stored as strings for localStorage
    //this is not required for IndexedDB, so we can just pass through the data
    // serialize: (data) => data,
    // deserialize: (data) => data,
    maxAge: 1000 * 60 * 60 * 48 // 48 hours
  })
}
