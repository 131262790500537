import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import numeral from 'numeral'
import { keyBy } from 'lodash-es'

/**
 * Get default state
 */
const getDefaultState = () => {
  return {
    salary_benchmarks: []
  }
}

/**
 * Get default states
 */
const state = getDefaultState()

const mutations = {
  setSalaryBenchmarks(_state, data) {
    _state.salary_benchmarks = data
  }
}

const getters = {
  salariesBypersonID(_state) {
    return keyBy(_state.salary_benchmarks, (salary) => {
      return salary.personId
    })
  },
  salaryBenchmarkLength(_state) {
    return _state.salary_benchmarks.length
  }
}

/**
 * Parse currency into numeric
 */
const parseCurrencyToNumeric = (currency) => {
  return parseInt(numeral(currency).value(), 10)
}

/**
 * Calculate Salary percentage between min and max
 */
const calculateSalaryPercentage = (benchmark) => {
  if (benchmark.min_salary === '' && benchmark.max_salary === '') {
    benchmark.min_salary = 0
    benchmark.max_salary = 0
  }
  const percentage = benchmark
    ? (
        ((parseCurrencyToNumeric(benchmark.salary) - parseCurrencyToNumeric(benchmark.min_salary)) /
          (parseCurrencyToNumeric(benchmark.max_salary) -
            parseCurrencyToNumeric(benchmark.min_salary))) *
        100
      ).toFixed(2)
    : 'N/A'
  return percentage
}

/**
 * Get delta for percentage
 */
const getDelta = (percentage) => {
  if (percentage > 100) {
    return 'Above Market'
  } else if (percentage > 80 && percentage <= 100) {
    return 'Within Market ("Top Range")'
  } else if (percentage > 30 && percentage <= 80) {
    return 'Within Market ("Mid Range")'
  } else if (percentage >= 0 && percentage <= 30) {
    return 'Within Market ("Bottom end")'
  }
  return 'Below range'
}
/**
 * Get class indicator for percentage
 */
const getClassIndicators = (percentage) => {
  if (percentage > 100) {
    return 'error'
  }
  if (percentage > 30) {
    return 'success'
  } else if (percentage >= 0 && percentage <= 30) {
    return 'warning'
  }
  return 'error'
}

const actions = {
  /* Fetching google sheet mappings from firestore. */
  async fetchSalaryBenchmarkData(ctx, data) {
    const salaries = []
    try {
      await firebase
        .firestore()
        .collection('salary_benchmark_data')
        .where('boardId', '==', data.boardId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const request = doc.data()
            const percentage = calculateSalaryPercentage(request)
            request.penetration_percentage = percentage
            request.delta = getDelta(percentage)
            request.percentageClass = getClassIndicators(percentage)
            request.percentage = percentage < 0 ? 0 : percentage > 100 ? 100 : percentage
            salaries.push(request)
          })
        })
      window.mixpanel.track(`compensation-benchmark-data-fetched`)
      ctx.commit('setSalaryBenchmarks', salaries)
    } catch (e) {
      console.log(e)
      Sentry?.captureException(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
