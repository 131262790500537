import 'firebase/compat/analytics'
import 'firebase/compat/app-check'
import 'firebase/compat/performance'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { createApp, defineCustomElement } from 'vue'
import * as Sentry from '@sentry/vue'
import firebase from 'firebase/compat/app'
import number from '@coders-tm/vue-number-format'
import Popper from 'vue3-popper'
import Vue3Sanitize from 'vue-3-sanitize'
import VueApexCharts from 'vue3-apexcharts'
import VueDatePicker from '@vuepic/vue-datepicker'
import VueVirtualScroller from 'vue-virtual-scroller'
import { VueQueryPlugin } from '@tanstack/vue-query'
import VueTelInput from 'vue-tel-input'
import { createPinia } from 'pinia'

import '@vuepic/vue-datepicker/dist/main.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import 'vue-tel-input/vue-tel-input.css'
import './index.css'
import 'floating-vue/dist/style.css'

import router from './router'
import store from './store'

import App from './App.vue'
import OMNode from './customElements/OMNode.ce.vue'
import OrgNode from './customElements/OrgNode.ce.vue'
import FloatingVue from 'floating-vue'

const app = createApp(App)
const pinia = createPinia()

if (import.meta.env.MODE === 'production') {
  // Production environment
  const firebaseConfig = {
    apiKey: 'AIzaSyCbJ-27TQG4TMws5BxSf99N4wpnMbXaihc',
    authDomain: 'auth.agentnoon.com',
    databaseURL: 'https://orgraph-d57a6.firebaseio.com',
    projectId: 'orgraph-d57a6',
    storageBucket: 'orgraph-d57a6.appspot.com',
    messagingSenderId: '513813238878',
    appId: '1:513813238878:web:2068fa1d9fdd0e152810cf',
    measurementId: 'G-M959PKHY2G'
  }

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig)
  console.log('connected to firebase production')
} else {
  const firebaseConfig = {
    apiKey: 'AIzaSyDq_MtrRO19v5SjaxPzt2D1E0oGtHDR9VA',
    authDomain: 'staging.auth.agentnoon.com',
    projectId: 'agentnoon-staging',
    storageBucket: 'agentnoon-staging.appspot.com',
    messagingSenderId: '706148019191',
    appId: '1:706148019191:web:3a3a0d023b2b352a089cf2'
  }
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig)
  console.log('connected to firebase staging')
}

self.FIREBASE_APPCHECK_DEBUG_TOKEN = import.meta.env.VITE_FIREBASE_APPCHECK_DEBUG_TOKEN
const appCheck = firebase.appCheck()
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
  '6LffVwAdAAAAAMBD9DEBOEKNphvLXoUsceaYHDMH',
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  true
)

Sentry.init({
  app,
  enabled: import.meta.env.MODE !== 'development',
  dsn: 'https://b8bbaeb9f316499aad2b598a051caa32@o970831.ingest.sentry.io/6123697',
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true
    })
  ],
  tracePropagationTargets: [/https:\/\/api(-aus|-eu|-doha|-singapore)?\.agentnoon\.co\/v1/],
  environment: import.meta.env.MODE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true
})

app.use(router)
app.use(store)
app.use(Vue3Sanitize)
app.use(autoAnimatePlugin)
app.use(VueApexCharts)
app.use(VueTelInput)
app.use(number, { precision: 2 })
app.use(VueVirtualScroller)
app.use(FloatingVue)
app.use(VueQueryPlugin)
app.use(pinia)

app.component('PopperTooltip', Popper)
app.component('VueDatePicker', VueDatePicker)

app.mount('#app')

// When an error occurs in Vue app, the native error output does not include
// the line number. This allows it.
app.config.errorHandler = (error) => {
  console.error(error)
}

const anOrgNode = defineCustomElement(OrgNode)
const anOMNode = defineCustomElement(OMNode)

customElements.define('an-org-node', anOrgNode)
customElements.define('an-om-node', anOMNode)
