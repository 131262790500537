import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import * as Sentry from '@sentry/browser'

const Mutations = {
  setBranding: 'setBranding',
  setLogos: 'setLogos',
  setWelcomeMessage: 'setWelcomeMessage'
}

/**
 * generate default state for the module
 */
const getDefaultState = () => {
  return {
    branding: {},
    logos: {
      small: null,
      large: null
    },
    welcomeMessage: {
      message: null,
      class: null
    }
  }
}

const state = getDefaultState()

const getters = {
  branding: (_state) => {
    return _state.branding
  },
  logos: (_state) => {
    return _state.logos
  },
  welcomeMessage: (_state) => {
    return _state.welcomeMessage
  }
}

const mutations = {
  [Mutations.setBranding](_state, branding) {
    _state.branding = branding
  },
  [Mutations.setLogos](_state, logos) {
    _state.logos = logos
  },
  [Mutations.setWelcomeMessage](_state, welcomeMessage) {
    _state.welcomeMessage = welcomeMessage
  }
}

const actions = {
  async fetch(context, { boardId }) {
    let { branding, logos, welcomeMessage } = getDefaultState()

    context.commit(Mutations.setBranding, branding)
    context.commit(Mutations.setLogos, logos)
    context.commit(Mutations.setWelcomeMessage, welcomeMessage)

    if (!boardId) return

    try {
      await firebase
        .firestore()
        .collection('branding_config')
        .doc(boardId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data()
            branding = data.branding
            logos = {
              small: data.logo_small || null,
              large: data.logo_large || null
            }
            welcomeMessage.message = data.welcome_message || null
            welcomeMessage.class = data.welcome_message_class || null
          }
        })

      context.commit(Mutations.setBranding, branding)
      context.commit(Mutations.setLogos, logos)
      context.commit(Mutations.setWelcomeMessage, welcomeMessage)
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
      return
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
