<template>
  <component
    :is="disabled ? 'span' : 'router-link'"
    :to="path"
    :disabled="disabled"
    :class="{
      'bg-gray-50 text-bluedark-600 hover:bg-bluedark-100': isCurrentPath,
      'text-gray-700 hover:bg-bluedark-50 hover:text-bluedark-700': !disabled,
      'cursor-not-allowed text-gray-300': disabled
    }"
    class="flex items-center gap-2 rounded-lg px-[14px] py-2 text-sm font-medium transition-colors"
  >
    <slot v-bind="{ currentPath: isCurrentPath }"></slot>
  </component>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const props = defineProps({
  path: {
    type: String,
    required: false,
    default: '/'
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  }
})
const isCurrentPath = computed(() => route.fullPath.match(/^\/([^?]+)/)?.[0] === props.path)
</script>
