import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'
import { decompressPeople, decompressJson } from '@/utils/Utils'

export const getMeta = async ({ boardId, projectId = null, includeEmployeeData = false }) => {
  try {
    const url = new URL(`${API_URL}/meta/${boardId}`)

    if (projectId) {
      url.searchParams.set('projectId', projectId)
    }

    if (includeEmployeeData) {
      url.searchParams.set('includeEmployeeData', 'true')
    }

    const ret = await axios.get(url, {
      responseType: 'arraybuffer',
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      const data = decompressPeople(ret.data) || {}
      return data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getPlansApprovalMeta = async ({ boardId, boardIds }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/meta/${boardId}/plans-approval`,
      { data: { boardIds } },
      {
        headers: { ...(await getAuthHeader()) }
      }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return {}
  }
}

export const getOrgApprovedRoles = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/meta/${boardId}/org-approved-roles`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getPlanApprovedRoles = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/meta/${boardId}/approved-roles`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getPlanExcludedRoles = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/meta/${boardId}/excluded-roles`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getPlanSubmittedRoles = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/meta/${boardId}/submitted-roles`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getPlanRolesInReview = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/meta/${boardId}/roles-in-review`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return []
  }
}

export const getPlanDatesRange = async ({ boardId }) => {
  try {
    const ret = await axios.get(`${API_URL}/meta/${boardId}/roles-date-range`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return []
  }
}

/**
 * Fetches the list of categories for the org.
 */
export const fetchHubCategories = async ({ boardId }) => {
  try {
    const url = new URL(`${API_URL}/meta/${boardId}/hub-categories`)

    const ret = await axios.get(url, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * For given personIds, calculate the count of occurrences of each value of the given custom fields in the subtree of each personId
 * @param boardId
 * @param personIds
 * @param fields - format: { fieldId, data }
 */
export const countByCustomFields = async ({ boardId, personIds, fields, filters }) => {
  if (!boardId) return {}
  if (!personIds || personIds.length === 0) return {}
  if (!fields || fields.length === 0) return {}

  const ret = await axios.post(
    `${API_URL}/meta/${boardId}/count-by-custom-field`,
    {
      data: {
        personIds,
        fields,
        filters
      }
    },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

/**
 * For given personIds, calculate the count of occurrences of each value of the given custom fields in the subtree of each personId
 * @param boardId
 * @param personIds
 * @param fields - format: { fieldId, data }
 */
export const countByPersonAttribute = async ({ boardId, personIds, attribute }) => {
  if (!boardId) return {}
  if (!personIds || personIds.length === 0) return {}

  const ret = await axios.post(
    `${API_URL}/meta/${boardId}/count-by-attribute`,
    {
      data: {
        personIds,
        attribute
      }
    },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

export async function getRollupSpanOfControl({
  boardId,
  includeApprovedRoles = false,
  collapseOn = null
}) {
  const headers = await getAuthHeader()

  const ret = await axios.post(
    `${API_URL}/meta/${boardId}/rollup-span-of-control`,
    { data: { includeApprovedRoles, collapseOn } },
    {
      responseType: 'arraybuffer',
      headers
    }
  )

  if (ret.status === 200) {
    const data = decompressPeople(ret.data) || []
    return data
  } else throw new Error(ret.statusText)
}

let runningPromise = null

/**
 * Fetches the list of vacant subordinates for a given board ID.
 * @param {string} boardId - The ID of the board.
 * @returns {Promise<Array>} - A promise that resolves to an array of vacant subordinates.
 */
export const vacantSubordinates = async ({ boardId }) => {
  try {
    const url = new URL(`${API_URL}/meta/${boardId}/vacant-subordinates`)

    const ret = await axios.get(url, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return []
  }
}

/**
 * Retrieves the count of vacant subordinates for a given board ID.
 * @param {string} boardId - The ID of the board.
 * @returns {Promise<number|null>} - A promise that resolves to the count of vacant subordinates, or null if an error occurs.
 */
export const countVacantSubordinates = async ({ boardId, filters }) => {
  // If there's already a request running, wait for it and return its result
  if (runningPromise) {
    return runningPromise
  }

  // Start a new request
  runningPromise = (async () => {
    try {
      const ret = await axios.post(
        `${API_URL}/meta/${boardId}/count-vacant-subordinates`,
        { data: { filters } },
        {
          headers: { ...(await getAuthHeader()) }
        }
      )

      if (ret.status === 200) {
        return ret.data
      } else {
        throw new Error(ret.statusText)
      }
    } catch (error) {
      console.log(error)
      return null
    } finally {
      // Clear the running promise when done, regardless of success or failure
      runningPromise = null
    }
  })()

  return runningPromise
}

/**
 * Retrieves the count of To be hired employees in a subtree of a given employee.
 */
export const getToBeHired = async ({ boardId, personId }) => {
  const ret = await axios.get(`${API_URL}/meta/${boardId}/${personId}/to-be-hired`, {
    headers: { ...(await getAuthHeader()) }
  })

  if (ret.status === 200) {
    return ret.data.count
  } else throw new Error(ret.statusText)
}

export const getDynamicFilters = async ({ boardId, filters }) => {
  const ret = await axios.post(
    `${API_URL}/meta/${boardId}/dynamic-filters`,
    { data: { filters } },
    {
      headers: { ...(await getAuthHeader()) }
    }
  )
  if (ret.status === 200) {
    return decompressJson(ret.data) || []
  }
}
