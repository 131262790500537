const PERSON_STATE = {
  NewlyAdded: 'Newly added',
  Removed: 'Removed',
  RIF: 'RIF',
  Moved: 'Moved',
  Edited: 'Edited',
  RemovedManager: 'Removed manager',
  Backfilled: 'Backfilled',
  Backfill: 'Backfill',
  NewReport: 'New Report',
  RemovedReport: 'Removed Report',
  Benched: 'Benched',
  Exited: 'Exited'
}

export const PERSON_STATE_ORDER = {
  [PERSON_STATE.NewlyAdded]: 0,
  [PERSON_STATE.Moved]: 1,
  [PERSON_STATE.Edited]: 2,
  [PERSON_STATE.Backfill]: 3,
  [PERSON_STATE.Backfilled]: 4,
  [PERSON_STATE.RIF]: 5
}

export const SPECIAL_STATES = [PERSON_STATE.RIF, PERSON_STATE.Exited, PERSON_STATE.NewlyAdded]

export default PERSON_STATE
