import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'
import { compress } from '@/utils/Utils.js'
import pako from 'pako'

export const getAllPacks = async ({ boardId }) => {
  const ret = await axios.get(`${API_URL}/ppt/${boardId}`, {
    headers: { ...(await getAuthHeader()) }
  })

  const data = ret.data

  console.log(`getAllPacks: ${ret.status}, size: ${data.length}`)

  if (ret.status === 200) {
    return data
  } else throw new Error(ret.statusText)
}

export const downloadThePack = async ({ boardId, packId }) => {
  const ret = await axios.get(`${API_URL}/ppt/${boardId}/${packId}`, {
    responseType: 'blob',
    headers: { ...(await getAuthHeader()) }
  })
  console.log('##### ppt ret:', ret)
  if (ret.status === 200) {
    const readFileFunction = async (file) => {
      const promise = await new Promise((resolve) => {
        const fileReader = new FileReader()
        fileReader.onload = () => resolve(fileReader.result)
        fileReader.readAsArrayBuffer(file)
      })
      return promise
    }

    // Extract the compressed buffer from the file object
    const compressedContent = await readFileFunction(ret.data)

    // Decompress the array buffer
    const data = pako.inflate(compressedContent, { level: 5 })

    return data
  } else throw new Error(ret.statusText)
}

export const createPack = async ({ boardId, pack }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/ppt/${boardId}/create`,
      { data: { pack } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return true
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const deletePack = async ({ boardId, packId }) => {
  try {
    const ret = await axios.delete(`${API_URL}/ppt/${boardId}/${packId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return true
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const deleteSlide = async ({ boardId, packId, slideId }) => {
  try {
    const ret = await axios.delete(`${API_URL}/ppt/${boardId}/${packId}/${slideId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return true
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const addSlide = async ({ boardId, packId, slide }) => {
  try {
    // Compress the slides and upload as a file
    const deflated = compress(slide, 5)
    const file = new File([deflated], 'slide', { type: 'text/plain' })
    const formData = new FormData()
    formData.append('slideFile', file, 'slide.txt')

    const ret = await axios.post(`${API_URL}/ppt/${boardId}/${packId}/slide`, formData, {
      headers: {
        ...(await getAuthHeader()),
        'Content-Type': 'multipart/form-data'
      }
    })

    if (ret.status === 200) {
      return true
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Call this function when the order of a slides in a given packId is changed.
 * Pass an object array where each object contains the slide ID and index field.
 */
export const orderSlides = async ({ boardId, packId, slides }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/ppt/${boardId}/${packId}/order`,
      { data: { slides } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return true
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}
