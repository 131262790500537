import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useFilterStore = defineStore('filter', () => {
  const filterHistory = ref({})

  const updateFilterHistory = (filterKey, managerId, filters) => {
    filterHistory.value[filterKey] = {
      [managerId]: filters
    }
  }

  return { filterHistory, updateFilterHistory }
})

export const useFilterValuesStore = defineStore('filterValues', () => {
  const values = ref({})
  const categories = ref({})
  const unfilteredCategories = ref({})

  const setValues = (key, value) => {
    values.value[key] = value
  }

  const setCategories = (key, value) => {
    categories.value[key] = value
  }

  const setUnfilteredCategories = (key, newUnfilteredCategories) => {
    unfilteredCategories.value[key] = newUnfilteredCategories
  }

  return {
    categories,
    values,
    unfilteredCategories,
    setCategories,
    setValues,
    setUnfilteredCategories
  }
})
