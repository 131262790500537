import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'
import * as Sentry from '@sentry/browser'

const state = {
  locations: [],
  roles: []
}

const getters = {
  salaryBenchmarkLocations: (_state) => _state.locations,
  salaryBenchmarkRoles: (_state) => _state.roles
}

const mutations = {
  setLocations(_state, _locations) {
    _state.locations = _locations
  },
  setRoles(_state, _roles) {
    _state.roles = _roles
  }
}

const actions = {
  async fetchSalaryBenchmark(context) {
    try {
      const data = await firebase
        .functions()
        .httpsCallable('salaryBenchmark-list')()
        .then((res) => res.data)

      context.commit('setLocations', data.locations)
      context.commit('setRoles', data.roles)
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
      return
    }
  },
  async fetchSalaryInfo(context, payload) {
    try {
      const { location, role } = payload
      const data = await firebase
        .functions()
        .httpsCallable('salaryBenchmark-fetch')({ location, role })
        .then((res) => res.data)
      return data
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
      return
    }
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
