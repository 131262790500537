export const PLAN_VIEW_PAGES = {
  Org: 'org',
  List: 'list',
  Forecast: 'forecast',
  Hub: 'hub',
  Activity: 'activity',
  Integration: 'integration',
  Upload: 'upload'
}

export const PLAN_VIEW_ROUTES = {
  [PLAN_VIEW_PAGES.Org]: {
    Home: 'home'
  },
  [PLAN_VIEW_PAGES.Forecast]: {
    Home: 'home'
  },
  [PLAN_VIEW_PAGES.Hub]: {
    Home: 'home'
  },
  [PLAN_VIEW_PAGES.Activity]: {
    Home: 'home'
  },
  [PLAN_VIEW_PAGES.List]: {
    Home: 'home',
    Formulas: 'formulas',
    Integration: 'integration',
    Upload: 'upload',
    Export: 'export'
  },
  [PLAN_VIEW_PAGES.Integration]: {
    Home: 'home',
    Sheets: 'sheets',
    Excel: 'excel',
    CSV: 'csv'
  },
  [PLAN_VIEW_PAGES.Upload]: {
    Sheets: 'sheets',
    CSV: 'csv'
  }
}
