<script setup>
import { useHierarchyFetch } from '@/hooks/use-hierarchy-fetch'
import { BaseHierarchy } from '@/lib/BaseHierarchy'
import { computed } from 'vue'

const props = defineProps({
  boardId: {
    type: String,
    required: true
  }
})

const boardIdComputed = computed(() => props.boardId)

useHierarchyFetch(boardIdComputed, BaseHierarchy)
useHierarchyFetch(boardIdComputed)
</script>
