import store from '@/store'
import router from '@/router'

// add user to main board as manager, add as a collaborator to plans and marks invitations as visited
const acceptPlanInvite = async (email) => {
  try {
    const planInvites = await store.dispatch('fetchPendingPlanInvitations', {
      email
    })

    window.mixpanel.track('new_invited_user_to_plan')
    // add user to the main board and all the plans he has been invited to
    await store.dispatch('acceptPlanInvitationForOutsiders', email)

    if (planInvites.length === 1) {
      await router.push({
        name: 'Plan View',
        params: { boardId: planInvites[0].boardId, planId: planInvites[0].planBoardId }
      })
    } else {
      await router.push({ name: 'Plans', params: { boardId: planInvites[0].boardId } })
    }
  } catch (err) {
    console.error('error occured in accept plan invite service', err)
  }
}

export default acceptPlanInvite
