import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/functions'
import { addInvitation, updateInvitations, sendInvite } from '@/services/invitations-api.service.js'
import {
  createPlanInvitationForOutsider,
  sendPlanInvitationEmail
} from '@/services/plan-invitations-api.service.js'

/**
 * Encapsulates the logic for sending a board invite - add invitation to DB and send email
 */
export async function sendBoardInvite({
  boardName,
  boardId,
  invitedBy,
  inviterEmail,
  recipientEmail,
  recipientName,
  accessLevel,
  isReminder,
  sendEmail = true
}) {
  try {
    const origin = window.location.origin

    // Remove existing invites
    await updateInvitations({
      boardId,
      recipientEmail,
      patch: {
        ignore: true
      }
    })

    if (sendEmail) {
      const template_id = isReminder
        ? 'd-77d1d25cb3b54734bd7589fb99e17f71'
        : 'd-fa518f0c3eef405495fe2802bb7c7933'

      await sendInvite({
        origin,
        boardName,
        invitedBy,
        recipientEmail,
        recipientName,
        template_id,
        boardId
      })
    }

    await addInvitation({
      invitation: {
        accessLevel,
        boardId,
        boardName,
        invitationAccepted: false,
        invitedBy,
        inviterEmail,
        recipient: recipientEmail,
        recipientName
      }
    })
    window.mixpanel.track('invite_others')
  } catch (error) {
    console.error(error)
    Sentry?.captureException(error)
  }
}

/**
 * Encapsulates the logic for sending a plan invite email
 */
export async function sendPlanInviteEmail({ boardName, boardId, invitedBy, recipients }) {
  try {
    await sendPlanInvitationEmail({
      boardName,
      boardId,
      recipients,
      invitedBy
    })
  } catch (error) {
    console.error(error)
    Sentry?.captureException(error)
  }
}

/**
 * Encapsulates the logic for sending a plan invite to outsiders - add invitation to DB and send email
 */
export async function sendPlanInviteToOutsiders({ boardId, invitation, recipientName, origin }) {
  try {
    const sendEmail = firebase.functions().httpsCallable('sendPlanInviteEmailToOutsiders')

    await sendEmail({
      origin,
      planName: invitation.planName,
      invitedBy: invitation.invitedBy,
      recipient: invitation.recipient,
      recipientName
    })

    await createPlanInvitationForOutsider({ boardId, invitation })
  } catch (error) {
    console.error(error)
    Sentry?.captureException(error)
  }
}
