import { getTypes, addOrUpdateField, remove } from '@/services/custom-fields-api.service'
import { keyBy } from 'lodash-es'

const getDefaultState = () => {
  return {
    customFields: [],
    typesById: {},
    uniqueValuesByTypeId: {},
    isFetchingTypes: false
  }
}

const state = getDefaultState()

const MUTATION_SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS'
const UPDATE_CUSTOM_FIELDS = 'UPDATE_CUSTOM_FIELDS'
const REMOVE_CUSTOM_FIELDS = 'REMOVE_CUSTOM_FIELDS'
const MUTATION_SET_IS_FETCHING_TYPES = 'SET_IS_FETCHING_TYPES'

const mutations = {
  [MUTATION_SET_CUSTOM_FIELDS](state, { boardId, customFields }) {
    if (!state[boardId]) {
      state[boardId] = getDefaultState()
    }

    state[boardId].customFields = customFields
    state[boardId].typesById = keyBy(customFields, 'id')
  },
  [UPDATE_CUSTOM_FIELDS](state, { boardId, customFields }) {
    if (!state[boardId]) {
      state[boardId] = getDefaultState()
    }

    //update existing fields and add new ones
    customFields.forEach((field) => {
      const existingField = state[boardId].customFields.find((f) => f.id === field.id)

      if (existingField) {
        Object.assign(existingField, field)
        state[boardId].typesById[field.id] = existingField
      } else {
        state[boardId].customFields.push(field)
        state[boardId].typesById[field.id] = field
      }
    })
  },
  [REMOVE_CUSTOM_FIELDS](state, { boardId, fieldIds }) {
    state[boardId].customFields = state[boardId].customFields.filter(
      (f) => !fieldIds.includes(f.id)
    )

    fieldIds.forEach((fieldId) => {
      delete state[boardId].typesById[fieldId]
    })
  },
  [MUTATION_SET_IS_FETCHING_TYPES](state, { boardId, status }) {
    if (!state[boardId]) {
      state[boardId] = getDefaultState()
    }
    state[boardId].isFetchingTypes = status
  }
}

const getters = {
  types: (state) => (boardId) => {
    return state[boardId]?.customFields || []
  },
  typesById: (state) => (boardId) => {
    return state[boardId]?.typesById || {}
  },
  /**
   * Use to get types for plan and main org
   */
  orgTypesById: (state, getters, __, rootGetters) => (boardId) => {
    const board = rootGetters['getBoard'](boardId)
    const mainBoardId = board.originalBoardId || boardId

    let allTypes = { ...getters['typesById'](boardId) }
    if (mainBoardId !== boardId) {
      allTypes = { ...allTypes, ...getters['typesById'](mainBoardId) }
    }

    return allTypes
  },
  orgTypes: (state, getters, __, rootGetters) => (boardId) => {
    const board = rootGetters['getBoard'](boardId)
    const mainBoardId = board.originalBoardId || boardId

    let allTypes = [...getters['types'](boardId)]
    if (mainBoardId !== boardId) {
      allTypes = [...allTypes, ...getters['types'](mainBoardId)]
    }

    return allTypes
  },
  isFetchingTypes: (state) => (boardId) => {
    return state[boardId]?.isFetchingTypes ?? false
  }
}

const actions = {
  fetch: async ({ commit, getters }, { boardId, force = true }) => {
    try {
      if (!force && getters['isFetchingTypes'](boardId)) {
        return
      }
      if (!force && state[boardId]?.customFields?.length > 0) {
        return
      }

      commit(MUTATION_SET_IS_FETCHING_TYPES, { boardId, status: true })
      const customFields = await getTypes({ boardId })
      commit(MUTATION_SET_CUSTOM_FIELDS, { boardId, customFields })
      commit(MUTATION_SET_IS_FETCHING_TYPES, { boardId, status: false })
    } catch (error) {
      console.log(error)
      commit(MUTATION_SET_IS_FETCHING_TYPES, { boardId, status: false })
    }
  },
  addOrUpdateFields: async ({ commit }, { boardId, fields }) => {
    try {
      const customFields = await addOrUpdateField({ boardId, fields })
      commit(UPDATE_CUSTOM_FIELDS, { boardId, customFields })
    } catch (error) {
      console.log(error)
    }
  },
  remove: async ({ commit }, { boardId, fieldId }) => {
    try {
      await remove({ boardId, fieldId })
      commit(REMOVE_CUSTOM_FIELDS, { boardId, fieldIds: [fieldId] })
    } catch (error) {
      console.log(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
