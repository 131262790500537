import {
  getAccessScopes,
  deleteAccessScope,
  updateAccessScopes,
  fetchCanViewManagementCost,
  fetchAccessScopesUsers,
  fetchFieldPermissions
} from '@/services/accessScopes-api.service'
import * as Sentry from '@sentry/browser'
import { fetchAccessScopesMeta } from '@/services/accessScopes-api.service'

const getDefaultState = () => {
  return {
    accessScopes: [],
    departments: [],
    locations: [],
    managers: [],
    customFieldsTypes: [],
    customFieldsValues: [],
    sensitiveData: [],
    isFetched: false,
    canViewManagementCost: false,
    users: [],
    fieldPermissions: {}
  }
}

const state = getDefaultState()

const mutations = {
  SET_ACCESS_SCOPES: (_state, scopes) => {
    _state.accessScopes = scopes
  },
  DELETE_ACCESS_SCOPE: (_state, scopeId) => {
    const scopeIndex = _state.accessScopes.findIndex((s) => s.scopeId === scopeId)
    if (scopeIndex >= 0) _state.accessScopes.splice(scopeIndex, 1)

    for (const user of _state.users) {
      const scopeInd = user.accessScopes?.findIndex((s) => s.scopeId === scopeId)
      if (scopeInd >= 0) {
        user.accessScopes.splice(scopeInd, 1)
        break
      }
    }
  },
  SET_DEPARTMENTS(_state, departments) {
    _state.departments = departments
  },
  SET_LOCATIONS(_state, locations) {
    _state.locations = locations
  },
  SET_MANAGERS(_state, managers) {
    _state.managers = managers
  },
  SET_CUSTOM_FIELDS_TYPES(_state, customFieldsTypes) {
    _state.customFieldsTypes = customFieldsTypes
  },
  SET_CUSTOM_FIELDS_VALUES(_state, customFieldsValues) {
    _state.customFieldsValues = customFieldsValues
  },
  SET_SENSITIVE_DATA(_state, sensitiveData) {
    _state.sensitiveData = sensitiveData
  },
  SET_IS_FETCHED(_state, isFetched) {
    _state.isFetched = isFetched
  },
  SET_CAN_VIEW_MANAGEMENT_COST(_state, canView) {
    _state.canViewManagementCost = canView
  },
  SET_USERS(_state, userInfo) {
    _state.users = userInfo
  },
  SET_FIELD_PERMISSIONS(_state, fieldPermissions) {
    _state.fieldPermissions = fieldPermissions
  }
}

const getters = {
  accessScopes: (_state) => _state.accessScopes ?? [],
  departments: (_state) => _state.departments ?? [],
  locations: (_state) => _state.locations ?? [],
  managers: (_state) => _state.managers ?? [],
  customFieldsTypes: (_state) => _state.customFieldsTypes ?? [],
  customFieldsValues: (_state) => _state.customFieldsValues ?? [],
  sensitiveData: (_state) => _state.sensitiveData ?? [],
  isFetched: (_state) => _state.isFetched,
  canViewManagementCost: (_state) => _state.canViewManagementCost,
  users: (_state) => _state.users,
  fieldPermissions: (_state) => _state.fieldPermissions
}

const actions = {
  async fetchAccessScopes(context, { boardId }) {
    try {
      const scopes = await getAccessScopes({ boardId })
      context.commit('SET_ACCESS_SCOPES', scopes)
    } catch (error) {
      console.log('Error in fetchAccessScopes', error)
      Sentry?.captureException(error)
    }
  },

  async deleteAccessScope(context, { boardId, scopeId, targetUid }) {
    try {
      await deleteAccessScope({ boardId, scopeId, targetUid })
      context.commit('DELETE_ACCESS_SCOPE', scopeId)
      return true
    } catch (error) {
      console.log('Error in deleteAccessScope', error)
      Sentry?.captureException(error)
      return false
    }
  },

  async updateAccessScopes({ commit, getters }, { targetUid, accessScopes, boardId }) {
    try {
      const allUserScopes = await updateAccessScopes({
        targetUid,
        accessScopes,
        boardId
      })

      // Replace the user's scopes with the new ones
      const otherScopes = getters.accessScopes.filter((scope) => scope.uid !== targetUid)
      const allScopes = otherScopes.concat(allUserScopes)
      commit('SET_ACCESS_SCOPES', allScopes)

      const users = getters.users
      const user = users.find((_user) => _user.uid === targetUid)
      if (user) {
        user.accessScopes = allUserScopes
        commit('SET_USERS', users)
      }

      return true
    } catch (error) {
      console.log('Error in updateAccessScopes', error)
      Sentry?.captureException(error)
    }
  },

  async fetchAccessScopesMeta({ commit, getters }, { boardId, force = false }) {
    if (!force && getters.isFetched) return

    try {
      const result = await fetchAccessScopesMeta({ boardId })

      commit('SET_DEPARTMENTS', result.departments)
      commit('SET_LOCATIONS', result.locations)
      commit('SET_MANAGERS', result.managers)
      commit('SET_CUSTOM_FIELDS_TYPES', result.customFieldsTypes)
      commit('SET_CUSTOM_FIELDS_VALUES', result.customFieldsValues)
      commit('SET_SENSITIVE_DATA', result.sensitiveData)
      commit('SET_IS_FETCHED', true)
    } catch (e) {
      console.error('fetchAccessScopesMeta err', e)
    }
  },

  async fetchCanViewManagementCost({ commit }, { boardId }) {
    try {
      const canView = await fetchCanViewManagementCost({ boardId })
      commit('SET_CAN_VIEW_MANAGEMENT_COST', canView)
    } catch (e) {
      console.error('fetchCanViewManagementCost err', e)
    }
  },

  async fetchAccessScopesUsers({ commit }, { boardId }) {
    try {
      const result = await fetchAccessScopesUsers({ boardId })
      commit('SET_USERS', result)
    } catch (e) {
      console.error('fetchAccessScopesUsers err', e)
    }
  },

  async fetchFieldPermissions({ commit }, { boardId }) {
    try {
      const result = await fetchFieldPermissions({ boardId })
      commit('SET_FIELD_PERMISSIONS', result)
    } catch (e) {
      console.error('fetchAccessScopesUsers err', e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
