import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'

export async function getAllowedValues({ boardId }) {
  try {
    const ret = await axios.get(`${API_URL}/allowed-values/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    }

    throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const updateAllowedValues = async ({ boardId, payload }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/allowed-values/${boardId}`,
      { data: { payload } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}
