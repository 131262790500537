import { createIdbPersister } from '@/lib/QueryPersisterIdb'
import { getOwnersProfiles } from '@/services/people-api.service'
import { useQuery } from '@tanstack/vue-query'
import { computed, unref } from 'vue'

export function useOwners(boardId, uids) {
  const persister = createIdbPersister()

  const queryKey = computed(() => ['owners', { boardId: unref(boardId), uids: unref(uids) }])

  const { data: owners, isPending: isLoading } = useQuery({
    queryKey,
    queryFn: () => getOwnersProfiles({ boardId: unref(boardId), uids: unref(uids) }),
    persister
  })

  return {
    /**
     * Map of uid => { name, email, avatar }
     */
    owners,
    isLoading
  }
}
