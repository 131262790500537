import { fetchPlannedChanges } from '@/services/planned-changes-api.service'

// Default state
const getDefaultState = () => ({
  plannedChangesChange: [],
  plannedChangesTime: {}
})

const state = {
  plannedChangesByBoard: {}
}

const mutations = {
  setPlannedChanges(_state, { boardId, data }) {
    // Initialize state for the boardId if it doesn't exist
    if (!_state.plannedChangesByBoard[boardId]) {
      _state.plannedChangesByBoard[boardId] = getDefaultState()
    }

    // Set the data for the specific boardId
    _state.plannedChangesByBoard[boardId].plannedChangesChange = data.change || []
    _state.plannedChangesByBoard[boardId].plannedChangesTime = data.time || {}
  }
}

const getters = {
  getPlannedChangesChange: (_state) => (boardId) =>
    _state.plannedChangesByBoard[boardId]?.plannedChangesChange || [],
  getPlannedChangesTime: (_state) => (boardId) =>
    _state.plannedChangesByBoard[boardId]?.plannedChangesTime || {}
}

const actions = {
  async fetchPlannedChanges({ commit }, { boardId, filters = [] }) {
    try {
      const result = await fetchPlannedChanges({ boardId, filters })
      commit('setPlannedChanges', { boardId, data: result.data })
    } catch (e) {
      console.log(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
