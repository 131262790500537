export const baseWidth = 232
export const baseHeight = 154

export const colorForVariant = (variant) => {
  switch (variant) {
    case 0:
      return 'bg-moss-25 ring-1 ring-moss-300 hover:bg-moss-100 hover:ring-moss-400'
    case 1:
      return 'bg-bluedark-25 ring-bluedark-300 hover:bg-bluedark-100 hover:ring-bluedark-400'
    case 2:
      return 'bg-rose-25 ring-rose-300 hover:bg-rose-100 hover:ring-rose-400'
    case 3:
      return 'bg-orangedark-25 ring-orangedark-300 hover:bg-orangedark-100 hover:ring-orangedark-400'
    case 4:
      return 'bg-greenlight-25 ring-greenlight-300 hover:bg-greenlight-100 hover:ring-greenlight-400'
    case 5:
      return 'bg-blue-25 ring-blue-300 hover:bg-blue-100 hover:ring-blue-400'
    case 6:
      return 'bg-orange-25 ring-orange-300 hover:bg-orange-100 hover:ring-orange-400'
    case 7:
      return 'bg-pink-25 ring-pink-300 hover:bg-pink-100 hover:ring-pink-400'
    case 8:
      return 'bg-bluelight-25 ring-bluelight-300 hover:bg-bluelight-100 hover:ring-bluelight-400'
    case 9:
      return 'bg-indigo-25 ring-indigo-300 hover:bg-indigo-100 hover:ring-indigo-400'
    case 10:
      return 'bg-fuchsia-25 ring-fuchsia-300 hover:bg-fuchsia-100 hover:ring-fuchsia-400'
    case 11:
      return 'bg-green-25 ring-green-300 hover:bg-green-100 hover:ring-green-400'
    case 12:
      return 'bg-cyan-25 ring-cyan-300 hover:bg-cyan-100 hover:ring-cyan-400'
    case 13:
      return 'bg-purple-25 ring-purple-300 hover:bg-purple-100 hover:ring-purple-400'
    case 14:
      return 'bg-violet-25 ring-violet-300 hover:bg-violet-100 hover:ring-violet-400'
    case 15:
      return 'bg-yellow-25 ring-yellow-300 hover:bg-yellow-100 hover:ring-yellow-400'
    case 16:
      return 'bg-teal-25 ring-teal-300 hover:bg-teal-100 hover:ring-teal-400'
  }
}

export const pillColorForVariant = (variant) => {
  switch (variant) {
    case 0:
      return 'bg-moss-700 text-moss-50 group-hover:text-moss-100'
    case 1:
      return 'bg-bluedark-700 text-bluedark-50 group-hover:text-bluedark-100'
    case 2:
      return 'bg-rose-700 text-rose-50 group-hover:text-rose-100'
    case 3:
      return 'bg-orangedark-700 text-orangedark-50 group-hover:text-orangedark-100'
    case 4:
      return 'bg-greenlight-700 text-greenlight-50 group-hover:text-greenlight-100'
    case 5:
      return 'bg-blue-700 text-blue-50 group-hover:text-blue-100'
    case 6:
      return 'bg-orange-700 text-orange-50 group-hover:text-orange-100'
    case 7:
      return 'bg-pink-700 text-pink-50 group-hover:text-pink-100'
    case 8:
      return 'bg-bluelight-700 text-bluelight-50 group-hover:text-bluelight-100'
    case 9:
      return 'bg-indigo-700 text-indigo-50 group-hover:text-indigo-100'
    case 10:
      return 'bg-fuchsia-700 text-fuchsia-50 group-hover:text-fuchsia-100'
    case 11:
      return 'bg-green-700 text-green-50 group-hover:text-green-100'
    case 12:
      return 'bg-cyan-700 text-cyan-50 group-hover:text-cyan-100'
    case 13:
      return 'bg-purple-700 text-purple-50 group-hover:text-purple-100'
    case 14:
      return 'bg-violet-700 text-violet-50 group-hover:text-violet-100'
    case 15:
      return 'bg-yellow-700 text-yellow-50 group-hover:text-yellow-100'
    case 16:
      return 'bg-teal-700 text-teal-50 group-hover:text-teal-100'
    default:
      return 'bg-indigo-50 text-indigo-700 mix-blend-multiply bg-blend-multiply'
  }
}
