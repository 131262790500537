export const FilterType = {
  Department: 'department',
  Role: 'role',
  Level: 'level',
  Location: 'officeLocation',
  Manager: 'manager',
  CustomField: 'customField',
  CustomFieldV2: 'customFieldv2',
  ScenarioStatus: 'scenarioStatus',
  WorkforceStatus: 'workforceStatus',
  SensitiveData: 'sensitiveData',
  PositionPlanning: 'positionPlanning',
  PositionPlanningScenarioPositions: 'positionPlanningScenarioPositions',
  PositionPlanningScenarioEmployees: 'positionPlanningScenarioEmployees'
}
