<template>
  <div
    ref="widthSource"
    class="org-node group relative flex h-full max-w-[232px] flex-col rounded-xl shadow-md ring-1 transition-all duration-200"
    :class="[
      cardWrapperClass.join(' '),
      draggingOver === 'true' ? 'shadow-3xl border border-dashed border-gray-600' : '',
      isSelected
        ? 'ring-2 ring-bluedark-600 hover:ring-2 hover:ring-bluedark-800'
        : 'ring-gray-300 hover:ring-1 hover:ring-gray-600',
      pulseAnimation ? 'pulseAnimation' : '',
      highlightAnimation ? 'highlightAnimation' : '',
      isDragging ? 'shadow-xl ring-2 hover:ring-2' : ''
    ]"
    data-test="org-node"
  >
    <div
      :class="{
        'blur-sm': isRemovedManager
      }"
    >
      <div v-if="!isNoManager && !hideImages" class="absolute w-full -translate-y-1/2">
        <img
          v-if="avatarUrl && isFieldEnabled(Fields.name)"
          class="mx-auto h-12 w-12 rounded-full object-cover"
          :src="avatarUrl"
          :onerror="`this.src='https://ui-avatars.com/api/?name=${name}&background=50B5FF&color=fff'`"
        />
      </div>

      <transition
        enter-active-class="transform transition-all"
        enter-from-class="opacity-0 scale-50"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition-all transform"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-50"
      >
        <div
          v-if="isRif"
          class="font-xs absolute -top-3 right-6 flex items-center rounded-full bg-orangedark-700 px-2 py-1 font-medium text-white"
        >
          <UserMinusIcon class="white-icon mr-1 h-3 w-3 stroke-white" />
          RIF
        </div>
        <template v-else-if="mode === modes.changes">
          <div
            v-if="isPersonLeaving"
            class="font-xs absolute -top-3 right-4 rounded-full bg-orangedark-700 px-2 py-1 font-medium text-white"
          >
            Leaving
          </div>
          <div
            v-else-if="isNewHire"
            class="font-xs absolute -top-3 right-4 rounded-full bg-green-700 px-2 py-1 font-medium text-white"
          >
            New hire
          </div>
          <div
            v-else-if="isEdited && mode === modes.changes"
            class="font-xs absolute -top-3 right-4 rounded-full bg-yellow-700 px-2 py-1 font-medium text-yellow-50"
          >
            Changed
          </div>
        </template>
      </transition>

      <transition
        enter-active-class="transform transition-all"
        enter-from-class="opacity-0 scale-50"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition-all transform"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-50"
      >
        <div
          v-if="isSelected"
          class="absolute right-1 top-1 z-20 flex items-center rounded-full px-2 py-1 font-medium text-white"
        >
          <SelectionIcon class="h-5 w-5 text-bluedark-700" />
        </div>
      </transition>

      <div class="w-full overflow-hidden">
        <div
          ref="heightSource"
          class="relative z-10 flex w-full cursor-grab flex-col gap-4 px-4 pb-4"
          :class="{
            'pt-4': !isFieldEnabled(Fields.name),
            'pt-8': isFieldEnabled(Fields.name)
          }"
        >
          <div class="w-full">
            <div
              class="flex flex-col items-center justify-center transition-colors duration-200"
              :class="{
                'bg-error-25 group-hover:bg-error-50': isRemovedManager
              }"
            >
              <template v-if="isApprovedRole">
                <div
                  class="min-h-fit w-full cursor-pointer truncate text-center text-base font-normal text-gray-900 hover:text-blue-600"
                >
                  {{ role || 'New role' }}
                </div>
              </template>
              <template v-else-if="isNoManager">
                <div
                  class="h-full w-full cursor-pointer truncate text-center text-base font-normal text-gray-900 hover:text-blue-600"
                ></div>
              </template>
              <template v-else>
                <div
                  v-if="isFieldEnabled(Fields.name)"
                  class="min-h-fit w-full cursor-pointer truncate text-center text-base font-normal text-gray-900 hover:text-blue-600"
                  data-test="name"
                  @click="emit('toggle-profile')"
                >
                  {{ name }}
                </div>

                <div
                  class="min-h-fit w-full text-center"
                  :class="{
                    'text-sm font-light text-gray-500': isFieldEnabled(Fields.name),
                    'text-base font-normal text-gray-700': !isFieldEnabled(Fields.name)
                  }"
                  data-test="role"
                >
                  {{ role }}
                </div>
              </template>

              <div
                v-if="!isNoManager && !isRemovedManager"
                class="-ml-1 mt-1.5 flex w-full flex-wrap items-center justify-center transition-all"
              >
                <transition
                  enter-active-class="transform transition-all"
                  enter-from-class="opacity-0 scale-50"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition-all transform"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-50"
                >
                  <div
                    v-if="department && isFieldEnabled(Fields.department)"
                    class="ml-1 mt-1 max-h-6 max-w-fit items-center truncate rounded-full px-2.5 py-1 text-xs font-normal transition-colors duration-300"
                    :style="pillColorStyle"
                    :class="{
                      'ring-2 ring-inset ring-gray-500': highlightMode === 'department'
                    }"
                    data-test="department"
                  >
                    {{ department }}
                  </div>
                </transition>

                <transition
                  enter-active-class="transform transition-all"
                  enter-from-class="opacity-0 scale-50"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition-all transform"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-50"
                >
                  <div
                    v-if="officeLocation && isFieldEnabled(Fields.location)"
                    class="ml-1 mt-1 flex max-h-6 flex-row items-center gap-1 truncate rounded-full px-2.5 py-1 text-xs font-normal transition-colors duration-300"
                    :style="pillColorStyle"
                    :class="{
                      'ring-2 ring-inset ring-gray-500': highlightMode === 'location'
                    }"
                    data-test="location"
                  >
                    <LocationMarkerIcon
                      class="w-3"
                      :style="`stroke: ${adjustColorForContrast(pillColor, 0.9)}`"
                    />
                    {{ officeLocation }}
                  </div>
                </transition>

                <transition
                  enter-active-class="transform transition-all"
                  enter-from-class="opacity-0 scale-50"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition-all transform"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-50"
                >
                  <div
                    v-if="isApprovedRole && startDate"
                    class="ml-1 mt-1 flex max-h-6 flex-row items-center gap-1 truncate rounded-full px-2.5 py-1 text-xs font-normal transition-colors duration-300"
                    :style="pillColorStyle"
                    data-test="start-date"
                  >
                    Start:
                    {{ startDate }}
                  </div>
                </transition>

                <transition
                  enter-active-class="transform transition-all"
                  enter-from-class="opacity-0 scale-50"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition-all transform"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-50"
                >
                  <div
                    v-if="isFieldEnabled(Fields.email) && person?.email"
                    class="ml-1 mt-1 max-h-6 max-w-fit items-center truncate rounded-full px-2.5 py-1 text-xs font-normal mix-blend-multiply transition-colors duration-300"
                    :style="pillColorStyle"
                    data-test="email"
                  >
                    {{ person.email }}
                  </div>
                </transition>

                <transition
                  enter-active-class="transform transition-all"
                  enter-from-class="opacity-0 scale-50"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition-all transform"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-50"
                >
                  <div
                    v-if="isFieldEnabled(Fields.salary) && localiseCurrency(salaryNumber, currency)"
                    class="ml-1 mt-1 max-h-6 max-w-fit items-center truncate rounded-full px-2.5 py-1 text-xs font-normal mix-blend-multiply transition-colors duration-300"
                    :style="pillColorStyle"
                    data-test="salary"
                  >
                    {{ salaryNumber === 0 ? 'N/A' : localiseCurrency(salaryNumber, currency) }}
                  </div>
                </transition>

                <transition
                  enter-active-class="transform transition-all"
                  enter-from-class="opacity-0 scale-50"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition-all transform"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-50"
                >
                  <div
                    v-if="isFieldEnabled(Fields.salaryNewRole) && person?.cost"
                    class="ml-1 mt-1 flex max-h-6 flex-row items-center gap-1 truncate rounded-full px-2.5 py-1 text-xs font-normal transition-colors duration-300"
                    :style="pillColorStyle"
                    data-test="salary-new-role"
                  >
                    {{ localiseCurrency(person.cost, currency) }}
                  </div>
                </transition>

                <template v-for="field in publicCustomFields" :key="field">
                  <div
                    class="ml-1 mt-1 flex max-h-6 flex-row items-center gap-1 truncate rounded-full px-2.5 py-1 text-xs font-normal transition-colors duration-300"
                    :style="pillColorStyle"
                  >
                    {{ `${field.name}: ${field.data}` }}
                  </div>
                </template>

                <template v-for="field in selectedFields" :key="field">
                  <div
                    v-if="sensitiveDataDict[field]"
                    class="ml-1 mt-1 flex flex-row items-center truncate rounded-full px-2.5 py-1 text-xs font-normal transition-colors duration-300"
                    :style="pillColorStyle"
                    :class="{
                      'ring-2 ring-inset ring-gray-500': highlightMode === field
                    }"
                  >
                    {{ sensitiveDataDict[field] }}
                  </div>
                </template>

                <transition
                  enter-active-class="transform transition-all"
                  enter-from-class="opacity-0 scale-50"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition-all transform"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-50"
                >
                  <input
                    v-if="isNewHire && isFieldEnabled(Fields.plannedHireDate)"
                    id="plannedHireDateInput"
                    :value="startDate"
                    class="ml-2 mt-2 w-fit rounded-full border-0 py-1 pl-2 font-inter text-xs transition-all"
                    :style="pillColorStyle"
                    placeholder="Hire Date (YYYY-MM)"
                    @change="handleHireDateChange"
                    @keydown.enter="handleHireDateChange"
                    @click.stop
                    @keypress="blockInvalidDateInputInString"
                  />
                </transition>

                <div
                  v-if="scenarioState.length > 0 && isFieldEnabled(Fields.planStatus)"
                  class="mt-1 flex w-full flex-wrap items-center justify-center gap-2 space-x-2"
                >
                  <span
                    v-for="status in scenarioState.filter(
                      (s) => ![PersonPlanStates.RIF, PersonPlanStates.NewReport].includes(s)
                    )"
                    :key="status"
                    :class="[
                      'inline-flex items-center rounded-full border px-2.5 py-0.5 font-inter text-xs font-medium',
                      status === PersonPlanStates.NewlyAdded &&
                        'border-purple-300 bg-purple-100 text-purple-700',
                      status === PersonPlanStates.Edited &&
                        'border-primary-300 bg-primary-100 text-primary-700',
                      status === PersonPlanStates.Moved &&
                        'border-success-500 bg-success-50 text-success-700',
                      status === PersonPlanStates.Backfilled && 'bg-blue-50 text-blue-700'
                    ]"
                  >
                    <SmallUserPlusIcon
                      v-if="status === PersonPlanStates.NewlyAdded"
                      class="mr-1 h-3 w-3 stroke-purple-700"
                    />
                    <PencilUnderlineIcon
                      v-if="status === PersonPlanStates.Edited"
                      class="mr-1 h-3 w-3 stroke-primary-700"
                    />
                    <MoveIcon
                      v-if="status === PersonPlanStates.Moved"
                      class="mr-1 h-3 w-3 stroke-success-700"
                    />
                    {{ status }}
                    {{ status === PersonPlanStates.Backfilled ? `by: ${backfilledByWho}` : '' }}
                  </span>
                </div>
              </div>

              <button
                v-if="!isRemovedManager && spanOfControl > 0"
                class="mt-2 flex items-center justify-center rounded-2xl bg-gray-800 px-2 py-1 text-xs text-white transition-all hover:bg-gray-600"
                data-test="toggle-collapse-button"
                @click.stop="emit('toggle-collapse')"
                @mousedown.stop
              >
                {{ activeSubordinatesCount }} / {{ spanOfControl }}
                <ChevronDownIcon
                  class="w-4 transition-all"
                  :class="{
                    'rotate-180': isExpanded === 'true'
                  }"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- overlay buttons -->
      <div
        v-if="!isRemovedManager && !isNoManager && showHover"
        class="absolute top-0 z-30 flex h-6 w-full translate-y-0 scale-0 justify-center opacity-0 transition-all group-hover:-translate-y-full group-hover:scale-100 group-hover:opacity-100"
        @click.stop
        @mousedown.stop
      >
        <div
          class="overlay-buttons absolute top-0 -translate-y-5 rounded-lg bg-white drop-shadow-lg transition-opacity"
          :class="{
            'opacity-0': dragging === 'true'
          }"
        >
          <Popper content="Edit" placement="top" hover class="top-10">
            <button
              type="button"
              class="items-center rounded-l-lg px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              data-test="edit-user-button"
              @click="emit('edit-user')"
            >
              <EditIcon class="h-5 w-5" />
            </button>
          </Popper>
          <Popper
            v-if="!isBackfilled"
            :content="isRif ? 'Remove Reduction' : 'Mark as Reduction'"
            placement="top"
            hover
            class="top-10"
          >
            <button
              type="button"
              class="items-center px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              :data-test="isRif ? 'remove-rif-button' : 'mark-as-rif-button'"
              @click="emit('mark-as-rif')"
            >
              <UserPlus v-if="isRif" class="h-5 w-5" />
              <UserMinusIcon v-else class="h-5 w-5" />
            </button>
          </Popper>
          <Popper content="Delete" placement="top" hover class="top-10">
            <button
              type="button"
              class="items-center rounded-r-lg px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              data-test="delete-user-button"
              @click="emit('delete-user')"
            >
              <TrashIcon class="h-5 w-5" />
            </button>
          </Popper>
          <Popper
            :content="`Duplicate (${getShortcutWithCmdOrCtrl('d')})`"
            placement="top"
            hover
            class="top-10"
          >
            <button
              type="button"
              class="items-center rounded-r-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              data-test="duplicate-user-button"
              @click="handleDuplicate"
            >
              <CopyIcon class="icon h-5 w-5" />
            </button>
          </Popper>
        </div>
      </div>

      <div
        v-if="!isRemovedManager && !isNoManager && showHover"
        class="absolute bottom-0 z-30 flex h-6 w-full translate-y-0 scale-0 justify-center opacity-0 transition-all group-hover:translate-y-full group-hover:scale-100 group-hover:opacity-100"
        @click.stop
        @mousedown.stop
      >
        <div
          class="overlay-buttons absolute top-5 -translate-y-2 rounded-lg bg-white drop-shadow-lg transition-opacity"
          :class="{
            'opacity-0': dragging === 'true'
          }"
        >
          <Popper content="Add direct report" placement="top" hover>
            <button
              class="items-center rounded-l-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              :class="{
                'opacity-0': dragging === 'true'
              }"
              data-test="add-direct-report-button"
              @click="emit('add-user')"
              @click.stop
              @mousedown.stop
            >
              <UserPlus class="h-auto w-5" />
            </button>
          </Popper>
          <Popper content="Create dotted line" placement="top" hover>
            <button
              class="items-center bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              :class="{
                'opacity-0': dragging === 'true'
              }"
              data-test="add-dotted-line-button"
              @click="emit('add-dotted-line')"
              @click.stop
              @mousedown.stop
            >
              <DottedLineIcon class="h-auto w-5" />
            </button>
          </Popper>
          <Popper
            :content="isBackfilled ? 'Remove backfill' : 'Add backfill'"
            placement="top"
            hover
          >
            <button
              class="items-center rounded-r-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              :class="{
                'opacity-0': dragging === 'true'
              }"
              :data-test="isBackfilled ? 'remove-backfill-button' : 'add-backfill-button'"
              @click="emit('add-remove-backfill')"
              @click.stop
              @mousedown.stop
            >
              <ReplaceIcon class="h-auto w-5" />
            </button>
          </Popper>
        </div>
      </div>
      <div
        v-if="showHover && !isCEO && !isNoManager"
        class="absolute -left-14 top-1/3 z-30 h-6 translate-y-0 scale-0 opacity-0 transition-all group-hover:translate-y-full group-hover:scale-100 group-hover:opacity-100"
        @click.stop
        @mousedown.stop
      >
        <div
          class="overlay-buttons absolute rounded-lg bg-white drop-shadow-lg transition-opacity"
          :class="{
            'opacity-0': dragging === 'true'
          }"
        >
          <Popper content="Move to the left" placement="top" hover>
            <button
              type="button"
              class="flex items-center justify-center rounded-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              data-test="move-node-left-button"
              @click="emit('move-node-left')"
            >
              <ArrowLeft class="h-4 w-4" />
            </button>
          </Popper>
        </div>
      </div>

      <div
        v-if="showHover && !isCEO && !isNoManager"
        class="absolute -right-2 top-1/3 z-30 h-6 translate-y-0 scale-0 opacity-0 transition-all group-hover:translate-y-full group-hover:scale-100 group-hover:opacity-100"
        @click.stop
        @mousedown.stop
      >
        <div
          class="overlay-buttons absolute rounded-lg bg-white drop-shadow-lg transition-opacity"
          :class="{
            'opacity-0': dragging === 'true'
          }"
        >
          <Popper content="Move to the right" placement="top" hover>
            <button
              type="button"
              class="flex items-center justify-center rounded-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              data-test="move-node-right-button"
              @click="emit('move-node-right')"
            >
              <ArrowRight class="h-4 w-4" />
            </button>
          </Popper>
        </div>
      </div>
      <!-- end buttons -->
    </div>
    <template v-if="isRemovedManager">
      <div
        class="absolute flex h-full w-full flex-col items-center justify-center space-y-4 rounded-xl bg-error-25 bg-opacity-70 ring-1 ring-error-500 transition-all duration-300 hover:ring-2 hover:ring-error-700"
      >
        <span
          class="rounded-full bg-error-100 px-2 py-1 font-medium text-error-700 bg-blend-multiply"
        >
          Missing manager
        </span>
        <div class="flex gap-2">
          <BaseButton
            class="h-9 w-11"
            color="white"
            data-test="undo-delete-manager-button"
            @click.stop="emit('undo-delete-manager')"
          >
            <Popper content="Undo delete" placement="top" hover class="top-10">
              <RefreshIcon class="h-auto w-5 stroke-bluedark-700" />
            </Popper>
          </BaseButton>
          <BaseButton
            class="h-9 w-11"
            color="white"
            data-test="replace-user-button"
            @click.stop="emit('replace-user')"
          >
            <Popper content="Add a new manager" placement="top" hover class="top-10">
              <PlusCircleIcon class="h-auto w-5" />
            </Popper>
          </BaseButton>
        </div>
        <button
          v-if="spanOfControl > 0"
          class="flex items-center justify-center rounded-2xl bg-gray-800 px-2 py-1 text-xs text-white transition-all hover:bg-gray-600"
          @click.stop="emit('toggle-collapse')"
          @mousedown.stop
        >
          {{ activeSubordinatesCount }} / {{ spanOfControl }}
          <ChevronDownIcon
            class="w-4 transition-all"
            :class="{
              'rotate-180': isExpanded === 'true'
            }"
          />
        </button>
      </div>
    </template>

    <div
      class="absolute bottom-0 z-40 flex h-fit w-full justify-center transition-all"
      :class="{
        'translate-y-0 scale-0 opacity-0': !dragging || dragging === 'false',
        'translate-y-full scale-100 opacity-100': dragging === 'true'
      }"
      @click.stop
      @mousedown.stop
    >
      <div
        class="flex h-auto translate-y-2 rounded-lg bg-white px-3 py-2 font-medium text-gray-700 shadow-lg transition-all"
      >
        <p v-if="dragTarget && dragging === 'true'" class="text-xs">
          Drop here to set
          <span class="font-bold">{{ potentialManager }}</span>
          as manager
        </p>
        <p v-else>Drag over a card</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import CopyIcon from '@/assets/Feather/Copy.svg'
import EditIcon from '@/assets/Feather/Edit.svg'
import PlusCircleIcon from '@/assets/Feather/plus-circle.svg'
import UserMinusIcon from '@/assets/Feather/user-minus.svg'
import UserPlus from '@/assets/Feather/UserPlus01.svg'
import ArrowLeft from '@/assets/SvgIcons/ArrowLeft.svg'
import ArrowRight from '@/assets/SvgIcons/ArrowRight.svg'
import MoveIcon from '@/assets/SvgIcons/MoveIcon.svg'
import PencilUnderlineIcon from '@/assets/SvgIcons/PencilUnderlineIcon.svg'
import RefreshIcon from '@/assets/SvgIcons/Refresh.svg'
import ReplaceIcon from '@/assets/SvgIcons/ReplaceIcon.svg'
import SmallUserPlusIcon from '@/assets/SvgIcons/SmallUserPlusIcon.svg'
import TrashIcon from '@/assets/SvgIcons/TrashIcon.svg'
import SelectionIcon from '@/assets/untitled-ui/check-circle.svg'
import DottedLineIcon from '@/assets/untitled-ui/dots-diagonal.svg'
import BaseButton from '@/components/BaseButton.vue'
import useChartMode, { modes } from '@/hooks/use-chart-mode.js'
import useOrgChartColors from '@/hooks/use-org-chart-colors.js'
import useOrgNodeHelpers from '@/hooks/use-org-node-helpers.js'
import useScenarioFields from '@/hooks/use-scenario-fields.js'
import { getShortcutWithCmdOrCtrl } from '@/lib/KeyboardShortcuts'
import { colorForVariant } from '@/lib/OrgNodeData'
import {
  isBackfilled as isBackfilledImpl,
  isEdited as isEditedImpl,
  isLeaving,
  isRemovedManager as isRemovedManagerImpl,
  isRif as isRifImpl
} from '@/lib/PersonDataProcessing.js'
import PersonPlanStates from '@/lib/PersonPlanStates'
import { Fields } from '@/lib/PlanFields'
import { localiseCurrency } from '@/services/currency.service.js'
import { blockInvalidDateInputInString } from '@/services/date.service'
import store from '@/store/index.js'
import { LocationMarkerIcon } from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { useElementHover, useElementSize, useMagicKeys, useResizeObserver } from '@vueuse/core'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import moment from 'moment'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import Popper from 'vue3-popper'
import chroma from 'chroma-js'
import { useSimpleTheme } from '@/hooks/use-simple-theme'

const props = defineProps({
  boardId: {
    type: String,
    required: true
  },
  personId: {
    type: String,
    required: true
  },
  opacity: {
    type: Number,
    default: 1
  },
  isExpanded: {
    type: [Boolean, String],
    default: false
  },
  enableMenuButton: {
    type: Boolean,
    default: false
  },
  activeSubordinatesCount: {
    type: Number,
    default: 0
  },
  spanOfControl: {
    type: Number,
    default: 0
  },
  enableHover: {
    type: String,
    default: 'true'
  },
  dragging: {
    type: String,
    default: 'false'
  },
  dragTarget: {
    type: String,
    default: ''
  },
  draggingOver: {
    type: String,
    default: ''
  },
  departments: {
    type: String,
    default: ''
  }
})

const emit = defineEmits([
  'toggle-menu',
  'toggle-profile',
  'toggle-collapse',
  'add-user',
  'add-remove-backfill',
  'add-user-from-template',
  'edit-user',
  'delete-user',
  'paste-user',
  'mark-as-rif',
  'replace-user',
  'undo-delete-manager',
  'duplicate-user',
  'hire-date-change',
  'add-dotted-line',
  'move-node-left',
  'move-node-right'
])

const widthSource = ref(null)
const heightSource = ref(null)
const pulseAnimation = ref(false)
const highlightAnimation = ref(false)

const { isSelected: isFieldEnabled, selectedFields } = useScenarioFields(props.boardId)
const { updateWidth, updateHeight, setHovered, resetHovered, hideImages, highlightedNode } =
  useOrgNodeHelpers()
const isHovered = useElementHover(widthSource)
const { backgroundColorVariant, adjustColorForContrast, highlightMode } = useOrgChartColors()
const { mode } = useChartMode()
const { theme } = useSimpleTheme(props.departments.split(','))

/**
 * Handles the duplication action by emitting a 'duplicate-user' event.
 *
 * @returns {void} - No return value.
 */
const handleDuplicate = () => {
  emit('duplicate-user')
}

let pulseAnimationSwitch = null
/**
 * Triggers a pulse animation. It resets and restarts the animation cycle by toggling `pulseAnimation.value`.
 * If `pulseAnimationSwitch` is active, it clears the existing timeout to reset the animation.
 * Then, it sets `pulseAnimation.value` to true in the next tick, and after 1000 milliseconds,
 * it resets `pulseAnimation.value` to false.
 *
 * @returns {void} - No return value.
 */
const triggerPulseAnimation = () => {
  if (pulseAnimationSwitch) {
    clearTimeout(pulseAnimationSwitch)
    pulseAnimation.value = false
  }

  nextTick(() => {
    pulseAnimation.value = true
    pulseAnimationSwitch = setTimeout(() => {
      pulseAnimation.value = false
    }, 1000)
  })
}

/**
 * Handles the paste action. If `isHovered.value` is true, it triggers a pulse animation
 * and emits a 'paste-user' event. If `isHovered.value` is false, the function returns early without action.
 *
 * @returns {void} - No return value.
 */
const handlePaste = () => {
  if (!isHovered.value) return
  emit('paste-user')
  triggerPulseAnimation()
}

useMagicKeys({
  passive: false,
  onEventFired(e) {
    if ((e.metaKey || e.ctrlKey) && e.key === 'v' && e.type === 'keydown' && isHovered.value) {
      e.preventDefault()
      handlePaste()
    }

    if ((e.metaKey || e.ctrlKey) && e.key === 'd' && e.type === 'keydown' && isHovered.value) {
      window.mixpanel.track('node_keyboard_duplicate')
      e.preventDefault()
      handleDuplicate()
    }
  }
})

const bgVariant = computed(() => backgroundColorVariant(props.personId))

onMounted(() => {
  const { width } = useElementSize(widthSource)

  useResizeObserver(heightSource, (entries) => {
    const entry = entries[0]
    updateHeight({ id: props.personId, height: entry?.target?.clientHeight })
  })

  watch(width, () => {
    updateWidth({ id: props.personId, width: width.value })
  })

  watch(isHovered, () => {
    if (isHovered.value) setHovered(props.personId)
    else resetHovered(props.personId)
  })
})

const showHover = computed(() => props.enableHover === 'true')
/**
 * Returns a computed property that retrieves a person's information by their ID for a specific board.
 *
 * @param {string} personId - The ID of the person to look up.
 * @returns {computed} A Vue computed property containing the person's information.
 */
const personById = (personId) =>
  computed(() =>
    store.getters['people/personByIdWithApprovedRoles']({ personId, boardId: props.boardId })
  )
const person = computed(() => personById(props.personId).value)
const isPersonLeaving = computed(() => isLeaving(person.value))

const sensitiveDataDict = computed(() => {
  const dict = {}
  if (!person.value) return dict
  const sensitiveDataObj = store.getters.sensitiveDataForPerson({
    boardId: person.value.boardId,
    personId: person.value.personId
  })
  sensitiveDataObj.data?.forEach((item) => {
    dict[item.field] = item.value
  })
  return dict
})

const isNewHire = computed(
  () =>
    person.value?.startDate &&
    DateTime.fromFormat(person.value?.startDate, 'yyyy-MM-dd').diffNow('days').days > 0
)

const name = computed(() => {
  return person.value?.name ?? ''
})

const avatarUrl = computed(() => {
  return person.value?.avatarImg ?? ''
})

const role = computed(() => {
  return person.value?.role ?? ''
})

const department = computed(() => {
  return person.value?.department ?? ''
})

const publicCustomFields = computed(() => {
  return person.value?.customFields ?? {}
})

const officeLocation = computed(() => {
  return person.value?.officeLocation ?? ''
})

const isNoManager = computed(() => {
  return person.value?.isNoManagerNode ?? false
})

const isCEO = computed(() => {
  const roots = store.getters['people/getRoots'](props.boardId)
  return roots[props.personId] ? true : false
})
const isRif = computed(() => isRifImpl(person.value))
const currency = computed(() => store.getters.preferredBaseCurrency)

const isBackfilled = computed(() => isBackfilledImpl(person.value))
const backfilledByWho = computed(
  () =>
    store.getters['people/personById']({
      boardId: props.boardId,
      personId: person.value?.scenarioMetaData?.byWho
    })?.name
)
const isRemovedManager = computed(() => isRemovedManagerImpl(person.value))
const isEdited = computed(() => isEditedImpl(person.value))
const isApprovedRole = computed(() =>
  store.getters['people/isApprovedRole']({ boardId: props.boardId, personId: props.personId })
)

// "showHover" is false in Org Chart view. Treat it as temporary fix until we move all charts to be "plans" and we'll
// need more robust context for Org Node context
const scenarioState = computed(
  () => (showHover.value && person.value?.scenarioMetaData?.state) || []
)

const potentialManager = computed(() => {
  const personData = personById(props.dragTarget).value

  const showRole = personData.name === 'Planned role' || !isFieldEnabled(Fields.name)
  const nameOrRole = showRole ? personData.role : personData.name

  return personData?.scenarioMetaData?.state?.includes(PersonPlanStates.RemovedManager)
    ? 'Missing manager'
    : nameOrRole
})

const startDate = computed(() =>
  !isEmpty(person.value?.startDate) ? moment(person.value?.startDate).format('YYYY-MM') : ''
)

const isDragging = computed(() => {
  return props.dragging === 'true'
})

const pillColor = computed(() => {
  const color = department.value ? theme.value?.departments?.[department.value] : '#FFFFFF'

  return chroma(color)
    .luminance(isHovered.value ? 0.4 : 0.6)
    .hex()
})

const backgroundColor = computed(() =>
  chroma(pillColor.value).luminance(isHovered.value ? 0.8 : 0.9)
)

const pillColorStyle = computed(
  () =>
    `background-color: ${pillColor.value}; color: ${adjustColorForContrast(pillColor.value, 0.9)}`
)

const cardWrapperClass = computed(() => {
  const result = []

  if (isRif.value) {
    result.push(
      'leaver bg-orangedark-25 ring-orangedark-300 hover:bg-orangedark-100 hover:ring-orangedark-400'
    )
  }

  if (isNoManager.value) {
    result.push('bg-gray-25 ring-gray-100 hover:bg-gray-50 hover:ring-gray-700')
  } else if (isRemovedManager.value) {
    result.push('bg-error-25 ring-error-500 hover:bg-error-50 hover:ring-error-700')
  } else if (backgroundColor.value) {
    result.push('customBgColor')
  } else if (bgVariant.value == null) {
    result.push('bg-white')
  } else {
    result.push(colorForVariant(bgVariant.value))
  }

  if (mode.value === modes.changes) {
    if (!isNewHire.value && !isPersonLeaving.value && !isRif.value && isEdited.value) {
      result.push('edited bg-yellow-25 ring-yellow-300 hover:bg-yellow-100 hover:ring-yellow-400')
    }
  }
  if (mode.value === modes.changes) {
    if (isNewHire.value) {
      result.push('new-hire bg-green-25 ring-green-300 hover:bg-green-100 hover:ring-green-400')
    } else if (isPersonLeaving.value) {
      result.push(
        'leaver bg-orangedark-25 ring-orangedark-300 hover:bg-orangedark-100 hover:ring-orangedark-400'
      )
    }
  }

  return result
})

let highlightAnimationSwitch = null
/**
 * Manages the highlight animation state. It resets and restarts the animation cycle,
 * toggling `highlightAnimation.value` between true and false with a delay.
 * Clears any existing animation cycle before starting a new one.
 *
 * @returns {void}
 */
const triggerHighlightAnimation = () => {
  if (highlightAnimationSwitch) {
    clearTimeout(highlightAnimationSwitch)
    highlightAnimation.value = false
  }

  nextTick(() => {
    highlightAnimation.value = true
    highlightAnimationSwitch = setTimeout(() => {
      highlightAnimation.value = false
    }, 1000)
  })
}

/**
 * Handle hire date change
 * @param {Event} event
 */
const handleHireDateChange = (event) => {
  emit('hire-date-change', event.target.value)
}

watch(highlightedNode, () => {
  if (highlightedNode.value === props.personId) {
    triggerHighlightAnimation()
  }
})
</script>

<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --popper-theme-background-color: rgb(16, 24, 40);
  --popper-theme-background-color-hover: rgb(16, 24, 40);
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 10px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.popper {
  font-size: 12px;
  white-space: nowrap;
  font-family: Inter, sans-serif;
}

.org-node {
  font-family: 'Inter';
}

.customBgColor {
  background: v-bind(backgroundColor);
}

.dragging-over {
  @apply scale-110 shadow-lg shadow-bluedark-100;
}

.white-icon :deep(path) {
  stroke: white;
}

.overlay-buttons svg :deep(path) {
  @apply stroke-gray-900;
}

:deep(.popper) {
  @apply pointer-events-none cursor-pointer whitespace-nowrap rounded-lg bg-black px-3 py-2 text-xs text-white shadow-lg !important;
}

.pulseAnimation {
  @apply animate-[pulse_0.3s_ease-in-out];
}

.highlightAnimation {
  @apply ring-4 ring-success-400;
}
</style>
