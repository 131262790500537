<template>
  <TransitionRoot appear :show="show" as="template">
    <Dialog as="div" class="relative z-10" @close="$emit('close')">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                Create new board
              </DialogTitle>
              <div class="mt-2">
                <p class="text-sm text-gray-500">This is visible to Agentnoon Admins Only.</p>
                <div
                  class="mt-4 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-blue-600"
                >
                  <label for="name" class="block text-xs font-medium text-gray-900">
                    Company name
                  </label>
                  <input
                    id="name"
                    v-model="companyName"
                    type="text"
                    name="name"
                    class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Acme Corp."
                  />
                </div>
              </div>

              <div class="mt-4 flex justify-end">
                <button
                  type="button"
                  class="bg-white-100 text-black-900 focus-visible:black-500 hover:bg-black-300 inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                  @click="$emit('close')"
                >
                  Cancel
                </button>

                <button
                  type="button"
                  class="inline-flex justify-center rounded-md border border-transparent bg-blue-600 text-white px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  @click="_createBoard"
                >
                  Create
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from 'vue'

import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { createBoard } from '@/services/boards-api.service'

import { useRouter } from 'vue-router'
import { createBoardId } from '@/lib/idCreator'

const { push } = useRouter()

const companyName = ref('')

defineProps({
  show: {
    type: Boolean,
    default: false
  }
})

defineEmits(['close'])

const _createBoard = async () => {
  console.log('create board')

  const boardName = companyName.value
  const _companyName = companyName.value
  const newBoardId = createBoardId()

  const payload = {
    boardId: newBoardId,
    boardName,
    companyName: _companyName
  }
  const ret = await createBoard({ payload })

  await push({
    name: 'Landing',
    params: { boardId: ret.boardId }
  })
  window.location.reload()
}
</script>
