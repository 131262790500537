/* eslint-disable no-unused-vars */
import EmployeeStatus from '@/lib/EmployeeStatus.js'
import {
  addSubordinate,
  applyEditedState,
  applyPersonMoved,
  ensureMetaProperties,
  getNewCompensation,
  getNewPerson,
  hasCEOAttributes,
  isCEO,
  isEdited,
  isMoved,
  isNewlyAdded,
  isRemoved,
  isRemovedManager,
  isRif,
  markAsRemoved,
  newRoleChangeRecord,
  setEdited,
  setNewReport,
  setScenarioStatus,
  validatePeopleData
} from '@/lib/PersonDataProcessing'
import { formatDateToStandard } from '@/services/date.service.js'
import { errorBus, personUpdateBus } from '@/services/eventbus.service.js'
import { getUsers } from '@/services/firebase-functions.service.js'
import { getOrgApprovedRoles } from '@/services/meta-api.service.js'
import {
  addPeople,
  addPerson,
  bulkStatusUpdate,
  bulkUpdate,
  deletePeople,
  getPeople,
  // getPerson,
  getPersonByEmail,
  updateManager,
  updatePeople,
  updatePerson
} from '@/services/people-api.service.js'
import {
  departments,
  groupByDepartment,
  groupByLocation,
  locations,
  roles
} from '@/services/people-processing.js'
import * as Sentry from '@sentry/browser'
import {
  cloneDeep,
  each,
  filter,
  find,
  flattenDeep,
  groupBy,
  isEqual,
  keyBy,
  map,
  matches,
  merge,
  remove,
  union,
  uniq,
  xor
} from 'lodash-es'

//each key is an object indexed by boardId
const getDefaultState = () => {
  return {
    people: {},
    peopleById: {},
    ownerInfos: {},
    isFetchingPeople: {},
    approvedRoles: {}
  }
}

const M = {
  SET_OWNER_INFOS: 'setOwnerInfos',
  SET_PERSON: 'setPerson',
  SET_PEOPLE_DATA: 'setPeopleData',
  REMOVE_PERSON: 'removePerson',
  UPDATE_PERSON: 'updatePerson',
  UPDATE_PEOPLE: 'updatePeople',
  UPDATE_PERSON_RAW: 'updatePersonRaw',
  SET_IS_FETCHING_PEOPLE: 'setIsFetchingPeople',
  RESET: 'reset',
  SET_APPROVED_ROLES: 'setApprovedRoles'
}

const state = getDefaultState()

const mutations = {
  [M.SET_OWNER_INFOS](_state, { boardId, ownerInfos }) {
    _state.ownerInfos[boardId] = ownerInfos
  },
  [M.SET_PERSON](_state, { boardId, person }) {
    remove(_state.people[boardId], (p) => p.personId === person.personId)

    if (!_state.people[boardId]) _state.people[boardId] = []
    if (!_state.peopleById[boardId]) _state.peopleById[boardId] = {}

    _state.people[boardId].push(person)
    _state.peopleById[boardId][person.personId] = person
  },
  [M.SET_PEOPLE_DATA](_state, { boardId, people }) {
    const peopleToSet = validatePeopleData(people)
    _state.people[boardId] = peopleToSet
    _state.peopleById[boardId] = keyBy(peopleToSet, 'personId')
  },
  [M.REMOVE_PERSON](_state, { boardId, personIds }) {
    // Go through everyone and make sure this person is removed from managers and subordinates list.
    each(_state.people[boardId], (person) => {
      remove(person.managers, (managerId) => personIds.includes(managerId))
      remove(person?.subordinates || [], (subordinateId) => personIds.includes(subordinateId))
    })

    _state.people[boardId] = validatePeopleData(
      _state.people[boardId].filter((person) => !personIds.includes(person.personId))
    )
  },
  [M.UPDATE_PERSON](_state, { boardId, person }) {
    const newPeopleData = [..._state.people[boardId]]

    const personIndex = newPeopleData.findIndex((p) => p.personId === person.personId)
    newPeopleData[personIndex] = person

    const peopleToSet = validatePeopleData(newPeopleData)
    _state.people[boardId] = peopleToSet
    _state.peopleById[boardId] = keyBy(peopleToSet, 'personId')
  },
  [M.UPDATE_PEOPLE](_state, { boardId, people }) {
    const newPeopleData = [..._state.people[boardId]]

    const personIdToIndex = {}
    _state.people[boardId].forEach((person, index) => {
      personIdToIndex[person.personId] = index
    })

    people.forEach((person) => {
      if (!person) return

      const personIndex = personIdToIndex[person.personId]
      if (!personIndex || personIndex > newPeopleData.length - 1) return

      newPeopleData[personIndex] = person
    })

    const peopleToSet = validatePeopleData(newPeopleData)
    _state.people[boardId] = peopleToSet
    _state.peopleById[boardId] = keyBy(peopleToSet, 'personId')
  },
  [M.UPDATE_PERSON_RAW](_state, { boardId, person }) {
    const newPeopleData = [..._state.people[boardId]]

    const personIndex = newPeopleData.findIndex((p) => p.personId === person.personId)
    newPeopleData[personIndex] = person

    _state.people[boardId] = newPeopleData
    _state.peopleById[boardId] = keyBy(newPeopleData, 'personId')
  },
  [M.SET_IS_FETCHING_PEOPLE](_state, { boardId, status }) {
    _state.isFetchingPeople[boardId] = status
  },
  [M.RESET](_state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(_state, getDefaultState())
  },
  [M.SET_APPROVED_ROLES](_state, { boardId, approvedRoles }) {
    _state.approvedRoles[boardId] = {
      roles: approvedRoles,
      rolesByPersonId: keyBy(approvedRoles, 'personId')
    }
  }
}

const getters = {
  people: (_state) => (boardId) => _state.people[boardId] || [],
  allPeople: (_state) => (boardId) => _state.allPeople[boardId] || [],

  activeEmployees: (_state) => (boardId) => {
    return (
      _state.people[boardId]?.filter((person) => !isRemovedManager(person) && !isRemoved(person)) ||
      []
    )
  },

  departmentList: (_, _getters) => (boardId) => departments(_getters.activeEmployees(boardId)),

  locationList: (_, _getters) => (boardId) => locations(_getters.activeEmployees(boardId)),

  roleList: (_, _getters) => (boardId) => roles(_getters.activeEmployees(boardId)),

  groupPeopleByDepartment: (_, _getters) => (boardId) =>
    groupByDepartment(_getters.people(boardId)),

  groupPeopleByLocation: (_, _getters) => (boardId) => groupByLocation(_getters.people(boardId)),

  groupPeopleByManager: (_state, _getters) => (boardId) =>
    groupBy(_getters.activeEmployees(boardId), (emp) =>
      emp.managers && emp.managers[0] ? emp.managers[0] : 'noManager'
    ),

  isFetchingPeople: (_state) => (boardId) => _state.isFetchingPeople[boardId] ?? false,

  reportsTo:
    (_state, _getters) =>
    ({ boardId, personId }) => {
      const peopleByManager = _getters.groupPeopleByManager(boardId) || {}
      return peopleByManager[personId] || []
    },

  getCEO: (_state) => (boardId) => _state.people[boardId]?.find(isCEO),

  getRoots: (_state) => (boardId) => keyBy(_state.people[boardId].filter(isCEO), 'personId'),

  getRootsWithCEOAttributes: (_state) => (boardId) =>
    keyBy(_state.people[boardId].filter(hasCEOAttributes), 'personId'),

  teamAndManagerInSubtree:
    (_state, _getters) =>
    ({ boardId, personId }) => {
      const peopleInSubtree = _getters.everyoneInSubtree({ boardId, personId }) || []
      const peopleObj = peopleInSubtree.map((pId) =>
        _getters.personById({ boardId, personId: pId })
      )

      return [...peopleObj, _getters.personById({ boardId, personId })]
    },

  teamByManager:
    (_state, _getters) =>
    ({ boardId, managerId }) => {
      const peopleByManager = _getters.groupPeopleByManager(boardId) || {}
      return peopleByManager[managerId] || []
    },

  personById:
    (_state) =>
    ({ boardId, personId }) =>
      _state.peopleById[boardId]?.[personId] || null,

  personByIdWithApprovedRoles:
    (_state) =>
    ({ boardId, personId }) =>
      _state.peopleById[boardId]?.[personId] ||
      _state.approvedRoles[boardId]?.rolesByPersonId[personId] ||
      null,

  peopleById: (_state) => (boardId) => _state.peopleById[boardId] || {},

  removed: (_state, _getters) => (boardId) => _getters.people(boardId).filter(isRemoved) || [],

  markedAsRIF: (_state, _getters) => (boardId) => _getters.people(boardId).filter(isRif) || [],

  changedInScenario: (_state, _getters) => (boardId) =>
    _getters.activeEmployees(boardId).filter((person) => isMoved(person) || isEdited(person)) || [],

  movedInScenario: (_state, _getters) => (boardId) =>
    _getters.activeEmployees(boardId).filter((person) => isMoved(person)) || [],

  editedInScenario: (_state, _getters) => (boardId) =>
    _getters
      .activeEmployees(boardId)
      .filter((person) => isEdited(person) && person.employeeStatus !== 'Terminated') || [],

  locations: (_, _getters) => (boardId) => {
    const res = new Set(
      _getters
        .activeEmployees(boardId)
        .flatMap((person) => [person.officeLocation ? person.officeLocation : null])
    )

    return [...res]
  },

  executives: (_, _getters) => (boardId) => {
    let roots = _getters.getRoots(boardId)
    if (!roots || roots.length === 0) {
      roots = _getters.getRootsWithCEOAttributes(boardId)
    }

    if (!roots || roots.length === 0) return []
    else {
      const execs = []
      Object.keys(roots).forEach((rootId) =>
        execs.push(..._getters.reportsTo({ boardId, personId: rootId }).map((p) => p.personId))
      )

      return execs
    }
  },

  managersInSubtree:
    (_, _getters) =>
    ({ boardId, personId }) => {
      const person = _getters.personById({ boardId, personId })
      const subordinates = person?.subordinates ? person?.subordinates : []
      const managers = []

      subordinates.forEach((subordinateId) => {
        const subordinatePerson = _getters.personById({ boardId, personId: subordinateId })
        if (subordinatePerson.subordinates.length > 0 && !isRif(subordinatePerson)) {
          managers.push(subordinatePerson.personId)
          managers.push(..._getters.managersInSubtree({ boardId, personId: subordinateId }))
        }
      })

      return managers
    },

  managers: (_, _getters) => (boardId) => {
    const peopleByManager = _getters.groupPeopleByManager(boardId)
    const managers = Object.keys(peopleByManager).filter((managerId) => managerId !== 'noManager')

    return managers.flatMap((managerId) => {
      const person = _getters.personById({ boardId, personId: managerId })
      if (person) return [person]
      return []
    })
  },

  individualContributorsInSubtree:
    (_, _getters) =>
    ({ boardId, personId }) => {
      const person = _getters.personById({ boardId, personId })
      const subordinates = person?.subordinates ? person?.subordinates : []
      const individualContributors = []

      subordinates.forEach((subordinateId) => {
        const subordinatePerson = _getters.personById({ boardId, personId: subordinateId })

        if (subordinatePerson.subordinates.length === 0) {
          individualContributors.push(subordinateId)
        } else {
          individualContributors.push(
            ..._getters.individualContributorsInSubtree({
              boardId,
              personId: subordinateId
            })
          )
        }
      })

      return individualContributors
    },

  everyoneInSubtree:
    (_, _getters) =>
    ({ boardId, personId }) => {
      const person = _getters.personById({ boardId, personId })
      const subordinates = person?.subordinates || []
      const everyone = []

      subordinates.forEach((subordinateId) => {
        const subordinate = _getters.personById({ boardId, personId: subordinateId })
        if (!isRif(subordinate)) {
          everyone.push(subordinateId)
        }
        everyone.push(..._getters.everyoneInSubtree({ boardId, personId: subordinateId }))
      })

      return everyone
    },

  ownerInfos: (_state) => (boardId) => _state.ownerInfos[boardId],

  /**
   * Returns an owner profile extended by person data (if available)
   */
  ownerProfile:
    (_state, _getters) =>
    ({ boardId, uid }) => {
      const owner = _getters.ownerInfos(boardId)?.find((owner) => owner.uid === uid)

      if (owner) {
        const ownerPerson = _getters
          .people(boardId)
          .find((person) => person.email?.toLowerCase().trim() === owner.email)

        return {
          ...owner,
          avatarImg: ownerPerson?.avatarImg,
          name: ownerPerson?.name || owner.name || owner.displayName || owner.email,
          role: ownerPerson?.role || null
        }
      }

      return null
    },

  indirectConnections:
    (_, _getters) =>
    ({ boardId }) => {
      const people = _getters.people(boardId)

      const connections = []

      people?.forEach((person) => {
        const personConnections =
          person.indirectConnections?.map((connection) => ({
            from: person.personId,
            to: connection
          })) || []

        connections.push(...personConnections)
      })

      return connections
    },

  rolesDateRange: (_, _getters) => (boardId) => {
    const addedRoles = _getters.addedInScenario(boardId)
    const removedRoles = _getters.markedAsRIF(boardId)

    const result = [
      ...(addedRoles.map((role) => formatDateToStandard(role.startDate)) || []),
      ...(removedRoles.map((role) => formatDateToStandard(role.terminationDate)) || [])
    ]

    const dates = result.filter((date) => date)

    dates.sort((a, b) => {
      return new Date(a) - new Date(b)
    })

    return [dates[0] || null, dates[dates.length - 1] || null]
  },

  approvedRoles: (_state) => (boardId) => _state.approvedRoles[boardId]?.roles || [],

  isApprovedRole:
    (_state, _getters) =>
    ({ boardId, personId }) => {
      return _getters.approvedRoles(boardId).some((role) => role.personId === personId)
    },

  vacanciesUnderPerson:
    (_state, _getters) =>
    ({ boardId, personId }) => {
      return _getters
        .reportsTo({ boardId, personId })
        ?.filter((person) => person.employeeStatus === 'Vacant').length
    }
}

const actions = {
  //TODO: remove from vuex (no dependency/effect on state)
  //TODO: weee only
  async fetchPersonByEmail(context, { boardId, email }) {
    try {
      //MIGRATION: this could be an array?
      return await getPersonByEmail({ boardId, email: email.toLowerCase() })
    } catch (e) {
      console.error('fetchPersonByEmail', e)
      Sentry?.captureException(e)
    }
  },

  /**
   * Fetch people for given boardId
   * @param boardId
   * @param force - if true, fetches people data from firestore even if it is already fetched
   * @param background - if true, doesn't set "loading" flag
   */
  async fetch({ commit, getters }, { boardId, force = true, background = false }) {
    let success = true
    try {
      if (!force && getters.people(boardId)?.length > 0) return false
      if (!force && getters.isFetchingPeople(boardId)) return false

      if (!background) commit(M.SET_IS_FETCHING_PEOPLE, { boardId, status: true })

      // const people = await getPeopleFromLevels({ boardId, levels: 2 })
      const people = await getPeople({ boardId })

      commit(M.SET_PEOPLE_DATA, {
        boardId,
        people
      })
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
      success = false
    } finally {
      if (!background) commit(M.SET_IS_FETCHING_PEOPLE, { boardId, status: false })
    }
    return success
  },

  // async fetchPerson({ commit }, { boardId, personId }) {
  //   try {
  //     const person = await getPerson({ boardId, personId })
  //
  //     commit(M.SET_PERSON, {
  //       boardId,
  //       person: person
  //     })
  //   } catch (e) {
  //     console.error(e)
  //     Sentry?.captureException(e)
  //   }
  // },

  async updatePersonInfo({ commit, getters }, { personObj, boardId }) {
    if (personObj.isNoManagerNode) return // Don't do anything. This is a virtual node.

    const originPersonObj = getters.personById({ boardId, personId: personObj.personId })
    const personId = personObj.personId

    personObj.email = personObj.email ? personObj.email.trim() : ''

    const updatedPersonObject = applyEditedState({
      oldPersonObject: originPersonObj,
      updatedPersonObject: ensureMetaProperties(personObj)
    })

    if (updatedPersonObject?.role !== originPersonObj?.role) {
      updatedPersonObject.scenarioMetaData.changes.push(
        newRoleChangeRecord(originPersonObj?.role, updatedPersonObject?.role)
      )
    }

    commit(M.UPDATE_PERSON, { boardId, person: updatedPersonObject })

    try {
      const updatedPerson = await updatePerson({ boardId, personId, person: updatedPersonObject })

      commit(M.UPDATE_PERSON, { boardId, person: updatedPerson })
      personUpdateBus.emit({ type: 'update', person: updatedPerson })
    } catch (e) {
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  /**
   * Patches in the format of: [{ personId: string, patch: object }]
   * When internal array has some elements removed, the patching won't work.
   * Transition to use updatePeople instead
   */
  async patchPeople({ commit, getters }, { boardId, patches }) {
    const dataToSet = []

    patches.forEach(({ personId, patch }) => {
      const person = getters.personById({ boardId, personId })
      if (!person) return

      let force = false

      if (patch.managers && !isEqual(patch.managers, person.managers)) {
        person.managers = [...patch.managers]
        force = true

        delete patch.managers
      }

      if (patch.subordinates && !isEqual(patch.subordinates, person.subordinates)) {
        person.subordinates = [...patch.subordinates]
        force = true

        delete patch.subordinates
      }

      if (
        patch.indirectConnections &&
        !isEqual(patch.indirectConnections, person.indirectConnections)
      ) {
        person.indirectConnections = [...patch.indirectConnections]
        force = true

        delete patch.indirectConnections
      }

      if (matches(patch)(person) && !force) {
        return
      }

      const updatedPerson = merge(person, patch)

      commit(M.UPDATE_PERSON_RAW, { boardId, person: updatedPerson })
      dataToSet.push(updatedPerson)
    })

    if (dataToSet.length === 0) return

    try {
      await updatePeople({ boardId, people: dataToSet })
    } catch (e) {
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  async updatePeople({ commit, getters }, { boardId, people }) {
    const dataToSet = []

    people.forEach((updatedPerson) => {
      const person = getters.personById({ boardId, personId: updatedPerson.personId })
      if (!person) return

      if (isEqual(person, updatedPerson)) {
        return
      }

      commit(M.UPDATE_PERSON, { boardId, person: updatedPerson })
      dataToSet.push(updatedPerson)
    })

    if (dataToSet.length === 0) return

    try {
      await updatePeople({ boardId, people: dataToSet })
    } catch (e) {
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  /**
   * API efficient method of updating selected properties of people
   * @param boardId
   * @param peopleIds
   * @param properties - Format: { property1: value1, property2: value2, ... }. Properties need to be columns in people table
   * @returns {Promise<void>}
   */
  async bulkUpdatePeople({ commit, getters }, { boardId, peopleIds, properties }) {
    const peopleById = getters.peopleById(boardId)
    const peopleToUpdate = peopleIds.map((personId) => {
      const person = peopleById[personId]
      if (!person) return null

      return Object.assign(person, properties)
    })

    commit(M.UPDATE_PEOPLE, { boardId, people: peopleToUpdate })

    try {
      await bulkUpdate({ boardId, peopleIds, properties })
    } catch (e) {
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  /**
   * API efficient method of updating scenario status of selected people
   * @param boardId
   * @param peopleIds
   * @param properties - Format: { property1: value1, property2: value2, ... }. Properties need to be columns in people table
   * @returns {Promise<void>}
   */
  async markAs({ commit, getters }, { boardId, peopleIds, status }) {
    const peopleById = getters.peopleById(boardId)
    const peopleToUpdate = peopleIds.map((personId) => {
      const person = peopleById[personId]
      if (!person) return null

      return setScenarioStatus({ person, status })
    })

    commit(M.UPDATE_PEOPLE, { boardId, people: peopleToUpdate })

    try {
      await bulkStatusUpdate({ boardId, peopleIds, status })
    } catch (e) {
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  async addPeople({ commit }, { boardId, people }) {
    people.forEach((updatedPerson) => {
      commit(M.SET_PERSON, { boardId, person: updatedPerson })
    })

    try {
      await addPeople({ boardId, people })
    } catch (e) {
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  async updateManager({ commit, getters }, { personId, boardId, newManagerId }) {
    const originPersonObj = {
      ...getters.personById({ boardId, personId }),
      managers: [newManagerId]
    }

    const newManager = getters.personById({ boardId, personId: newManagerId }) || {}
    const oldManager =
      find(getters.people(boardId), (person) => person?.subordinates?.includes(personId)) || {}

    if (oldManager) {
      oldManager.subordinates = xor(oldManager.subordinates, [personId])
      commit(M.UPDATE_PERSON, { boardId, person: oldManager })
    }

    newManager.subordinates = union(newManager.subordinates, [personId])

    commit(M.UPDATE_PERSON, { boardId, person: newManager })
    commit(M.UPDATE_PERSON, { boardId, person: originPersonObj })

    try {
      await updateManager({ boardId, personId, newManagerId })
    } catch (e) {
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  //TODO: this is almost a duplicate of updateManager, refactor
  async updatePersonInfoAndManager({ commit, getters }, { personObj, boardId, newManagerId }) {
    if (!personObj) return
    if (personObj.isNoManagerNode) return // Don't do anything. This is a virtual node.

    const personId = personObj.personId

    const movedPerson = applyPersonMoved({
      person: personObj,
      newManagerId
    })

    const newManager = getters.personById({ boardId, personId: newManagerId }) || {}

    const oldManager =
      find(getters.people(boardId), (person) => person?.subordinates?.includes(personId)) || {}

    if (oldManager) {
      oldManager.subordinates = xor(oldManager.subordinates, [personId])
      commit(M.UPDATE_PERSON, { boardId, person: oldManager })
    }

    newManager.subordinates = union(newManager.subordinates, [personId])

    let managerToUpdate = setNewReport(setEdited(newManager))

    commit(M.UPDATE_PERSON, { boardId, person: managerToUpdate })
    commit(M.UPDATE_PERSON, { boardId, person: movedPerson })

    try {
      await updatePerson({ boardId, personId, person: movedPerson })
      await updateManager({ boardId, personId, newManagerId })

      personUpdateBus.emit({ type: 'update', person: movedPerson })
    } catch (e) {
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  //TODO: move out of vuex, no dependency or effect on the state
  async addPerson(context, { personObj, boardId }) {
    if (!boardId) throw new Error('BoardId is undefined')

    if (personObj.isNoManagerNode) return // Don't do anything. This is a virtual node.

    personObj.boardId = boardId

    try {
      await addPerson({ boardId, person: personObj })
    } catch (e) {
      console.error('Error adding new person: ', e)
      console.error(personObj)
      errorBus.emit({ type: 'sync', message: e })

      if (Sentry) Sentry.captureException(e)
    }
  },

  async deletePeople(context, { personIds, boardId }) {
    try {
      if (!personIds || personIds.length === 0) return
      // keeping a copy before running mutation to keep the old records to perform operation on the DB in the background
      const people = cloneDeep(context.state.people[boardId])

      // this will quickly update the UI
      context.commit(M.REMOVE_PERSON, { boardId, personIds })
      const peopleUpdates = []

      for (const personId of personIds) {
        let personToBeRemoved = find(people, (person) => person.personId === personId)

        // Remove this person from its manager, if any
        const manager = find(people, (person) => {
          return person?.subordinates?.includes(personId)
        })

        if (manager && !manager.isNoManagerNode) {
          manager.subordinates = xor(manager.subordinates, [personId])
          peopleUpdates.push(manager)
        }

        // if someone is reporting to this person - update the managers of it's subordinate
        if (personToBeRemoved?.subordinates?.length) {
          const subordinatesOfRemovedPerson = filter(people, (person) =>
            person.managers.includes(personId)
          )
          for (const subordinate of subordinatesOfRemovedPerson) {
            remove(subordinate.managers, (managerId) => personIds.includes(managerId))
            peopleUpdates.push(subordinate)
          }
        }

        //remove that person
        personToBeRemoved = markAsRemoved(personToBeRemoved)
        peopleUpdates.push(personToBeRemoved)
      }
      await updatePeople({ boardId, people: peopleUpdates })
      // sets deletedAt field
      await deletePeople({ boardId, personIds })
    } catch (e) {
      console.error('Error removing person from manager: ', e, {
        personIds,
        boardId
      })

      if (Sentry) Sentry.captureException(e)
    }
  },

  async addOpenRoleWithCompensation(
    context,
    {
      newPersonId,
      managerObj,
      boardId,
      role,
      status,
      name = '',
      officeLocation = '',
      compensation,
      subordinates = [],
      scenarioMetaData = {},
      department = '',
      type = null,
      sourceEmployeeId = null,
      startDate = null,
      terminationDate = null,
      email = '',
      isRoot = false
    }
  ) {
    if (!boardId) throw new Error('BoardId is undefined')

    const newPersonObj = getNewPerson({
      boardId,
      newPersonId,
      role,
      name,
      status,
      managerObj,
      officeLocation,
      subordinates,
      scenarioMetaData,
      department,
      type,
      sourceEmployeeId,
      startDate,
      terminationDate,
      email,
      isRoot
    })

    context.commit(M.SET_PERSON, { boardId, person: newPersonObj })
    context.dispatch(
      'addCompensation',
      { personId: newPersonId, boardId, ...compensation },
      { root: true }
    )

    addSubordinate({ managerObj, subordinateId: newPersonId })
    managerObj = setEdited(managerObj)

    // TODO this has to be a transaction instead of a chain request
    try {
      await addPerson({ boardId, person: newPersonObj })

      if (managerObj && !managerObj.isNoManagerNode) {
        await updatePerson({ boardId, personId: managerObj.personId, person: managerObj })

        context.commit(M.UPDATE_PERSON, { boardId, person: managerObj })
      }
    } catch (e) {
      Sentry?.captureException(e)
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  async addOpenRole(
    context,
    {
      newPersonId,
      managerObj,
      boardId,
      role,
      status,
      name = '',
      officeLocation = '',
      subordinates = [],
      scenarioMetaData = {},
      department = '',
      type = null,
      sourceEmployeeId = null,
      startDate = null,
      terminationDate = null,
      email = '',
      isRoot = false
    }
  ) {
    await context.dispatch('addOpenRoleWithCompensation', {
      newPersonId,
      managerObj,
      boardId,
      role,
      status,
      name,
      officeLocation,
      subordinates,
      department,
      scenarioMetaData,
      compensation: getNewCompensation({
        personId: newPersonId,
        boardId,
        defaultCurrency: context.rootGetters.preferredBaseCurrency
      }),
      type,
      sourceEmployeeId,
      startDate,
      terminationDate,
      email,
      isRoot
    })
  },

  /**
   * This is a simplified implementation that marks selected people as removed recursively, without removing the hierarchy data
   */
  async removePeople(context, { managerId, peopleIds, boardId }) {
    try {
      peopleIds.forEach((personId) => {
        const removedPerson = markAsRemoved(context.getters.personById({ boardId, personId }))
        context.commit(M.UPDATE_PERSON_RAW, { boardId, person: removedPerson })

        updatePerson({
          boardId,
          personId,
          person: removedPerson
        })
      })

      const removedPerson = markAsRemoved(
        context.getters.personById({ boardId, personId: managerId })
      )
      context.commit(M.UPDATE_PERSON_RAW, { boardId, person: removedPerson })

      await updatePerson({
        boardId,
        personId: managerId,
        person: removedPerson
      })
    } catch (e) {
      errorBus.emit({ type: 'sync', message: e })
    }
  },

  reset({ commit }) {
    commit(M.RESET)
  },

  async fetchUserInfos(context, { boardId, userIds }) {
    try {
      const result = await getUsers(userIds)
      context.commit(M.SET_OWNER_INFOS, { boardId, ownerInfos: result })
      return result
    } catch (error) {
      console.error(error)
    }
  },

  async fetchAllOwnerInfos(context, { boardId }) {
    try {
      const board = context.rootGetters.getBoard(boardId)
      const result = await getUsers(board?.owners || [])

      context.commit(M.SET_OWNER_INFOS, { boardId, ownerInfos: result })
      return result
    } catch (error) {
      console.error(error)
    }
  },

  /**
   * Used for public plan at the moment
   */
  set({ commit }, { boardId, people }) {
    commit(M.SET_PEOPLE_DATA, { boardId, people })
  },

  updateLocalPerson({ commit, getters }, { boardId, patch }) {
    if (!patch.personId) return

    const existingPerson = { ...getters.personById({ boardId, personId: patch.personId }) }
    const updatedPerson = merge(existingPerson, patch)

    commit(M.SET_PERSON, { boardId, person: updatedPerson })
  },

  async fetchOrgApprovedRoles({ commit }, { boardId }) {
    const roles = await getOrgApprovedRoles({ boardId })
    commit(M.SET_APPROVED_ROLES, { boardId, approvedRoles: roles })
  },

  async moveEveryoneTo({ getters, dispatch }, { personIds, toPersonId, boardId }) {
    const toPerson = getters.personById({ boardId, personId: toPersonId })

    const updatedPeople = []
    const managersToUpdate = personIds.map((personId) => {
      const person = getters.personById({ boardId, personId })
      return person?.managers?.[0]
    })

    //find managers who have personIds among their subordinates
    const managersWithSubordinates = uniq(
      getters
        .people(boardId)
        .filter((manager) => {
          return manager?.subordinates?.some((personId) => personIds.includes(personId))
        })
        .map((manager) => manager.personId)
    )

    personIds.forEach((personId) => {
      updatedPeople.push({
        personId: personId,
        patch: {
          managers: [toPersonId]
        }
      })
    })

    updatedPeople.push({
      personId: toPersonId,
      patch: {
        subordinates: uniq([...toPerson.subordinates, ...personIds])
      }
    })

    uniq([...managersToUpdate, ...managersWithSubordinates]).forEach((managerId) => {
      if (managerId === toPersonId) return

      const manager = getters.personById({ boardId, personId: managerId })
      if (manager) {
        const updatedSubordinates =
          manager.subordinates?.filter((personId) => !personIds.includes(personId)) || []

        updatedPeople.push({
          personId: managerId,
          patch: {
            subordinates: updatedSubordinates
          }
        })
      }
    })

    await dispatch('patchPeople', { boardId, patches: updatedPeople })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
