import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'

// Fetch comments
export async function getComments({ boardId }) {
  try {
    const ret = await axios.get(`${API_URL}/comments/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    }

    throw new Error(ret.statusText)
  } catch (error) {
    console.error(error)
    return null
  }
}

// Add a comment
export async function addComment({ boardId, comment }) {
  try {
    const ret = await axios.post(
      `${API_URL}/comments/${boardId}`,
      { data: { comment } },
      {
        headers: { ...(await getAuthHeader()) }
      }
    )

    if (ret.status === 201) {
      return ret.data
    }

    throw new Error(ret.statusText)
  } catch (error) {
    console.error(error)
    return null
  }
}

// Update a comment
export async function updateComment({ boardId, comment }) {
  try {
    const ret = await axios.post(
      `${API_URL}/comments/${boardId}/update`,
      { data: { comment } },
      {
        headers: { ...(await getAuthHeader()) }
      }
    )

    if (ret.status === 200) {
      return ret.data
    }

    throw new Error(ret.statusText)
  } catch (error) {
    console.error(error)
    return null
  }
}
