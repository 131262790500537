import * as Sentry from '@sentry/browser'
import {
  getAllPacks,
  createPack,
  deletePack,
  addSlide,
  deleteSlide,
  orderSlides
} from '@/services/ppt-api.service'
const state = {
  packs: []
}

const getters = {
  ppt: (_state) => {
    return {
      packs: _state.packs
    }
  }
}

const mutations = {
  setPacks(_state, _pack) {
    _state.packs = _pack
  }
}

const actions = {
  async fetchAllPacks(context, { boardId }) {
    try {
      const packs = await getAllPacks({ boardId })
      context.commit('setPacks', packs)
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
    }
  },

  async createNewPack(context, { boardId, pack }) {
    try {
      const success = await createPack({ boardId, pack })
      if (success) {
        const packs = [...state.packs]
        packs.push(pack)
        context.commit('setPacks', packs)
      }
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
    }
  },

  async deleteExistingPack(context, { boardId, packId }) {
    try {
      const success = await deletePack({ boardId, packId })
      if (success) {
        const packs = state.packs.filter((p) => p.id !== packId)
        context.commit('setPacks', packs)
      }
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
    }
  },

  async addNewSlide(context, { boardId, packId, slide }) {
    try {
      const success = await addSlide({ boardId, packId, slide })
      if (success) {
        const packs = [...state.packs]
        const pack = packs.find((p) => p.id === packId)
        if (!pack) return
        pack.slides.push(slide)
        context.commit('setPacks', packs)
      }
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
    }
  },

  async deleteExistingSlide(context, { boardId, packId, slideId }) {
    try {
      const success = await deleteSlide({ boardId, packId, slideId })
      if (success) {
        const packs = [...state.packs]
        const pack = packs.find((p) => p.id === packId)
        pack.slides = pack.slides.filter((slide) => slide.id !== slideId)
        context.commit('setPacks', packs)
      }
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
    }
  },

  async orderAllSlides(context, { boardId, packId, slides }) {
    try {
      await orderSlides({ boardId, packId, slides })
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
    }
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
