// import { getNewPeople } from '@/services/people-api.service.js'
import { sortBy } from 'lodash-es'

const getDefaultState = () => {
  return {
    updatedPeople: []
  }
}

const state = getDefaultState()

const mutations = {
  SET_PEOPLE(_state, people) {
    _state.updatedPeople = sortBy(people, (person) => {
      return person.name ? person.name : ''
    })
  },
  RESET_SYNC_MODULE(_state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(_state, getDefaultState())
  }
}

const getters = {
  getUpdatedPeople: (_state) => {
    return _state.updatedPeople
  }
}

const actions = {
  /* Fetch newly added people from the main board. */
  // async fetchNewPeopleFromMain(context, data) {
  //   const srcBoardId = data.srcBoardId
  //   const queryFrom = data.from
  //
  //   const updated = getNewPeople({ boardId: srcBoardId, from: queryFrom })
  //   context.commit('SET_PEOPLE', updated)
  // }
}

export default {
  state,
  mutations,
  actions,
  getters,
  modules: {}
}
