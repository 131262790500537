/**
 * Functions to identify the current browser using the navigator
 * Ref: https://github.com/arasatasaygin/is.js
 **/

const navigator = window.navigator
const userAgent = ((navigator && navigator.userAgent) || '').toLowerCase()
const vendor = ((navigator && navigator.vendor) || '').toLowerCase()

export function isSafari() {
  const match = userAgent.match(/version\/(\d+).+?safari/)
  return match !== null
}

export function isOpera() {
  const match = userAgent.match(/(?:^opera.+?version|opr)\/(\d+)/)
  return match !== null
}

export function isChrome() {
  const match = /google inc/.test(vendor) ? userAgent.match(/(?:chrome|crios)\/(\d+)/) : null
  return match !== null && !isOpera()
}

export function isEdge() {
  const match = userAgent.match(/edge\/(\d+)/)
  return match !== null
}

export function isFirefox() {
  const match = userAgent.match(/(?:firefox|fxios)\/(\d+)/)
  return match !== null
}

export function isIE() {
  const match = userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/)
  return match !== null
}

export function isMac() {
  return navigator.platform?.toUpperCase().indexOf('MAC') >= 0 || false
}

export const sanitize = (string) => {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '/': '&#x2F;'
  }
  const reg = /[&<>"'/]/gi
  return string.replace(reg, (match) => map[match])
}
