import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'

/**
 * Returns an array of Objects with user profiles matching provided uid's
 * @param {Array} userIds - array of uid values
 */
export const getUsers = async (userIds) => {
  //MIGRATION: auth related, not moving
  const query = userIds.map((uid) => ({ uid }))

  const ret = await axios.post(
    `${API_URL}/users/get-users`,
    { data: { userIds: query } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

export const writeToGoogleSheet = async (docId, data) => {
  //MIGRATION: middleware to talk to sheets api, ignore?
  const updateDoc = firebase.functions().httpsCallable('googleSheetIntegration-writeToGoogleSheet')
  try {
    await updateDoc({ data, docId })
  } catch (e) {
    console.log('err caught in updateDoc')
  }
}

export const readFromGoogleSheet = async (docId) => {
  //MIGRATION: middleware to talk to sheets api, ignore?
  const readDoc = firebase.functions().httpsCallable('googleSheetIntegration-readFromGoogleSheet')
  try {
    const res = await readDoc({ docId })
    return res
  } catch (e) {
    console.log('err caught in updateDoc')
  }
}

export const copyGoogleSheet = async ({ sourceId, title, userEmail }) => {
  //MIGRATION: middleware to talk to sheets api, ignore?
  const copyFile = firebase.functions().httpsCallable('googleSheetIntegration-copyFile')

  try {
    const ret = await copyFile({ sourceId, title, userEmail })
    return ret
  } catch (e) {
    console.log('err caught in googleSheetIntegration-copyFile', e)
  }
}

export const givePermission = async ({ userEmail, docId }) => {
  //MIGRATION: middleware to talk to sheets api, ignore?
  try {
    const givePermissionForUser = firebase
      .functions()
      .httpsCallable('googleSheetIntegration-givePermissionForUser')

    await givePermissionForUser({ userEmail, docId })
  } catch (e) {
    console.log('err caught in givePermission', e)
  }
}

export const removePermission = async ({ userEmail, docId }) => {
  //MIGRATION: middleware to talk to sheets api, ignore?
  try {
    const removePermission = firebase
      .functions()
      .httpsCallable('googleSheetIntegration-removePermissionForUser')

    await removePermission({ userEmail, docId })
  } catch (e) {
    console.log('err caught in givePermission', e)
  }
}
