import { ACCESS_LEVELS_DICT, ACCESS_LEVELS_GROUPS } from '@/lib/Access.js'

export const MenuItems = {
  OrgPlan: 'Org Chart',
  Analytics: 'Analytics',
  Directory: 'Directory',
  Integration: 'Integration',
  Forecast: 'Forecast',
  Plans: 'Scenarios',
  Access: 'Access',
  ImpactOfPlannedChanges: 'Impact of planned changes',
  Hub: 'Hub',
  Settings: 'Settings'
}

export const FeatureAccess = {
  [MenuItems.Plans]: ACCESS_LEVELS_GROUPS.ABOVE_IC,
  [MenuItems.OrgPlan]: ACCESS_LEVELS_GROUPS.ALL,
  [MenuItems.Analytics]: ACCESS_LEVELS_GROUPS.ABOVE_IC,
  [MenuItems.Directory]: ACCESS_LEVELS_GROUPS.ABOVE_IC,
  [MenuItems.Forecast]: ACCESS_LEVELS_GROUPS.TOP_LEVEL_FINANCE,
  [MenuItems.Access]: [ACCESS_LEVELS_DICT.ADMIN],
  [MenuItems.ImpactOfPlannedChanges]: ACCESS_LEVELS_GROUPS.TOP_LEVEL_FINANCE,
  [MenuItems.Hub]: ACCESS_LEVELS_GROUPS.TOP_LEVEL_FINANCE_HR,
  [MenuItems.Settings]: [ACCESS_LEVELS_DICT.ADMIN]
}

const generateConfig = (boardId, role) => ({
  [MenuItems.Plans]: {
    name: 'All scenarios',
    url: `/plans/${boardId}`,
    show: FeatureAccess[MenuItems.Plans].includes(role)
  },
  [MenuItems.OrgPlan]: {
    name: 'Org Chart',
    url: `/board/${boardId}`,
    show: FeatureAccess[MenuItems.OrgPlan].includes(role)
  },
  [MenuItems.Directory]: {
    name: 'Directory',
    url: `/table/${boardId}`,
    show: FeatureAccess[MenuItems.Directory].includes(role)
  },
  [MenuItems.Forecast]: {
    name: 'Forecast',
    url: `/forecast/${boardId}`,
    show: FeatureAccess[MenuItems.Forecast].includes(role)
  },
  [MenuItems.Access]: {
    name: 'Access control',
    url: `/invite-access/${boardId}`,
    show: FeatureAccess[MenuItems.Access].includes(role)
  },
  [MenuItems.ImpactOfPlannedChanges]: {
    name: 'Impact of planned changes',
    url: `/planned-changes-impact/${boardId}`,
    show: FeatureAccess[MenuItems.ImpactOfPlannedChanges].includes(role)
  },
  [MenuItems.Hub]: {
    name: 'Hub',
    url: `/hub/${boardId}`,
    show: FeatureAccess[MenuItems.Hub].includes(role)
  },
  [MenuItems.Settings]: {
    name: 'General settings',
    url: `/settings/${boardId}`,
    show: FeatureAccess[MenuItems.Settings].includes(role)
  }
})

/**
 * Get configuration for navigation bar
 * @param boardId
 * @returns {[{icon: *, name: string, href: string},{name: string, icon: *, subitems: [{name: string, href: string, routeName: string},{name: string, disabled: boolean, href: string, routeName: string},{name: string, href: string, routeName: string},{name: string, href: string, routeName: string}]},{name: string, icon: *, subitems: [{name: string, href: string},{name: string, href: string},{name: string, href: string}]}]}
 */
export const getNavigationConfig = ({ boardId, route, isVerified, role }) => {
  if (boardId && isVerified && route.name !== 'No Access') {
    return generateConfig(boardId, role)
  }

  return {}
}
