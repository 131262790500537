import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { uniq } from 'lodash-es'

/**
 * Function to reset state to default values
 */
const getDefaultState = () => {
  return {
    accessData: []
  }
}

const state = getDefaultState()

const getters = {
  allowedPersonIds: (_state) => _state.accessData
}

const mutations = {
  setAccessData(_state, obj) {
    _state.accessData = [...obj]
  }
}

const actions = {
  async fetchPersonAccess(context, { boardId }) {
    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('salary_calculator_permissions')
        .where('boardId', '==', boardId)
        .get()

      const result = []
      querySnapshot.forEach((doc) => {
        result.push(doc.data())
      })

      if (result.length > 0) {
        const arr = result.map((res) => {
          return {
            department: res.department,
            personIds: res.accessAllowedPersonIds
          }
        })
        context.commit('setAccessData', arr)
        return
      }
      context.commit('setAccessData', [])
    } catch (e) {
      window.console.error('Error adding new permission access for salary calculator: ', e)
      if (Sentry) Sentry.captureException(e)
    }
  },

  async mutatePersonAccess(context, { department, personId, boardId, remove = false }) {
    const departmentObj =
      context.state.accessData.find((item) => item.department === department) || {}
    const existingIds = departmentObj.personIds || []
    const newData = {
      boardId,
      department,
      accessAllowedPersonIds: uniq(
        !remove ? [...existingIds, personId] : [...existingIds.filter((uid) => uid !== personId)]
      ),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      ...(!remove && {
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      })
    }
    try {
      await firebase
        .firestore()
        .collection('salary_calculator_permissions')
        .doc(`${boardId}_${department}`)
        .set(newData, { merge: true })
      context.dispatch('fetchPersonAccess', { boardId })
    } catch (e) {
      window.console.error('Error adding new permission access for salary calculator: ', e)
      if (Sentry) Sentry.captureException(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {}
}
