import { isNumber } from 'lodash-es'
import numeral from 'numeral'
import CurrencySymbols from '@/lib/CurrencySymbolConstants.js'

export const SCALING_FACTORS = {
  K: 1000,
  M: 1000000,
  B: 1000000000,
  T: 1000000000000
}

/**
 * Returns localised string with amount in given currency
 * @param amount
 * @param currency
 * @returns {string}
 */
export const localiseCurrency = (
  amount,
  currency = 'USD',
  maximumFractionDigits = 0,
  notation = 'standard'
) => {
  const locale = navigator.language
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    maximumFractionDigits,
    currency,
    notation
  }).format(amount)
}

export const createNumberFormat = ({ currency = 'USD', maximumFractionDigits = 0 } = {}) => {
  const locale = navigator.language
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    maximumFractionDigits,
    currency: currency
  })
}

export const localiseCurrencyOptions = (amount, numberFormat) => {
  return numberFormat.format(amount)
}

//TODO: potentially unsafe when decimal separator is not a dot/comma
export const extractNumber = (stringValue) => {
  if (isNumber(stringValue)) return stringValue

  const nonDigits = /[^0-9.-]+/g
  return stringValue?.length ? Number(stringValue.replace(nonDigits, '')) : 0
}

/**
 * Formats a number by rounding it off and using abbreviations like "M" and "K".
 * @param {number} number - The number to be formatted.
 * @returns {string} The formatted number string.
 */
export const shortenNumber = (number) => {
  return numeral(number).format('0.0 a')
}

/**
 * Abbreviates a numerical value with a unit and returns the formatted value and its unit value.
 *
 * @param {number} inputValue - The numerical value to be abbreviated.
 * @returns {Object} - {formattedValue, unitValue} An object containing the formatted value and its corresponding unit value.
 */
export const abbreviateWithUnitValue = (inputValue) => {
  const formatted = numeral(inputValue).format('0.0a').toUpperCase()
  const scalingFactor = formatted.charAt(formatted.length - 1)
  if (!Object.keys(SCALING_FACTORS).includes(scalingFactor)) {
    let value = 1
    if (inputValue >= 100) {
      value = 100
    } else if (inputValue >= 10) {
      value = 10
    }
    return {
      formattedValue: formatted,
      unitValue: value
    }
  }

  return {
    formattedValue: formatted,
    unitValue: SCALING_FACTORS[scalingFactor]
  }
}

export const abbreviateWithUnitValueCustomFormat = ({ value, format = '0.0a' }) => {
  const formatted = numeral(value).format(format).toUpperCase()
  const scalingFactor = formatted.charAt(formatted.length - 1)
  if (!Object.keys(SCALING_FACTORS).includes(scalingFactor)) {
    let result = 1
    if (value >= 100) {
      result = 100
    } else if (value >= 10) {
      result = 10
    }

    return {
      formattedValue: formatted,
      unitValue: result
    }
  }

  return {
    formattedValue: formatted,
    unitValue: SCALING_FACTORS[scalingFactor]
  }
}

// basic number formating
export const numberFormat = (value, format = '0,0') => numeral(value).format(format)

/**
 * Function to find currency object from static list of currencies
 * @param str is the name or code of the currency you want to find, @param key defines first parameter (str)
 * @returns found currency object
 */
export const getCurrencyData = (str, key) =>
  CurrencySymbols.find((currency) => currency[key] === str) || {}

// Function to convert monetary string to actual number
export const convertMonetaryStringToNumber = (str) => {
  // Remove the last character and check if it is 'm' or 'k'
  const value = str.slice(0, -1)
  const suffix = str.slice(-1)
  let number = numeral(str).value()

  if (SCALING_FACTORS[suffix.toUpperCase()]) {
    number = numeral(value).value() * SCALING_FACTORS[suffix.toUpperCase()]
  }

  return number
}
