import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'

export async function createProject({ boardId, name, filters, uids }) {
  try {
    const ret = await axios.post(
      `${API_URL}/projects/${boardId}/create`,
      {
        data: {
          project: {
            name,
            filters,
            uids
          }
        }
      },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 201) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function editProject({ boardId, projectId, filters, uids }) {
  try {
    const ret = await axios.post(
      `${API_URL}/projects/${boardId}/${projectId}`,
      { data: { filters, uids } },
      {
        headers: { ...(await getAuthHeader()) }
      }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function deleteProject({ boardId, projectId }) {
  try {
    const ret = await axios.delete(`${API_URL}/projects/${boardId}/${projectId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function getAllProjects({ boardId }) {
  try {
    const ret = await axios.get(`${API_URL}/projects/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}
