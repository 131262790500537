<template>
  <div ref="el">
    <slot></slot>
  </div>
</template>

<script setup>
import { useElementSize } from '@vueuse/core'
import { computed, ref } from 'vue'

const el = ref(null)

const { width } = useElementSize(el)
const translateX = computed(() => (needsScroll.value ? `${-width.value / 2}px` : '0px'))
const needsScroll = computed(() => width.value > el.value.parentNode?.offsetWidth)
</script>

<style scoped>
div {
  @apply transition-all duration-1000;
}

div:hover {
  transform: translateX(v-bind(translateX));
}
</style>
