import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'

export const checkAccountStatus = async (email) => {
  try {
    const res = await axios.get(`${API_URL}/users/auth-type`, {
      params: {
        email
      }
    })
    return res
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Sends a failed login attempt to the server.
 * @param {string} email - The email address used for the login attempt.
 * @returns {Promise<Object|null>} - A Promise that resolves to the response object if the request is successful, or null if there is an error.
 */
export const failedLoginAttempt = async (email) => {
  try {
    const res = await axios.post(`${API_URL}/users/failed-login-attempt`, {
      data: {
        email
      }
    })
    return res
  } catch (error) {
    console.log(error)
    return null
  }
}

export const changedPassword = async ({ email, password }) => {
  try {
    const res = await axios.post(`${API_URL}/users/changed-password`, {
      data: {
        email,
        password
      }
    })
    return res.data
  } catch (error) {
    console.log(error)
    return null
  }
}

/**
 * Resets the password for a user.
 *
 * @param {string} email - The email of the user.
 * @returns {Promise<Object|null>} - A promise that resolves to the response object if successful, or null if there was an error.
 */
export const resetPassword = async (email) => {
  try {
    const res = await axios.post(`${API_URL}/users/reset-password`, {
      data: {
        email
      }
    })
    return res
  } catch (error) {
    console.log(error)
    return null
  }
}

export const logout = async () => {
  try {
    const res = await axios.post(
      `${API_URL}/users/logout`,
      { data: {} },
      { headers: { ...(await getAuthHeader()) } }
    )
    return res
  } catch (error) {
    console.log(error)
    return null
  }
}
