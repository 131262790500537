import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'

const getDefaultState = () => {
  return {}
}

const state = getDefaultState()

const mutations = {
  reset(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  async fetchPerformanceReviews(context, { boardId }) {
    if (!boardId) {
      throw new Error('Required fields are missing')
    }

    const querySnapshot = await firebase
      .firestore()
      .collection('performance_review_templates')
      .where('boardId', '==', boardId)
      .get()

    const templates = []
    querySnapshot.forEach((doc) => {
      templates.push(doc.data())
    })
    return templates
  },

  async fetchSetting(context, { boardId }) {
    if (!boardId) {
      throw new Error('Required fields are missing')
    }

    const doc = await firebase
      .firestore()
      .collection('performance_review_settings')
      .doc(boardId)
      .get()

    return doc.data()
  },

  async fetchPerformanceReviewTemplate(context, { boardId, templateId }) {
    if (!boardId || !templateId) {
      throw new Error('Required fields are missing')
    }
    // if (AccessCheck(board, user, targetPerson, me, peopleDict)) {
    //   // Check if performanceAccessibleByHROnly
    //   if (context.rootState.settings.performanceAccessibleByHROnly) {
    //     // If this is on, then check if the current user has
    //     // HR lead access
    //     if (me.personId !== context.rootState.settings.hrLeadPersonId) {
    //       console.log("access not granted");
    //       return null;
    //     }
    //   }

    try {
      const doc = await firebase
        .firestore()
        .collection('performance_review_templates')
        .doc(`${boardId}_${templateId}`)
        .get()

      return doc.data()
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return 'error'
    }
  },

  async updatePerformanceReviewTemplate(context, { boardId, templateId, user, updates }) {
    if (!boardId || !templateId || !user || !updates) {
      throw new Error('Required fields are missing')
    }
    await firebase
      .firestore()
      .collection('performance_review_templates')
      .doc(`${boardId}_${templateId}`)
      .update({
        updatedBy: user.uid,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        ...updates
      })
  },

  async fetchAllPerformanceReviewReceivers(context, { boardId }) {
    if (!boardId) {
      throw new Error('Required fields are missing')
    }
    const ret = await firebase
      .firestore()
      .collection('performance_review_receivers')
      .where('boardId', '==', boardId)
      .get()

    return _.map(ret.docs, (doc) => {
      return doc.data()
    })
  },

  async fetchPerformanceReviewReceivers(context, { boardId, templateId }) {
    if (!boardId || !templateId) {
      throw new Error('Required fields are missing')
    }
    const ret = await firebase
      .firestore()
      .collection('performance_review_receivers')
      .doc(`${boardId}_${templateId}`)
      .get()

    return ret.data()
  },

  async getPerformanceReviewForPersonId(context, { boardId, personId }) {
    if (!boardId) {
      throw new Error('Required fields are missing')
    }
    const ret = await firebase
      .firestore()
      .collection('performance_review_receivers')
      .where('boardId', '==', boardId)
      .where('sent', 'array-contains', personId)
      .get()

    const reviews = []
    ret.forEach((doc) => {
      reviews.push(doc.data())
    })
    return reviews
  },

  async setPerformanceReviewReceivers(context, { boardId, templateId, user, receivers }) {
    if (!boardId || !templateId || !user || !receivers) {
      throw new Error('Required fields are missing')
    }
    await firebase
      .firestore()
      .collection('performance_review_receivers')
      .doc(`${boardId}_${templateId}`)
      .set(
        {
          boardId,
          templateId,
          updatedBy: user.uid,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          receivers
        },
        { merge: true }
      )
  },

  async saveDraftPerformanceReviewAnswers(
    context,
    { boardId, user, templateId, personId, responsibilities, evaluations, selfEvaluation }
  ) {
    if (!boardId || !user) {
      throw new Error('Required fields are missing')
    }

    try {
      await firebase
        .firestore()
        .collection('performance_review_answer_drafts')
        .doc(`${templateId}_${personId}`)
        .set({
          templateId: templateId,
          boardId: boardId,
          revieweeName: user.displayName,
          revieweePersonId: personId,
          responsibilities,
          evaluations,
          selfEvaluation,
          updatedBy: user.uid,
          createdBy: user.uid,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdAt: firebase.firestore.FieldValue.serverTimestamp()
        })
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return 'error'
    }
  },

  async loadDraft(context, { boardId, templateId, personId }) {
    console.log(boardId, templateId, personId)
    if (!boardId || !templateId || !personId) {
      throw new Error('Required fields are missing')
    }

    console.log('loadDraft')

    try {
      const doc = await firebase
        .firestore()
        .collection('performance_review_answer_drafts')
        .doc(`${templateId}_${personId}`)
        .get()
      // if (querySnapshot.docs.length > 0) {
      //   return querySnapshot.docs[0].data();
      // }
      return doc.data()
    } catch (e) {
      // console.error(e);
      // Sentry.captureException(e);
      return 'document DNE'
    }
  },

  async fetchPerformanceReviewAnswer(context, { boardId, templateId, personId }) {
    console.log(boardId, templateId, personId)
    if (!boardId || !templateId || !personId) {
      throw new Error('Required fields are missing')
    }

    console.log('fetchPerformanceReviewAnswer')

    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('performance_review_answers')
        .where('templateId', '==', templateId)
        .where('boardId', '==', boardId)
        .where('revieweePersonId', '==', personId)
        .orderBy('updatedAt', 'desc')
        .get()

      const ret = []
      querySnapshot.forEach((doc) => {
        ret.push(doc.data())
      })

      // If there are more than one answer, pick the one that is
      // latest && has manager & vp rating
      // This happend because employees were submittinf after
      // the ratings were done. Which caused the existing doc with managers
      // rating to be overwritten.
      return _.maxBy(ret, 'updatedAt.seconds')
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return 'error'
    }
  },

  async fetchPerformanceReviewRatingOnly(context, { boardId, templateId, revieweePersonId }) {
    console.log(boardId, templateId, revieweePersonId)
    if (!boardId || !templateId || !revieweePersonId) {
      throw new Error('Required fields are missing')
    }

    console.log('fetchPerformanceReviewRatingOnly')

    try {
      var fetch = firebase.functions().httpsCallable('performanceReview-fetchRatingForEmployee')

      const rating = await fetch({
        boardId,
        templateId,
        revieweePersonId
      })

      return rating.data
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return 'error'
    }
  },

  async fetchPerformanceReviewsForDepartment(context, { boardId, templateId, departmentCode }) {
    console.log(boardId, templateId, departmentCode)
    if (!boardId || !templateId || !departmentCode) {
      throw new Error('Required fields are missing')
    }

    console.log('fetchPerformanceReviewsForDepartment')

    try {
      var fetch = firebase.functions().httpsCallable('performanceReview-fetchDepartmentReviews')

      const rating = await fetch({
        boardId,
        templateId,
        departmentCode
      })

      return rating.data
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return 'error'
    }
  },
  async fetchReviewsForPersonIds(context, { boardId, targetPersonIds }) {
    if (!boardId || !targetPersonIds) {
      throw new Error('Required fields are missing')
    }

    console.log('fetchReviewsForPersonIds')

    try {
      var fetch = firebase.functions().httpsCallable('performanceReview-fetchReviewsForPersonIds')

      const rating = await fetch({
        boardId,
        targetPersonIds
      })

      return rating.data
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return 'error'
    }
  },

  async fetchAllPerformanceReviewAnswers(context, { boardId, templateId }) {
    if (!boardId || !templateId) {
      throw new Error('Required fields are missing')
    }

    console.log('fetchAllPerformanceReviewAnswers')

    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('performance_review_answers')
        .where('templateId', '==', templateId)
        .where('boardId', '==', boardId)
        .get()

      const ret = []
      querySnapshot.forEach((doc) => {
        ret.push(doc.data())
      })

      // Gropu the ansers by person Id. Identified the latest revisions and leave only the latest
      const groupedByPersonId = _.chain(ret)
        .orderBy(['updatedAt.seconds'], ['desc'])
        .groupBy('revieweePersonId')
        .map((arr) => {
          return _.maxBy(arr, 'updatedAt.seconds')
        })
        .value()

      return groupedByPersonId
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return 'error'
    }
  },

  // There are cases where the evaluating manager is different from
  // the current manager.
  // e.g. an employee has switched team recently and the current
  // manager has no context to evaluate the manager.
  // In such case, we must override the reporting manager with
  // a manager responsible for evaluating.
  async saveChangedManagers(context, { boardId, templateId, changedManagerArray }) {
    if (!boardId || !templateId) {
      throw new Error('Required fields are missing')
    }

    console.log('saveChangedManagers')

    try {
      await firebase
        .firestore()
        .collection('performance_review_changed_managers')
        .doc(`${boardId}_${templateId}`)
        .set({
          boardId,
          templateId,
          changedManagerArray,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        })
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return 'error'
    }
  },
  async readChangedManagers(context, { boardId, templateId }) {
    if (!boardId || !templateId) {
      throw new Error('Required fields are missing')
    }

    console.log('readChangedManagers')

    try {
      const ret = await firebase
        .firestore()
        .collection('performance_review_changed_managers')
        .doc(`${boardId}_${templateId}`)
        .get()

      return ret.data()
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return 'error'
    }
  },

  async setVPMapping(context, { boardId, templateId, mapping }) {
    if (!boardId || !templateId) {
      throw new Error('Required fields are missing')
    }

    console.log('setVPMapping')

    try {
      await firebase
        .firestore()
        .collection('performance_review_vp_mapping')
        .doc(`${boardId}_${templateId}`)
        .set({
          boardId,
          templateId,
          mapping,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        })
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return 'error'
    }
  },

  async getVPMapping(context, { boardId, templateId }) {
    if (!boardId || !templateId) {
      throw new Error('Required fields are missing')
    }

    console.log('getVPMapping', boardId, templateId)

    try {
      const ret = await firebase
        .firestore()
        .collection('performance_review_vp_mapping')
        .doc(`${boardId}_${templateId}`)
        .get()
      return ret.data()
    } catch (e) {
      console.error(e, templateId)
      Sentry.captureException(e)
      return 'error'
    }
  },
  async getAllVPMapping(context, { boardId }) {
    if (!boardId) {
      throw new Error('Required fields are missing')
    }

    try {
      const snapshot = await firebase
        .firestore()
        .collection('performance_review_vp_mapping')
        .where('boardId', '==', boardId)
        .get()

      return _.map(snapshot.docs.map((doc) => doc.data()))
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return 'error'
    }
  }
}

export default {
  state,
  mutations,
  actions,
  modules: {}
}
