import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'
import { useDebounceFn } from '@vueuse/core'
import { groupBy } from 'lodash-es'
import { ref } from 'vue'
import { decompressJson } from '@/utils/Utils'

export const getTypes = async ({ boardId }) => {
  try {
    const url = new URL(`${API_URL}/custom-fields/${boardId}`)

    const ret = await axios.get(url, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else if (ret.status === 404) return []
    else throw new Error(ret.statusText)
  } catch (error) {
    console.error(error)
    return null
  }
}

export const fetchValues = async ({ boardId }) => {
  const ret = await axios.get(`${API_URL}/custom-fields/${boardId}/values`, {
    headers: { ...(await getAuthHeader()) }
  })

  if (ret.status === 200) {
    return decompressJson(ret.data) || []
  } else throw new Error(ret.statusText)
}

// Initialize pendingRequests as a Map to track ongoing requests
const pendingRequests = new Map()

// Initialize batchedRequests as an array instead of reassigning to an empty array
let batchedRequests = []
const responses = ref({})

const CLEANUP_INTERVAL = 60000 // 1 minute interval for cleanup

const fetchValuesForPersonsDebounced = useDebounceFn(async (boardId) => {
  const personIds = [...new Set(batchedRequests.map((obj) => obj.personId))]
  batchedRequests = []

  if (personIds.length === 0) return

  try {
    const ret = await axios.post(
      `${API_URL}/custom-fields/${boardId}/values-batch`,
      { data: { personIds } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      const data = decompressJson(ret.data) || []
      const result = groupBy(data, 'personId') || {}
      personIds.forEach((key) => {
        responses.value[key] = result[key] || []
        // Resolve the promise for each personId
        if (pendingRequests.has(key)) {
          pendingRequests.get(key).resolve(responses.value[key])
          pendingRequests.delete(key)
        }
      })
    } else {
      throw new Error(ret.statusText)
    }
  } catch (error) {
    console.error('Error fetching batched custom field values:', error)
    personIds.forEach((key) => {
      responses.value[key] = { error: error.message }
      // Reject the promise for each personId
      if (pendingRequests.has(key)) {
        pendingRequests.get(key).reject(error)
        pendingRequests.delete(key)
      }
    })
  }
}, 500)

async function addToQueue({ boardId, personId }) {
  if (pendingRequests.has(personId)) {
    // If a request is already pending for this personId, return the existing promise
    return pendingRequests.get(personId).promise
  }

  // Create a new promise for this request
  let resolveFn, rejectFn
  const promise = new Promise((resolve, reject) => {
    resolveFn = resolve
    rejectFn = reject
  })

  // Add to pendingRequests
  pendingRequests.set(personId, { promise, resolve: resolveFn, reject: rejectFn })

  // Add personId to the queue if it's not already present
  if (!batchedRequests.some((obj) => obj.personId === personId)) {
    batchedRequests.push({ boardId, personId })
  }

  // Fetch using the debounced function
  fetchValuesForPersonsDebounced(boardId)

  try {
    // Await the promise associated with this personId
    const result = await promise
    return result
  } catch (error) {
    console.error('Error in addToQueue:', error)
    return { error: error.message }
  }
}

export const fetchValuesForPersonBatched = async ({ boardId, personId }) => {
  if (!boardId || !personId) return []

  if (personId.includes('-collapsed-')) return []

  try {
    const result = await addToQueue({ boardId, personId })

    if (result && result.error) {
      throw new Error(result.error)
    }

    return result || []
  } catch (error) {
    console.error('Error in fetchValuesForPersonBatched:', error)
    return { error: error.message }
  }
}

export const filter = async ({ boardId, personIds, fields }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/custom-fields/${boardId}/filter`,
      { data: { personIds, fields } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return decompressJson(ret.data) || []
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getUniqueValues = async ({ boardId, projectId }) => {
  try {
    const url = new URL(`${API_URL}/custom-fields/${boardId}/unique-values`)

    if (projectId) {
      url.searchParams.set('projectId', projectId)
    }

    const ret = await axios.get(url, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return decompressJson(ret.data) || []
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const addOrUpdateField = async ({ boardId, fields }) => {
  const ret = await axios.post(
    `${API_URL}/custom-fields/${boardId}`,
    { data: { fields } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

export const bulkUpdate = async ({ boardId, peopleIds, values }) => {
  const ret = await axios.post(
    `${API_URL}/custom-fields/${boardId}/bulk-values`,
    { data: { values, peopleIds } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 200) {
    return ret.data
  } else throw new Error(ret.statusText)
}

export const addOrUpdateValues = async ({ boardId, fields }) => {
  try {
    const ret = await axios.post(
      `${API_URL}/custom-fields/${boardId}/values`,
      {
        data: { fields }
      },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const remove = async ({ boardId, fieldId }) => {
  try {
    const ret = await axios.delete(`${API_URL}/custom-fields/${boardId}/${fieldId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

// Cleanup function to remove old data and prevent memory leaks
function cleanup() {
  // No need to clean batchedRequests as it's being reset after each batch
  // However, ensure pendingRequests that are no longer needed are cleaned up
  // Optionally, implement logic to remove old entries based on timestamps if necessary
}

setInterval(cleanup, CLEANUP_INTERVAL)
