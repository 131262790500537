import * as Sentry from '@sentry/browser'
import {
  fetchSensitiveData,
  addOrUpdateSensitiveData
} from '@/services/sensitive-data-api.service.js'
import { keyBy, uniq, compact, flatten } from 'lodash-es'

const state = {
  sensitiveData: [],
  sensitiveDataByPersonId: {},
  isFetchingSensitiveData: {},
  sensitiveDataFields: {}
}

const getters = {
  /**
   * Return array of all sensitive data in a board
   */
  sensitiveData: (_state) => (boardId) => {
    return _state.sensitiveData?.[boardId] || []
  },

  uniqueSensitiveData: (_state) => (boardId) => {
    const data = _state.sensitiveData?.[boardId].map((entry) => entry.data).flat()

    const res = data.reduce((acc, item) => {
      // Initialize the key if it does not exist
      if (!acc[item.field]) {
        acc[item.field] = new Set()
      }
      // Add the value to the Set to ensure uniqueness
      acc[item.field].add(item.value)
      return acc
    }, {})

    // Convert Sets to arrays
    for (const key in res) {
      if (Object.prototype.hasOwnProperty.call(res, key)) {
        res[key] = Array.from(res[key])
      }
    }
    return res
  },

  /**
   * Return array of all the unqiue fields of the sensitive data in a board
   */
  sensitiveDataFields: (_state) => (boardId) => {
    return _state.sensitiveDataFields?.[boardId] || []
  },

  sensitiveDataForPerson:
    (_state) =>
    ({ boardId, personId }) => {
      return _state.sensitiveDataByPersonId[boardId]?.[personId] || {}
    },

  /**
   * Return sensitive data in dict format
   * {
   *  <fieldName>: {
   *    field: <fieldName>,
   *    value: <string>
   *  }, ...
   * }
   */
  sensitiveDataDict:
    (_state) =>
    ({ boardId, personId }) => {
      const sd = _state.sensitiveDataByPersonId[boardId]?.[personId]
      return keyBy(sd?.data, 'field')
    },

  isFetchingSensitiveData: (_state) => (boardId) => _state.isFetchingSensitiveData[boardId] ?? false
}

const mutations = {
  setSensitiveDataForBoard(_state, { data, boardId }) {
    _state.sensitiveData[boardId] = data
    _state.sensitiveDataByPersonId[boardId] = keyBy(_state.sensitiveData[boardId], 'personId')

    let uniqueFields = _state.sensitiveData[boardId]
      ?.filter((sd) => sd.personId !== '__salary_benchmark_settings')
      .map((sd) => sd.data.map((d) => d.field))

    uniqueFields = uniqueFields || []
    uniqueFields = flatten(uniqueFields)
    uniqueFields = uniq(uniqueFields)
    uniqueFields = compact(uniqueFields)

    _state.sensitiveDataFields[boardId] = uniqueFields
  },

  /**
   * sensitiveDataObj must contain all model fields
   */
  setSensitiveDataForPerson(_state, { boardId, sensitiveDataObj }) {
    const index = _state.sensitiveData[boardId].findIndex(
      (obj) => obj.personId === sensitiveDataObj.personId
    )

    if (index === -1) {
      _state.sensitiveData[boardId].push(sensitiveDataObj)
    } else {
      _state.sensitiveData[boardId][index] = sensitiveDataObj
    }

    _state.sensitiveDataByPersonId[boardId][sensitiveDataObj.personId] = sensitiveDataObj
  },
  setIsFetchingCompensations(_state, { boardId, status }) {
    _state.isFetchingSensitiveData[boardId] = status
  }
}

const actions = {
  /**
   * Fetch all sensitive data for a board
   */
  async fetchSensitiveData(context, { boardId, force = true }) {
    try {
      if (!force && context.getters.sensitiveData(boardId)?.length > 0) return
      if (!force && context.getters.isFetchingSensitiveData(boardId)) return

      context.commit('setIsFetchingCompensations', { boardId, status: true })
      const sensitiveDataArr = await fetchSensitiveData({ boardId })
      context.commit('setSensitiveDataForBoard', { data: sensitiveDataArr, boardId })
      context.commit('setIsFetchingCompensations', { boardId, status: false })
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
      context.commit('setIsFetchingCompensations', { boardId, status: false })
    }
  },

  /**
   * Update sensitive data for one or more people.
   * The patch does not have to contain all fields.
   */
  async addOrUpdateSensitiveData(context, { boardId, patches }) {
    if (!boardId) throw new Error('BoardId is undefined')

    try {
      const savedSensitiveDataArr = await addOrUpdateSensitiveData({ boardId, patches })
      savedSensitiveDataArr?.forEach((sensitiveDataObj) => {
        context.commit('setSensitiveDataForPerson', { boardId, sensitiveDataObj })
      })
    } catch (e) {
      console.error('Error updating new sensitive data: ', e)
      if (Sentry) Sentry.captureException(e)
    }
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
