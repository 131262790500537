const FONT_SIZE = 16
const labelStyle = {
  colors: '#667085',
  fontFamily: 'Inter'
}

const titleStyle = {
  color: '#667085',
  fontSize: FONT_SIZE,
  fontFamily: 'Inter',
  fontWeight: 400
  // cssClass: 'apexcharts-xaxis-title-custom'
}

const yAxisTitle = {
  rotate: -90,
  offsetX: 0,
  offsetY: 0
}

export { labelStyle, titleStyle, yAxisTitle, FONT_SIZE }
