<template>
  <div>
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-bluedark-50 px-3 py-2 text-sm font-semibold text-bluedark-700 ring-inset hover:bg-bluedark-100 focus:border-transparent focus:ring-0 disabled:bg-bluedark-25 disabled:text-bluedark-300"
        >
          <component :is="currentViewIcon?.icon" class="h-5 w-5 text-bluedark-500" />
          <ChevronDownIcon class="-mr-1 size-5 text-bluedark-500" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute left-0 z-10 flex w-56 origin-top-right flex-col gap-2 rounded-md bg-white p-2 shadow-lg ring-1 ring-black/5 focus:outline-none"
        >
          <MenuItem v-for="view in views" :key="view.name" v-slot="{ close }">
            <template v-if="!view.handler">
              <div>
                <TooltipBaseLink
                  v-if="!isPlanToolbar ? show(view.name) : true"
                  v-slot="{ currentPath }"
                  :path="path(view.name)"
                  :disabled="disabled"
                  :data-test="view.dataTest"
                  @click="close"
                >
                  <component
                    :is="view.icon"
                    class="h-5 w-5"
                    :class="{ 'text-bluedark-600': currentPath, 'text-gray-700': !currentPath }"
                  />
                  <span
                    class="grow text-sm"
                    :class="{ 'text-bluedark-600': currentPath, 'text-gray-700': !currentPath }"
                  >
                    {{ view.tooltipText }}
                  </span>
                  <SelectionIcon v-if="currentPath" class="h-5 w-5 text-bluedark-600" />
                </TooltipBaseLink>
              </div>
            </template>
            <template v-else>
              <button
                :disabled="disabled"
                class="flex items-center gap-2 rounded-lg px-[14px] py-2 text-sm font-medium transition-colors hover:bg-bluedark-50 hover:text-bluedark-700 disabled:text-gray-300"
                :class="{
                  'bg-gray-50 text-bluedark-600 hover:bg-bluedark-100': currentView === view.name,
                  'text-gray-700': currentView !== view.name
                }"
                :data-test="view.dataTest"
                @click="view.handler"
              >
                <component :is="view.icon" class="h-5 w-5" />
                <span class="grow text-start text-sm">{{ view.tooltipText }}</span>
                <SelectionIcon v-if="currentView === view.name" class="h-5 w-5 text-bluedark-600" />
              </button>
            </template>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import useNavigation from '@/hooks/use-navigation'
import ChevronDownIcon from '@/assets/untitled-ui/chevron-down.svg'
import TooltipBaseLink from '@/components/TooltipBaseLink.vue'
import { useRoute } from 'vue-router'
import SelectionIcon from '@/assets/untitled-ui/check-circle.svg'

const route = useRoute()

const props = defineProps({
  views: {
    type: Array,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  currentView: {
    type: String,
    required: false,
    default: ''
  },
  isPlanToolbar: {
    type: Boolean,
    required: false,
    default: false
  },
  projectId: {
    type: String,
    required: false,
    default: ''
  }
})

const { navigationConfig } = useNavigation()

const isCurrentPath = (path) => route.fullPath.match(/^\/([^?]+)/)?.[0] === path

const show = (menuItem) => computed(() => navigationConfig.value[menuItem]?.show || false).value
const path = (menuItem) => computed(() => navigationConfig.value[menuItem]?.url || '').value

const currentViewIcon = computed(() =>
  props.views.find((view) => props.currentView === view.name || isCurrentPath(path(view.name)))
)
</script>
