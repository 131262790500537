import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'
// import DecompressWorker from '@/workers/decompress-json.js?worker'
import { decompressJson } from '@/utils/Utils'

export async function getCompensations({ boardId }) {
  //GET /compensations/:boardId
  // const { data, post, terminate } = useWebWorker(DecompressWorker)

  try {
    const ret = await axios.get(`${API_URL}/compensations/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return decompressJson(ret.data)
      // post(ret.data)
      // await until(data).changed()

      // return data.value
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function deleteCompensations({ boardId }) {
  //DELETE /compensations/:boardId
  try {
    const ret = await axios.delete(`${API_URL}/compensations/${boardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data // delete count
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function addCompensation({ boardId, compensation }) {
  const ret = await axios.post(
    `${API_URL}/compensations/${boardId}/create`,
    { data: { compensation } },
    { headers: { ...(await getAuthHeader()) } }
  )

  if (ret.status === 201) {
    return ret.data // success
  } else throw new Error(ret.statusText)
}

export async function addCompensations({ boardId, compensations }) {
  try {
    const ret = await axios.post(
      `${API_URL}/compensations/${boardId}/createMany`,
      { data: { compensations } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 201) {
      return ret.data // success
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function updateCompensation({ boardId, personId, compensation }) {
  //POST /compensations/:boardId/:personId
  //return the added compensation
  try {
    const ret = await axios.post(
      `${API_URL}/compensations/${boardId}/${personId}`,
      { data: { compensation } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function updateCompensations({ boardId, compensations }) {
  //POST /compensations/:boardId
  //compensations must include boardId fields
  //return the updated compensations
  try {
    const ret = await axios.post(
      `${API_URL}/compensations/${boardId}`,
      { data: { compensations } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function bulkUpdateCompensations({ boardId, compensation, peopleIds }) {
  try {
    const ret = await axios.post(
      `${API_URL}/compensations/${boardId}/bulk`,
      { data: { compensation, peopleIds } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

//TODO: bulk compensation action
