import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'

/**
 * Fetch latest forex rates
 * @returns {Promise<{ rates, base }>} forex rates
 */
export async function getForexRates() {
  try {
    const ret = await axios.get(`${API_URL}/forex/latest`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    }

    throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}
