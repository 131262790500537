import { computed } from 'vue'
import chroma from 'chroma-js'

/**
 * Custom hook that generates a simple theme based on the provided departments.
 * @param {Array} departments - The array of department names.
 * @returns {Object} An object containing the generated theme.
 */
export function useSimpleTheme(departments) {
  const theme = computed(() => {
    const newTheme = {}

    const categories = {
      departments
    }

    for (const [category, items] of Object.entries(categories)) {
      newTheme[category] = newTheme[category] || {}

      const colors = chroma
        .scale(['red', 'green', 'blue', 'yellow'])
        .mode('lab')
        .colors(items.length)

      items.forEach((item, index) => {
        if (!newTheme[category][item]) {
          newTheme[category][item] = colors[index]
        }
      })
    }

    return newTheme
  })

  return {
    theme
  }
}
