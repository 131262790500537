export const Fields = {
  department: 'department',
  location: 'officeLocation',
  email: 'email',
  salary: 'salary',
  salaryNewRole: 'salary-newrole',
  name: 'name',
  plannedHireDate: 'planned-hire-date',
  planStatus: 'scenario-status',
  level: 'level',
  reportingLayers: 'reporting-layers',
  groupByRole: 'group-by-role',
  rollupSpanOfControl: 'rollup-span-of-control',
  managementCostEtihad: 'management-cost-etihad',
  vacancies: 'vacancies',
  role: 'role',
  managerCount: 'manager-count',
  managerCost: 'manager-cost',
  icCount: 'ic-count',
  icCost: 'ic-cost',
  managerCostRatio: 'management-cost-ratio',
  managerICRatio: 'manager-ic-ratio',
  totalCost: 'total-cost',
  dottedLines: 'dotted-lines',
  rateCard: 'rate-card'
}

export const ManagementFields = [
  Fields.managerCount,
  Fields.managerCost,
  Fields.icCount,
  Fields.icCost,
  Fields.managerCostRatio,
  Fields.managerICRatio,
  Fields.totalCost
]

export const FieldsLabels = {
  [Fields.name]: 'Name',
  [Fields.department]: 'Department',
  [Fields.location]: 'Location',
  [Fields.email]: 'Email',
  [Fields.salary]: 'Salary',
  [Fields.salaryNewRole]: 'Salary (new role)',
  [Fields.plannedHireDate]: 'Hire date (new role)',
  [Fields.planStatus]: 'Scenario status',
  [Fields.level]: 'Layers',
  [Fields.groupByRole]: 'Group by: role',
  [Fields.rollupSpanOfControl]: 'Rollup span of control',
  [Fields.managementCostEtihad]: 'Management cost',
  [Fields.vacancies]: 'Vacancies',
  [Fields.reportingLayers]: 'Reporting layers',
  [Fields.role]: 'Role',
  [Fields.managerCount]: 'Manager count',
  [Fields.managerCost]: 'Manager cost',
  [Fields.icCount]: 'IC count',
  [Fields.icCost]: 'IC cost',
  [Fields.managerCostRatio]: 'Manager cost ratio',
  [Fields.managerICRatio]: 'Manager to IC ratio',
  [Fields.totalCost]: 'Total cost',
  [Fields.dottedLines]: 'Dotted lines',
  [Fields.rateCard]: 'Rate card'
}
