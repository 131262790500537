import {
  getMeta,
  fetchHubCategories,
  countVacantSubordinates,
  vacantSubordinates
} from '@/services/meta-api.service.js'
import { getBoard, updateBoard } from '@/services/boards-api.service'
import { getAllowedValues, updateAllowedValues } from '@/services/allowed-values-api.service'
import _ from 'lodash'

// This module is inteded to store the meta data of the main board.
// Main usecase is to return the statistics of various fields
// such as number of people, departments, locations, and unique custom fields
const getDefaultState = () => {
  return {
    peopleMetaData: {},
    // number of people in the board
    numbPeople: 0,
    departments: [],
    locations: [],
    theme: {},
    hubCategories: {},
    roles: [],
    allowedValues: {},
    directManagerVacancies: {},
    noManagerVacancies: {},
    rollupManagerVacancies: {},
    vacancies: [],
    duplicationPresets: {}
  }
}

const state = getDefaultState()

const mutations = {
  SET_PEOPLE_META_DATA(_state, data) {
    _state.peopleMetaData = data
  },
  SET_NUMB_PEOPLE(_state, numbPeople) {
    _state.numbPeople = numbPeople
  },
  SET_DEPARTMENTS(_state, departments) {
    _state.departments = departments
  },
  SET_MANAGERS(_state, managers) {
    _state.managers = managers
  },
  SET_LOCATIONS(_state, locations) {
    _state.locations = locations
  },
  SET_ORG_DEPTH(_state, depth) {
    _state.depth = depth
  },
  SET_THEME(_state, theme) {
    if (theme) {
      _state.theme = theme
    }
  },
  SET_COLOUR(_state, { content, filter, colour }) {
    _state.theme[content][filter] = colour
  },
  SET_ALLOWED_VALUES(_state, allowedValues) {
    _state.allowedValues = allowedValues
  },
  RESET_SYNC_MODULE(_state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(_state, getDefaultState())
  },
  SET_HUB_CATEGORIES(_state, { boardId, data }) {
    _state.hubCategories = _state.hubCategories ?? {}
    _state.hubCategories[boardId] = data
  },
  SET_ROLES(_state, roles) {
    _state.roles = roles
  },
  SET_DUPLICATION_PRESETS(_state, duplicationPresets) {
    _state.duplicationPresets = duplicationPresets
  },
  SET_DIRECT_MANAGER_VACANCIES(_state, managerVacancies) {
    _state.directManagerVacancies = managerVacancies
  },
  SET_NO_MANAGER_VACANCIES(_state, noManagerVacancies) {
    _state.noManagerVacancies = noManagerVacancies
  },
  SET_ROLLUP_MANAGER_VACANCIES(_state, managerVacancies) {
    _state.rollupManagerVacancies = managerVacancies
  },
  SET_VACANCIES(_state, vacancies) {
    _state.vacancies = vacancies
  }
}

const getters = {
  getMeta: (_state) => {
    return _state.peopleMetaData
  },
  getNumbPeople: (_state) => {
    return _state.numbPeople
  },
  getDepartments: (_state) => {
    return _state.departments
  },
  getLocations: (_state) => {
    return _state.locations
  },
  getRoles: (_state) => {
    return _state.roles
  },
  getDepth: (_state) => {
    return _state.depth
  },
  getManagers: (_state) => {
    return _state.managers
  },
  getHubCategories: (_state) => (boardId) => _state.hubCategories?.[boardId] ?? [],
  getDuplicationPresets: (_state) => _state.duplicationPresets,
  getAllowedValues: (_state) => {
    return _.keyBy(_state.allowedValues, 'key') || {}
  },
  getDirectManagerVacancies: (_state) => {
    return _state.directManagerVacancies
  },
  getNoManagerVacancies: (_state) => {
    return _state.noManagerVacancies
  },
  getRollupManagerVacancies: (_state) => {
    return _state.rollupManagerVacancies
  },
  getVacancies: (_state) => {
    return _state.vacancies
  }
}

const actions = {
  async fetchPeopleMetaData(context, { boardId }) {
    const meta = await getMeta({ boardId })
    context.commit('SET_NUMB_PEOPLE', meta.numbPeople)
    context.commit('SET_DEPARTMENTS', meta.departments)
    context.commit('SET_LOCATIONS', meta.locations)
    context.commit('SET_MANAGERS', meta.managers)
    context.commit('SET_ORG_DEPTH', meta.depth)
    context.commit('SET_ROLES', meta.roles)
  },

  async fetchVacancies(context, { boardId }) {
    const vacancies = await vacantSubordinates({ boardId })
    context.commit('SET_VACANCIES', vacancies)
  },

  async countVacancies(context, { boardId, filters }) {
    try {
      const count = await countVacantSubordinates({ boardId, filters })
      if (count) {
        context.commit('SET_DIRECT_MANAGER_VACANCIES', count.direct)
        context.commit('SET_ROLLUP_MANAGER_VACANCIES', count.rollup)
        context.commit('SET_NO_MANAGER_VACANCIES', count.noManager)
      }
    } catch (e) {
      console.error('countVacancies err', e)
    }
  },

  async fetchDuplicationPresets(context, { boardId }) {
    const boardData = await getBoard({ boardId })
    const duplicationPresets = boardData.duplicationPresets
    context.commit('SET_DUPLICATION_PRESETS', duplicationPresets)
  },

  async fetchAllowedValues(context, { boardId }) {
    const allowedValues = await getAllowedValues({ boardId })
    context.commit('SET_ALLOWED_VALUES', allowedValues)
  },

  async updateTheme(context, { boardId, theme }) {
    const boardData = await getBoard({
      boardId
    })
    context.commit('SET_THEME', theme)
    await updateBoard({ boardId, payload: { ...boardData, theme } })
  },

  async updateDuplicationPresets(context, { boardId, duplicationPresets }) {
    const boardData = await getBoard({
      boardId
    })
    context.commit('SET_DUPLICATION_PRESETS', duplicationPresets)
    await updateBoard({ boardId, payload: { ...boardData, duplicationPresets } })
  },

  async updateAllowedValues(context, { boardId, allowedValues }) {
    context.commit('SET_ALLOWED_VALUES', allowedValues)
    await updateAllowedValues({ boardId, payload: { allowedValues } })
  },

  setColour(context, { content, filter, colour }) {
    context.commit('SET_COLOUR', { content, filter, colour })
  },

  async fetchHubCategories(
    { commit, getters: contextGetters, rootGetters },
    { boardId, force = false }
  ) {
    if (!force && contextGetters.getHubCategories(boardId)?.length > 0) {
      return
    }

    const visibleFields = rootGetters['getFieldVisibility'] || {}
    const hasVisibilitySettings = Object.keys(visibleFields).length > 0

    try {
      const result = await fetchHubCategories({ boardId })
      commit('SET_HUB_CATEGORIES', {
        boardId,
        data:
          result.filter(
            (category) => visibleFields[category.id] === true || !hasVisibilitySettings
          ) || []
      })
    } catch (e) {
      console.error('fetchHubCategories err', e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {}
}
