import * as Sentry from '@sentry/browser'
import { isEmpty } from 'lodash-es'
import {
  fetchOrgmap,
  fetchLayerAndDepth,
  fetchLayerAndSpanOfControl,
  fetchDistribution,
  fetchSpanOfControl,
  fetchAverageSpanOfControl,
  fetchIcicle,
  fetchCostDistribution,
  fetchGrowthDistribution,
  fetchGrowthDistributionDates,
  fetchPyramidChart,
  fetchStackedHeadcountCost,
  fetchSkillsHeatmap,
  fetchLayersTable
} from '@/services/hub-api.service'
import { sortDataByGivenOrder } from '@/utils/Utils'
import { applyCorrectness } from '@/utils/workforceHubChartConfig/pyramidChart'

import { HUB_DICT } from '@/lib/WorkforceHubCharts'

const getDefaultState = () => ({
  layerAndDepth: {},
  layerAndSpanOfControl: {},
  orgMap: {},
  headcountDistribution: {},
  headcountCostDistribution: {},
  headcountGrowthDistribution: {},
  spanOfControl: {},
  averageSpanOfControl: {},
  icicleChart: {},
  pyramid: [],
  stackedHeadcountCost: {},
  skillsHeatmap: {},
  layersTable: {}
})

const state = {
  dataById: {}
}

/**
 * Sets the state with data and options for a specific hub.
 *
 * @param {object} _state - The state object to be modified.
 * @param {object} data - The data to be assigned to the state's hub.
 * @param {string} hub - The hub identifier within the state.
 */
const setStateWithOptions = (_state, data, hub) => {
  _state[hub].dataByOptions = data.data
  _state[hub].options = data.options
}

const mutations = {
  setLayerAndDepth(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    setStateWithOptions(_state.dataById[boardId], data, HUB_DICT.LAYER_AND_DEPTH)
  },
  setLayerAndSpanOfControl(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    setStateWithOptions(_state.dataById[boardId], data, HUB_DICT.LAYER_AND_SPAN_OF_CONTROL)
  },
  setHeadcountDistribution(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    setStateWithOptions(_state.dataById[boardId], data, HUB_DICT.HEADCOUNT_DISTRIBUTION)
  },
  setHeadcountCostDistribution(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    setStateWithOptions(_state.dataById[boardId], data, HUB_DICT.HEADCOUNT_COST_DISTRIBUTION)
  },
  setHeadcountGrowthDistribution(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    setStateWithOptions(_state.dataById[boardId], data, HUB_DICT.HEADCOUNT_GROWTH_DISTRIBUTION)
  },
  setHeadcountGrowthDistributionDates(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    _state.dataById[boardId][HUB_DICT.HEADCOUNT_GROWTH_DISTRIBUTION].dates = data
  },
  setSpanOfControl(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    setStateWithOptions(_state.dataById[boardId], data, HUB_DICT.SPAN_OF_CONTROL)
  },
  setAverageSpanOfControl(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    setStateWithOptions(_state.dataById[boardId], data, HUB_DICT.AVERAGE_SPAN_OF_CONTROL)
  },
  setOrgMap(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    _state.dataById[boardId].orgMap = data
  },
  setPyramidChart(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    _state.dataById[boardId].pyramid = data
  },
  setIcicleChart(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    _state.dataById[boardId].icicleChart = Object.freeze(data)
  },
  setStackedHeadcountCost(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    setStateWithOptions(_state.dataById[boardId], data, HUB_DICT.STACKED_HEADCOUNT_COST)
  },
  setSkillsHeatmap(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    setStateWithOptions(_state.dataById[boardId], data, HUB_DICT.SKILLS_HEATMAP)
  },
  setLayersTable(_state, { boardId, data }) {
    if (!_state.dataById[boardId]) {
      _state.dataById[boardId] = getDefaultState()
    }
    setStateWithOptions(_state.dataById[boardId], data, HUB_DICT.LAYERS_TABLE)
  },

  //reset
  resetOrgMap(_state, boardId) {
    if (_state.dataById[boardId]) {
      _state.dataById[boardId].orgMap = {}
    }
  },
  resetLayerAndDepth(_state, boardId) {
    if (_state.dataById[boardId]) {
      _state.dataById[boardId].layerAndDepth = {}
    }
  },
  resetLayerAndSpanOfControl(_state, boardId) {
    if (_state.dataById[boardId]) {
      _state.dataById[boardId].layerAndSpanOfControl = {}
    }
  },
  resetHeadcountDistribution(_state, boardId) {
    if (_state.dataById[boardId]) {
      _state.dataById[boardId].headcountDistribution = {}
    }
  },
  resetHeadcountCostDistribution(_state, boardId) {
    if (_state.dataById[boardId]) {
      _state.dataById[boardId].headcountCostDistribution = {}
    }
  },
  resetHeadcountGrowthDistribution(_state, boardId) {
    if (_state.dataById[boardId]) {
      _state.dataById[boardId].headcountGrowthDistribution = {}
    }
  },
  resetSpanOfControl(_state, boardId) {
    if (_state.dataById[boardId]) {
      _state.dataById[boardId].spanOfControl = {}
    }
  },
  resetAverageSpanOfControl(_state, boardId) {
    if (_state.dataById[boardId]) {
      _state.dataById[boardId].averageSpanOfControl = {}
    }
  },
  resetIcicleChart(_state, boardId) {
    if (_state.dataById[boardId]) {
      _state.dataById[boardId].icicleChart = {}
    }
  },
  resetPyramidChart(_state, boardId) {
    if (_state.dataById[boardId]) {
      _state.dataById[boardId].pyramid = []
    }
  },
  resetStackedHeadcountCost(_state, boardId) {
    if (_state.dataById[boardId]) {
      _state.dataById[boardId].stackedHeadcountCost = {}
    }
  },
  resetSkillsHeatmap(_state, boardId) {
    if (_state.dataById[boardId]) {
      _state.dataById[boardId].skillsHeatmap = {}
    }
  },
  resetLayersTable(_state, boardId) {
    if (_state.dataById[boardId]) {
      _state.dataById[boardId].layersTable = {}
    }
  }
}

const getters = {
  // layer and depth
  getLayerAndDepth:
    (_state) =>
    (boardId, { filter }) => {
      return _state.dataById[boardId]?.layerAndDepth?.dataByOptions?.[filter] || []
    },

  getLayerAndDepthOptions: (_state) => (boardId) =>
    _state.dataById[boardId]?.layerAndDepth?.options || [],

  // layer and span of control
  getLayerAndSpanOfControl: (_state) => (boardId) => {
    return _state.dataById[boardId]?.layerAndSpanOfControl?.dataByOptions?.default || []
  },

  getLayerAndSpanOfControlOptions: (_state) => (boardId) =>
    _state.dataById[boardId]?.layerAndSpanOfControl?.options || [],

  // headcount distribution
  getHeadcountDistribution:
    (_state) =>
    (boardId, { filter }) => {
      return _state.dataById[boardId]?.headcountDistribution?.dataByOptions?.[filter] || {}
    },

  getHeadcountDistributionOptions: (_state) => (boardId) =>
    _state.dataById[boardId]?.headcountDistribution?.options || [],

  // headcount cost distribution
  getHeadcountCostDistribution:
    (_state) =>
    (boardId, { filter }) =>
      _state.dataById[boardId]?.headcountCostDistribution?.dataByOptions?.[filter] || {},
  getHeadcountCostDistributionOptions: (_state) => (boardId) =>
    _state.dataById[boardId]?.headcountCostDistribution?.options || [],

  // headcount growth distribution
  getHeadcountGrowthDistribution:
    (_state) =>
    (boardId, { filter }) =>
      _state.dataById[boardId]?.headcountGrowthDistribution?.dataByOptions?.[filter] || [],
  getHeadcountGrowthDistributionOptions: (_state) => (boardId) =>
    _state.dataById[boardId]?.headcountGrowthDistribution?.options || [],
  getHeadcountGrowthDistributionDates: (_state) => (boardId) =>
    _state.dataById[boardId]?.headcountGrowthDistribution?.dates || {},

  // span of control distribution
  getSpanOfControl:
    (_state) =>
    (boardId, { filter }) =>
      _state.dataById[boardId]?.spanOfControl?.dataByOptions?.[filter] || {},
  getSpanOfControlOptions: (_state) => (boardId) =>
    _state.dataById[boardId]?.spanOfControl?.options || [],

  // span of control
  getAverageSpanOfControl:
    (_state) =>
    (boardId, { filter }) =>
      _state.dataById[boardId]?.averageSpanOfControl?.dataByOptions?.[filter] || {},
  getAverageSpanOfControlOptions: (_state) => (boardId) =>
    _state.dataById[boardId]?.averageSpanOfControl?.options || [],

  // icicle
  getIcicleChart: (_state) => (boardId) => _state.dataById[boardId]?.icicleChart || {},
  isIcicleChartAvailable: (_state, _getters) => (boardId) =>
    !isEmpty(_getters.getIcicleChart(boardId)),

  // pyramid
  getPyramidChart: (_state) => (boardId) => _state.dataById[boardId]?.pyramid || [],

  // org map
  getOrgMapByOptions:
    (_state) =>
    (boardId, { filter }) =>
      _state.dataById[boardId]?.orgMap?.dataset?.[filter] || [],
  getOrgMapOptions: (_state) => (boardId) => _state.dataById[boardId]?.orgMap?.options || [],
  isOrgMapAvailable:
    (_state, _getters) =>
    (boardId, { filter }) => {
      return _getters.getOrgMapByOptions(boardId, { filter })?.children?.length > 0
    },

  // stacked headcount cost
  getStackedHeadcountCost:
    (_state) =>
    (boardId, { filter }) =>
      _state.dataById[boardId]?.stackedHeadcountCost?.dataByOptions?.[filter] || {},

  getSkillsHeatmap: (_state) => (boardId) => {
    return _state.dataById[boardId]?.skillsHeatmap || {}
  },
  getLayersTable: (_state) => (boardId) =>
    _state.dataById[boardId]?.layersTable?.dataByOptions || {}
}

const actions = {
  async fetchLayerAndDepth(
    context,
    { boardId, filters = [], category = null, subCategory = null }
  ) {
    window.mixpanel.track('workforce_hub_fetch_layer_and_depth', {
      boardId,
      filters,
      category,
      subCategory
    })
    try {
      const result = await fetchLayerAndDepth({ boardId, filters, category, subCategory })
      context.commit('setLayerAndDepth', { boardId, data: result || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchLayerAndDepth err', e)
      context.commit('resetLayerAndDepth', boardId)
    }
  },

  async fetchLayerAndSpanOfControl(context, { boardId, filters = [], category = null }) {
    window.mixpanel.track('workforce_hub_fetch_layer_and_span_of_control', {
      boardId,
      filters,
      category
    })
    try {
      const result = await fetchLayerAndSpanOfControl({ boardId, filters, category })
      context.commit('setLayerAndSpanOfControl', { boardId, data: result || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchLayerAndSpanOfControl err', e)
      context.commit('resetLayerAndSpanOfControl', boardId)
    }
  },

  async fetchHeadcountDistribution(context, { boardId, filters = [], category = null }) {
    window.mixpanel.track('workforce_hub_fetch_headcount_distribution', {
      boardId,
      filters,
      category
    })
    try {
      const result = await fetchDistribution({ boardId, filters, category })
      context.commit('setHeadcountDistribution', { boardId, data: result || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchHeadcountDistribution err', e)
      context.commit('resetHeadcountDistribution', boardId)
    }
  },

  async fetchHeadcountCostDistribution(
    context,
    { boardId, filters = [], category = null, isAverages = false }
  ) {
    window.mixpanel.track('workforce_hub_fetch_headcount_cost_distribution', {
      boardId,
      filters,
      category,
      isAverages
    })
    try {
      const result = await fetchCostDistribution({ boardId, filters, category, isAverages })
      context.commit('setHeadcountCostDistribution', { boardId, data: result || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchHeadcountCostDistribution err', e)
      context.commit('resetHeadcountCostDistribution', boardId)
    }
  },

  async fetchHeadcountGrowthDistribution(context, { boardId, filters = [], startDate, endDate }) {
    window.mixpanel.track('workforce_hub_fetch_headcount_growth_distribution', {
      boardId,
      filters,
      startDate,
      endDate
    })
    try {
      const result = await fetchGrowthDistribution({ boardId, filters, startDate, endDate })
      context.commit('setHeadcountGrowthDistribution', { boardId, data: result || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchHeadcountGrowthDistribution err', e)
      context.commit('resetHeadcountGrowthDistribution', boardId)
    }
  },

  async fetchHeadcountGrowthDistributionDates(context, { boardId, filters }) {
    window.mixpanel.track('workforce_hub_fetch_headcount_growth_distribution_dates', { boardId })
    try {
      const result = await fetchGrowthDistributionDates({ boardId, filters })
      context.commit('setHeadcountGrowthDistributionDates', { boardId, data: result || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchHeadcountGrowthDistributionDates err', e)
      context.commit('resetHeadcountGrowthDistribution', boardId)
    }
  },

  async fetchOrgMap(context, { boardId, filters = [], category = null, subCategory = null }) {
    window.mixpanel.track('workforce_hub_fetch_org_map', {
      boardId,
      filters,
      category,
      subCategory
    })
    try {
      const orgData = await fetchOrgmap({ boardId, filters, category, subCategory })
      context.commit('setOrgMap', { boardId, data: orgData || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchOrgMap err', e)
      context.commit('resetOrgMap', boardId)
    }
  },

  async fetchIcicleChart(context, { boardId, filters = [] }) {
    window.mixpanel.track('workforce_hub_fetch_icicle_chart', { boardId, filters })
    try {
      const result = await fetchIcicle({ boardId, filters })
      context.commit('setIcicleChart', { boardId, data: Object.freeze(result) || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchIcicleChart err', e)
      context.commit('resetIcicleChart', boardId)
    }
  },

  async fetchSpanOfControl(context, { boardId, filters = [], category = null }) {
    window.mixpanel.track('workforce_hub_fetch_span_of_control', { boardId, filters, category })
    try {
      const result = await fetchSpanOfControl({ boardId, filters, category })
      context.commit('setSpanOfControl', { boardId, data: result || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchSpanOfControl err', e)
      context.commit('resetSpanOfControl', boardId)
    }
  },

  async fetchAverageSpanOfControl(context, { boardId, filters = [], category = null }) {
    window.mixpanel.track('workforce_hub_fetch_average_span_of_control', {
      boardId,
      filters,
      category
    })
    try {
      const result = await fetchAverageSpanOfControl({ boardId, filters, category })
      context.commit('setAverageSpanOfControl', { boardId, data: result || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchAverageSpanOfControl err', e)
      context.commit('resetAverageSpanOfControl', boardId)
    }
  },

  async fetchPyramidChart(
    context,
    { boardId, filters = [], category = null, showBy = 'headcount', newOrder }
  ) {
    window.mixpanel.track('workforce_hub_fetch_pyramid_chart', { boardId, filters, category })
    try {
      if (newOrder) {
        let data = context.getters.getPyramidChart(boardId) ?? {}
        if (!data) {
          data = await fetchPyramidChart({ boardId, filters, category, showBy })
        }
        const sortedData = sortDataByGivenOrder({
          data: data.data,
          order: newOrder,
          sortByProperty: 'x'
        })
        const sortedPlanData = sortDataByGivenOrder({
          data: data.planData,
          order: newOrder,
          sortByProperty: 'x'
        })
        applyCorrectness(sortedData)
        applyCorrectness(sortedPlanData)
        context.commit('setPyramidChart', {
          boardId,
          data: { ...data, data: sortedData, planData: sortedPlanData, categoryOrder: newOrder }
        })
        return
      }
      const result = await fetchPyramidChart({ boardId, filters, category, showBy })
      context.commit('setPyramidChart', { boardId, data: result || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchPyramidChart err', e)
      context.commit('resetPyramidChart', boardId)
    }
  },

  async fetchStackedHeadcountCost(
    context,
    { boardId, filters = [], category = null, subCategories = [] }
  ) {
    window.mixpanel.track('workforce_hub_fetch_stacked_headcount_cost', {
      boardId,
      filters,
      category,
      subCategories
    })
    try {
      const result = await fetchStackedHeadcountCost({ boardId, filters, category, subCategories })
      context.commit('setStackedHeadcountCost', { boardId, data: result || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchStackedHeadcountCost err', e)
      context.commit('resetStackedHeadcountCost', boardId)
    }
  },

  async fetchSkillsHeatmap(
    context,
    { boardId, filters = [], category = null, subCategory = null }
  ) {
    window.mixpanel.track('workforce_hub_fetch_skills_heatmap', {
      boardId,
      filters,
      category,
      subCategory
    })
    try {
      const result = await fetchSkillsHeatmap({ boardId, filters })
      context.commit('setSkillsHeatmap', { boardId, data: result || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchSkillsHeatmap err', e)
      context.commit('resetSkillsHeatmap', boardId)
    }
  },
  async fetchLayersTable(context, { boardId, filters = [] }) {
    window.mixpanel.track('workforce_hub_fetch_layers_table', {
      boardId,
      filters
    })
    try {
      const result = await fetchLayersTable({ boardId, filters })
      context.commit('setLayersTable', { boardId, data: result || {} })
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchLayersTable err', e)
      context.commit('resetLayersTable', boardId)
    }
  },

  resetHeadcountCostDistribution(context, boardId) {
    try {
      context.commit('resetHeadcountCostDistribution', boardId)
    } catch (e) {
      Sentry.captureException(e)
      console.error('resetHeadcountCostDistribution err', e)
    }
  },

  resetLayerAndDepth(context, boardId) {
    try {
      context.commit('resetLayerAndDepth', boardId)
    } catch (e) {
      Sentry.captureException(e)
      console.error('resetLayerAndDepth err', e)
    }
  },

  resetLayerAndSpanOfControl(context, boardId) {
    try {
      context.commit('resetLayerAndSpanOfControl', boardId)
    } catch (e) {
      Sentry.captureException(e)
      console.error('resetLayerAndSpanOfControl err', e)
    }
  },

  resetIcicleChart(context, boardId) {
    try {
      context.commit('resetIcicleChart', boardId)
    } catch (e) {
      Sentry.captureException(e)
      console.error('resetIcicleChart err', e)
    }
  },

  resetHeadcountGrowthDistribution(context, boardId) {
    try {
      context.commit('resetHeadcountGrowthDistribution', boardId)
    } catch (e) {
      Sentry.captureException(e)
      console.error('resetHeadcountGrowthDistribution err', e)
    }
  },

  resetSpanOfControl(context, boardId) {
    try {
      context.commit('resetSpanOfControl', boardId)
    } catch (e) {
      Sentry.captureException(e)
      console.error('resetSpanOfControl err', e)
    }
  },

  resetAverageSpanOfControl(context, boardId) {
    try {
      context.commit('resetAverageSpanOfControl', boardId)
    } catch (e) {
      Sentry.captureException(e)
      console.error('resetAverageSpanOfControl err', e)
    }
  },

  resetOrgMap(context, boardId) {
    try {
      context.commit('resetOrgMap', boardId)
    } catch (e) {
      Sentry.captureException(e)
      console.error('resetOrgMap err', e)
    }
  },

  resetPyramidChart(context, boardId) {
    try {
      context.commit('resetPyramidChart', boardId)
    } catch (e) {
      Sentry.captureException(e)
      console.error('resetPyramidChart err', e)
    }
  },

  resetHeadcountDistribution(context, boardId) {
    try {
      context.commit('resetHeadcountDistribution', boardId)
    } catch (e) {
      Sentry.captureException(e)
      console.error('resetHeadcountDistribution err', e)
    }
  },

  resetStackedHeadcountCost(context, boardId) {
    try {
      context.commit('resetStackedHeadcountCost', boardId)
    } catch (e) {
      Sentry.captureException(e)
      console.error('resetStackedHeadcountCost err', e)
    }
  },

  resetSkillsHeatmap(context, boardId) {
    try {
      context.commit('resetSkillsHeatmap', boardId)
    } catch (e) {
      Sentry.captureException(e)
      console.error('resetSkillsHeatmap err', e)
    }
  },
  resetLayersTable(context, boardId) {
    try {
      context.commit('resetLayersTable', boardId)
    } catch (e) {
      Sentry.captureException(e)
      console.error('resetLayersTable err', e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
