import axios from 'axios'
import { API_URL, getAuthHeader } from '@/services/api.service.js'

export async function getPendingPlanInvitationsForEmail({ email }) {
  try {
    const ret = await axios.get(`${API_URL}/plan-invitations/email/${email}/not-visited`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function getPendingPlanInvitationsForBoard({ boardId }) {
  try {
    const ret = await axios.get(`${API_URL}/plan-invitations/${boardId}/not-visited`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function createPlanInvitationForOutsider({ boardId, invitation }) {
  try {
    const ret = await axios.post(
      `${API_URL}/plan-invitations/${boardId}`,
      { data: { invitation } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 201) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function sendPlanInvitationEmail(invitation) {
  try {
    const ret = await axios.post(
      `${API_URL}/plan-invitations/${invitation.boardId}/send-invite`,
      { data: { invitation } },
      { headers: { ...(await getAuthHeader()) } }
    )

    if (ret.status === 200) {
      return ret.data
    }

    throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function removePlanInvitationForOutsiders({ planBoardId, recipient }) {
  //DELETE /plan-invitations/:planBoardId/:recipient

  // const docs = await firebase
  //   .firestore()
  //   .collection('plan_invitations')
  //   .where('planBoardId', '==', planBoardId)
  //   .where('recipient', '==', recipient)
  //   .get()

  try {
    const ret = await axios.delete(`${API_URL}/plan-invitations/${planBoardId}/${recipient}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}

export async function removePlanInvitations({ boardId, planBoardId }) {
  //DELETE /plan-invitations/:boardId/:planBoardId

  // const snapshot = await firebase
  //   .firestore()
  //   .collection('plan_invitations')
  //   .where('planBoardId', '==', planBoardId)
  //   .where('boardId', '==', boardId)
  //   .get()
  // // Batch request is capped at 500 transactions
  // const batches = chunk(snapshot.docs, 500)
  // const commitBatchPromises = []
  //
  // batches.forEach((batch) => {
  //   const writeBatch = firebase.firestore().batch()
  //   batch.forEach((doc) => writeBatch.delete(doc.ref))
  //   commitBatchPromises.push(writeBatch.commit())
  // })
  //
  // await Promise.all(commitBatchPromises)

  try {
    const ret = await axios.delete(`${API_URL}/plan-invitations/${boardId}/${planBoardId}`, {
      headers: { ...(await getAuthHeader()) }
    })

    if (ret.status === 200) {
      return ret.data
    } else throw new Error(ret.statusText)
  } catch (error) {
    console.log(error)
    return null
  }
}
