import { FONT_SIZE } from './constants'

export const palette = {
  correctnessColor: '#F97066',
  changeColor: '#155EEF',
  defaultColor: '#98A2B3'
}

export default {
  chart: {
    id: 'pyramid-chart',
    toolbar: {
      tools: {
        download: false
      }
    },
    type: 'bar',
    sparkline: {
      enabled: true
    }
  },
  states: {
    hover: {
      filter: {
        type: 'darken',
        value: 0.7
      }
    }
  },
  // colors: [
  // function ({ seriesIndex, w }) {
  //   if (!w.config.series[0].data[seriesIndex].correctness) return palette.correctnessColor
  //   return palette.defaultColor
  // }
  // ],
  colors: [],
  // tooltip: {
  //   enabled: true
  // },
  plotOptions: {
    bar: {
      horizontal: true,
      distributed: true,
      isFunnel: true,
      isFunnel3d: false,
      // borderRadius: 10,
      // borderRadiusApplication: 'around',
      dataLabels: {
        hideOverflowingLabels: false,
        position: 'top'
      }
    }
  },
  dataLabels: {
    formatter(val, opt) {
      const series = opt.config.series[0].data[opt.dataPointIndex]
      const isMonetarySelection = series.showBy === 'headcount-cost'
      return `${isMonetarySelection ? series.currency : ''} ${
        isMonetarySelection ? series.formattedY : series.y
      } - ${opt.w.globals.labels[opt.dataPointIndex]}`
    },
    enabled: true,
    textAnchor: 'start',
    offsetX: 40,
    style: {
      colors: ['#EFF4FF']
    },
    background: {
      enabled: true,
      foreColor: '#1D2939',
      borderColor: '#EFF4FF',
      // borderRadius: 10,
      padding: 10,
      opacity: 1
    }
  },
  legend: {
    show: false
  },
  grid: {
    show: false,
    padding: {
      right: 100
    }
  },
  xaxis: {
    showAlways: false,
    // categories: [
    //   'Sweets',
    //   'Processed Foods',
    //   'Healthy Fats',
    //   'Meat',
    //   'Beans & Legumes',
    //   'Dairy',
    //   'Fruits & Vegetables',
    //   'Grains'
    // ],
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    labels: {
      hideOverlappingLabels: false,
      // rotate: 0,
      trim: true,
      style: {
        colors: '#667085',
        fontSize: FONT_SIZE,
        fontWeight: 600,
        fontFamily: 'Inter'
      }
    },
    title: {
      text: '',
      offsetY: -1,
      style: {
        color: '#667085',
        fontSize: FONT_SIZE,
        fontFamily: 'Inter',
        fontWeight: 400
      }
    }
  },
  yaxis: {
    showAlways: false,
    labels: {
      style: {
        colors: '#667085',
        fontSize: FONT_SIZE,
        fontWeight: 600,
        fontFamily: 'Inter'
      }
    },
    title: {
      text: '',
      rotate: -90,
      offsetX: 8,
      offsetY: 0,
      style: {
        color: '#667085',
        fontSize: FONT_SIZE,
        fontFamily: 'Inter',
        fontWeight: 400
      }
    }
  },
  stroke: {
    colors: ['transparent'],
    width: 10
  }
}

export const applyCorrectness = (data) => {
  // Loop through the array and compare each item's 'y' value with the next item's 'y' value
  for (let i = 0; i < data.length; i++) {
    if (i + 1 < data.length) {
      // Check if there is a next item
      // If current item's 'y' is greater than the next item's 'y', set current item's correctness to false
      data[i].correctness = !(data[i].y > data[i + 1].y)
    } else {
      // For the last item, we can leave it as is since there's no next item to compare to
      // Or set to true or false depending on your business logic
      data[i].correctness = true // Assuming true if it's the last item and has no comparison
    }
  }
}
